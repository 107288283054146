import axios from '../../apis/axiosApi';
import { REDUCER_ACTION_TYPE } from '../context/AuthProviderContext';
import useAuth from './useAuth';

const useLogout = () => {
    const setAuth = useAuth();

    const logout = async () => {
        setAuth.dispatch({ type: REDUCER_ACTION_TYPE.RESET_ALL });
        try {
            const response = await axios('/auth/logout', {
                withCredentials: true,
            });
        } catch (err) {
            console.error(err);
        }
    };

    return logout;
};
export default useLogout;
