import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { createRateAndShowModel, RateAndShowModel } from './RateAndShowModel';
import { useOutsideClick } from '../hooks/useOutsideClick';
//import { Note } from "../../schema/notes_schema";

import { Area, AreaChart, CartesianGrid, Line, LineChart, XAxis } from 'recharts';
//import { Slider } from "./slider";
import ReactSlider from 'react-slider';
import { Note } from '../../schema/app_schema';
import { Tree } from 'fluid-framework';
//import { Tree } from "@fluid-experimental/tree2";

export function RateAndShow(props: { user: string; note: Note }) {
    const [model] = useState<RateAndShowModel>(createRateAndShowModel(props.note));
    // const [acceptanceLevel, setAcceptanceLevel] = useState<number>(0);
    const [selectionOfUser, setSelectionOfUser] = useState<number>(1);
    const [invalidations, setInvalidations] = useState(0);
    const [data3, setData3] = useState([
        { uv: 0, pv: 0 },
        { uv: 1, pv: 0 },
        { uv: 2, pv: 0 },
        { uv: 3, pv: 0 },
        { uv: 4, pv: 0 },
        { uv: 5, pv: 0 },
        { uv: 6, pv: 0 },
        { uv: 7, pv: 0 },
        { uv: 8, pv: 0 },
        { uv: 9, pv: 0 },
        { uv: 10, pv: 0 },
    ]);

    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        return Tree.on(props.note.ratings, 'nodeChanged', () => {
            setInvalidations(invalidations + Math.random());
        });
    }, [invalidations]);

    useEffect(() => {
        const syncLocalAndFluidState = () => {
            const acceptanceLevelIntern = model.AcceptanceLevel;
            //      setAcceptanceLevel(acceptanceLevelIntern);

            const userSelection = model.GetUserVoting(props.user);
            setSelectionOfUser(userSelection);
        };
        syncLocalAndFluidState();
        // model.setChangeListener(syncLocalAndFluidState);
        // return () => model.removeChangeListener(syncLocalAndFluidState);
    }, [props.note.ratings]);

    // Chart data

    // const data3 = [
    //   { uv: 9, pv: 0},
    //   { uv: 8, pv: 0},
    //   { uv: 7, pv: 1},
    //   { uv: 6, pv: 0},
    //   { uv: 5, pv: 0},
    //   { uv: 4, pv: 1},
    //   { uv: 3, pv: 0},
    //   { uv: 2, pv: 0},
    //   { uv: 1, pv: 1},
    //   { uv: 0, pv: 0},
    // ];

    useEffect(() => {
        let datax = [
            { uv: 0, pv: 0 },
            { uv: 1, pv: 0 },
            { uv: 2, pv: 0 },
            { uv: 3, pv: 0 },
            { uv: 4, pv: 0 },
            { uv: 5, pv: 0 },
            { uv: 6, pv: 0 },
            { uv: 7, pv: 0 },
            { uv: 8, pv: 0 },
            { uv: 9, pv: 0 },
            { uv: 10, pv: 0 },
        ];
        if (props.note.ratings) {
            for (const u of props.note.ratings) {
                const foundVal = datax.find(({ uv }) => uv === u.voteValue);
                if (foundVal) {
                    foundVal.pv += 1;
                }
            }
            setData3(datax);
        }
    }, [invalidations]);

    var rating: number;
    rating = 5;

    // const changeRating = (e: any) => {
    //     let { name, value } = e.target;
    //     if (value == '22') {
    //         value = 0;
    //     }
    //     if (value == '5 - Ich habe Bedenken') {
    //         value = 5;
    //     }
    //     if (value == 'Keine eigene Meinung') {
    //         value = -1;
    //     }
    //     if (value == '10 - Nur das nicht') {
    //         value = 10;
    //     }

    //     model.CreateOrUpdateVote(parseInt(value), props.user);
    // };
    let selectionStr;
    switch (selectionOfUser) {
        case 0:
            selectionStr = '0 - Is ok kein Widerstand';
            break;
        case 5:
            selectionStr = '5 - Ich habe Bedenken';
            break;
        case 10:
            selectionStr = '10 - Nur das nicht';
            break;
        case -1:
            selectionStr = 'Keine eigene Meinung';
            break;
        default:
            selectionStr = selectionOfUser;
            break;
    }
    const ref = useOutsideClick(() => {
        setState(false);
    });

    const [state, setState] = useState(false);

    const handleButtonClick = () => {
        setState((prevState) => {
            return !prevState;
        });
    };
    let container = React.createRef();

    let onListItemClicked = (res: number) => {
        setState(false);
        setSelectionOfUser(res);
        model.CreateOrUpdateVote(res, props.user);
    };
    let classOfButton = '';
    let buttonContent = '▼';
    if (selectionOfUser == -1) {
        classOfButton = 'dropdown-button background-undecided';
        //      buttonContent = "Enthalten"
    } else {
        classOfButton = 'dropdown-button background-' + selectionOfUser;
        //     buttonContent = selectionOfUser.toString();
    }

    //   buttonContent += " ▼"

    const renderSliderThumb = (value: number) => {
        if (value >= 0) {
            return value;
        } else {
            return 'X';
        }
    };

    return (
        <div className="flex justify-between">
            <div className="containerxx">
                <div className="areachart">
                    <AreaChart
                        key={invalidations}
                        width={200}
                        height={25}
                        data={data3}
                        margin={{ top: 0, right: 12, left: 25, bottom: 0 }}
                    >
                        <Area
                            type="monotone"
                            dataKey="pv"
                            stroke="#82ca9d"
                            fill="#82ca9d"
                            yAxisId={1}
                        />
                    </AreaChart>
                </div>

                <ReactSlider
                    max={10}
                    min={-1}
                    value={selectionOfUser}
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    onChange={(newState, state) => onListItemClicked(newState)}
                    renderThumb={(props, state) => (
                        <div {...props}> {renderSliderThumb(state.valueNow)}</div>
                    )}
                />
            </div>

            <div className="dropdown-container" ref={ref}>
                <button className={classOfButton} onClick={handleButtonClick}>
                    {buttonContent}
                </button>
                {state && (
                    <div className="dropdown">
                        <ul>
                            <li
                                onClick={() => onListItemClicked(0)}
                                data-wpoints="0"
                                className="dropdown-item background-0"
                            >
                                0 - Is ok kein Widerstand
                            </li>
                            <li
                                onClick={() => onListItemClicked(1)}
                                className="dropdown-item background-1"
                            >
                                1
                            </li>
                            <li
                                onClick={() => onListItemClicked(2)}
                                className="dropdown-item background-2"
                            >
                                2
                            </li>
                            <li
                                onClick={() => onListItemClicked(3)}
                                className="dropdown-item background-3"
                            >
                                3
                            </li>
                            <li
                                onClick={() => onListItemClicked(4)}
                                className="dropdown-item background-4"
                            >
                                4
                            </li>
                            <li
                                onClick={() => onListItemClicked(5)}
                                className="dropdown-item background-5"
                            >
                                5 - Ich habe Bedenken
                            </li>
                            <li
                                onClick={() => onListItemClicked(6)}
                                className="dropdown-item background-6"
                            >
                                6
                            </li>
                            <li
                                onClick={() => onListItemClicked(7)}
                                className="dropdown-item background-7"
                            >
                                7
                            </li>
                            <li
                                onClick={() => onListItemClicked(8)}
                                className="dropdown-item background-8"
                            >
                                8
                            </li>
                            <li
                                onClick={() => onListItemClicked(9)}
                                className="dropdown-item background-9"
                            >
                                9
                            </li>
                            <li
                                onClick={() => onListItemClicked(10)}
                                className="dropdown-item background-10"
                            >
                                10 - Nur das nicht
                            </li>
                            <li
                                onClick={() => onListItemClicked(-1)}
                                className="dropdown-item background-whatever"
                            >
                                Enthalten
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {/* <ProgressBar  bgcolor={"#50DD50"} completed={acceptanceLevel * 100}/> */}
            {/* <p><progress  style={{background: '#90EE90', width: 80}} value={acceptanceLevel} max="1">50 %</progress></p> */}
        </div>
    );
}
