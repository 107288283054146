import React, { FC, useState } from 'react';
import { Input } from '../../../../components/ui/input';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../../components/ui/form';
import { Button } from '../../../../components/ui/button';
import { RadioGroup, RadioGroupItem } from '../../../../components/ui/radio-group';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Trans, useTranslation } from 'react-i18next';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Label } from '../../../../components/ui/label';
import { Checkbox } from '../../../../components/ui/checkbox';
import { FunnelChart } from 'recharts';
import {
    REDUCER_ACTION_TYPE,
    useNewSubjectContextValue,
} from '../../context/NewSubjectContext';
import { useAuthContextValue } from '../../context/AuthProviderContext';
import { Separator } from '../../../../components/ui/separator';

interface HowToAdmisterTheTopicProps {}

const HowToAdmisterTheTopic: FC<HowToAdmisterTheTopicProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['register', 'mysk', 'create_subject']);
    const navigate = useNavigate();
    const subjectContext = useNewSubjectContextValue();
    const authContext = useAuthContextValue();
    // const [isEmail, setIsEmail] = useState<boolean>(false);

    function saveTheData(values: z.infer<typeof formSchema>) {
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_IS_EMAIL,
            payload: values.test == 'email',
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_EMAIL,
            payload: values.email,
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_NAME,
            payload: values.name,
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_IS_TIMED,
            payload: values.timing == 'yes',
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_IS_ACCEPTED_AGB,
            payload: values.agbSelected,
        });
    }

    function onSubmit(values: z.infer<typeof formSchema>) {
        // Do something with the form values.
        // ✅ This will be type-safe and validated.
        saveTheData(values);
        console.log(values);
        navigate('/setup/settings', { replace: true });
    }

    function onEmailSetToInactive(e: any) {
        //       setIsEmail(false);
    }

    function onEmailSetToActive(e: any) {
        //     setIsEmail(true);
    }

    const emailSchema = z.object({
        name: z
            .string()
            .min(2, { message: 'Username must be at least 2 characters.' }),
        test: z.literal('email'),
        agbSelected: z.boolean().default(false),
        email: z.string().email(),
        timing: z.string(),
    });

    const addToMyListSchema = z.object({
        test: z.literal('mentions'),
        agbSelected: z.boolean().default(false),
        email: z.string().optional(),
        name: z.string().optional(),
        timing: z.string(),
    });

    const formSchema = z
        .discriminatedUnion('test', [addToMyListSchema, emailSchema])
        .refine(
            (data) => {
                if (data.test === 'email') {
                    return data.agbSelected;
                }
                return true;
            },
            {
                path: ['agbSelected'],
            }
        );

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            test: subjectContext.state.isEmail ? 'email' : 'mentions',
            timing: subjectContext.state.isTimed ? 'yes' : 'no',
        },
    });

    function onBack() {
        saveTheData(form.getValues());
        navigate('/setup/distrib_mode', { replace: true });
    }

    const isEmail = form.watch('test') == 'email';

    return (
        <div className="form-frame">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="test"
                        render={({ field }) => (
                            <FormItem className="space-y-3">
                                <FormLabel>
                                    {t('wanna_manage', { ns: 'create_subject' })}
                                </FormLabel>
                                <FormControl>
                                    <RadioGroup
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        className="flex flex-col space-y-1"
                                    >
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="mentions" />
                                            </FormControl>
                                            <FormLabel className="list">
                                                {authContext.state.isLoggedIn
                                                    ? t('choice.mysk')
                                                    : t('choice.mysk_login')}
                                            </FormLabel>
                                        </FormItem>
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="email" />
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                {t('choice.private_link')}
                                            </FormLabel>
                                        </FormItem>
                                    </RadioGroup>
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    {isEmail === true && (
                        <>
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('nameOrPseudonym')}</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>{t('register.email')}</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="agbSelected"
                                render={({ field }) => (
                                    <FormItem>
                                        <div className="mt-2 flex items-center">
                                            <FormControl>
                                                <Checkbox
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                            <div className="ml-2">
                                                <FormLabel>
                                                    <Trans
                                                        i18nKey="acceptAgb"
                                                        t={t}
                                                        ns="mysk"
                                                        components={{
                                                            linkTag: (
                                                                <Link
                                                                    to={
                                                                        '/terms_conditions'
                                                                    }
                                                                ></Link>
                                                            ),
                                                        }}
                                                    />
                                                </FormLabel>
                                            </div>
                                        </div>
                                    </FormItem>
                                )}
                            />
                        </>
                    )}
                    <FormField
                        control={form.control}
                        name="timing"
                        render={({ field }) => (
                            <FormItem className="space-y-3">
                                <Separator className="mt-3" />
                                <FormLabel>
                                    Soll das Thema zeitgesteuert behandelt werden
                                </FormLabel>
                                <FormControl>
                                    <RadioGroup
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        className="flex flex-col space-y-1"
                                    >
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="yes" />
                                            </FormControl>
                                            <FormLabel className="list">
                                                Ja: Es soll automatisiert
                                                zeigtesteuert ablaufen
                                            </FormLabel>
                                        </FormItem>
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value="no" />
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                Nein: Moderator:in soll den Ablauf
                                                bestimmen
                                            </FormLabel>
                                        </FormItem>
                                    </RadioGroup>
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    {/* <pre>{JSON.stringify(form.watch(),null,2)}</pre> */}
                    <div className="mt-2 float-right">
                        <Button type="submit" className="mr-2" onClick={onBack}>
                            <ChevronLeft />
                        </Button>
                        <Button type="submit">
                            <ChevronRight />
                        </Button>
                    </div>
                    <div className="clear-both" />
                </form>
            </Form>
        </div>
    );
};

export default HowToAdmisterTheTopic;
