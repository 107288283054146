import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContextValue } from '../context/AuthProviderContext';

import acceptify_triangle_logo from './images/acceptify_triangle_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faLightbulb,
    faGlobe,
    faBarChart,
    faMobile,
    faThumbsUp,
    faUsers,
    faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from '../../../components/ui/input';
import { Button } from '../../../components/ui/button';
import { ChevronRight } from 'lucide-react';

interface startProps {}

const Start: FC<startProps> = ({}) => {
    const { t, i18n } = useTranslation(['landing']);
    const authContext = useAuthContextValue();
    const [code, setCode] = useState<string>();
    const navigate = useNavigate();

    function loadSubjectFromCode() {
        navigate('/load_subject/' + code);
    }

    return (
        <div>
            <section id="hero">
                <div className="hero-container">
                    <br />
                    <form>
                        <p id="join_caption" className="text-4xl">
                            {t('joining_as_participant')}
                        </p>
                        <div id="quick-access" className="flex justify-center">
                            <Input
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <Button onClick={loadSubjectFromCode}>
                                <ChevronRight />
                            </Button>
                            {/* {{ form_widget(form.link, {'attr': {'class': 'align-self-center'}}) }}
        {{ form_buttons('button.continue', false, false, false, false, 'ml-1 only-left-margin') }} */}
                        </div>
                    </form>
                    <br />
                    <div id="welcome-container">
                        <p id="slogan">
                            {t('cooperate')} {t('cooperate_part_2')}
                        </p>
                        <p id="welcome-msg">{t('welcome_text_1')}</p>

                        <p className="video-container mt-2 mb-2 w-96 text-center m-auto">
                            <video className="video-container" controls>
                                <source
                                    src={
                                        require('./../videos/acceptify_intro.mp4')
                                            .default
                                    }
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </p>

                        <a href="#services" className="btn-get-started">
                            {t('landing.why.heading')}
                        </a>
                        <Link to="/selection" className="btn-get-started">
                            {t('subject.create')}
                        </Link>
                        {/* <Link to="/resistance_measurement" className="btn-get-started">
                            T
                        </Link>
                        <Link to="/erste" className="btn-get-started">
                            E
                        </Link>
                        <Link to={"/load_subject/"} className="btn-get-started">
                            Test
                        </Link> */}
                        <br />
                        {/* <a className="btn-get-started create-new-subject-link" href="{{ path('create_subject_start') }}"
               {% if(hasNotFinishedSubjectInCreation) %}data-has-subject-in-creation="true"{% endif %}
               id="start_create_subject">{t('subject.create')}</a>
            {% if(hasNotFinishedSubjectInCreation) %}
                <br/><a className="btn-get-started" href="{{ path('create_subject_start') }}"
                        id="start_continue_subject">{t('subject.continue' | trans({}, 'static') }}</a>
            {% endif %} */}
                    </div>
                </div>
            </section>
            <section id="services">
                <div className="container mx-auto sm:px-4">
                    <div className="section-header">
                        <h3 className="section-title">{t('landing.why.heading')}</h3>
                        <br></br>
                        <p className="section-description">
                            {t('landing.why.text')}
                        </p>
                    </div>
                    <div id="konsensierungprocess">
                        <img
                            src={
                                require('./../images/konsensierungsprozess_de.png')
                                    .default
                            }
                        />
                    </div>
                    <div className="flex flex-wrap ">
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faGlobe as IconProp} />
                                </div>
                                <h4 className="title">
                                    {t('landing.contribute.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.contribute.text')}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon
                                        icon={faLightbulb as IconProp}
                                    />
                                </div>
                                <h4 className="title">
                                    {t('landing.value.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.value.text')}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faBarChart as IconProp} />
                                </div>
                                <h4 className="title">
                                    {t('landing.measure.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.measure.text')}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faMobile as IconProp} />
                                </div>
                                <h4 className="title">
                                    {t('landing.devices.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.devices.text')}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faThumbsUp as IconProp} />
                                </div>
                                <h4 className="title">
                                    {t('landing.acceptify.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.acceptify.text')}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faReceipt as IconProp} />
                                </div>
                                <h4 className="title">
                                    {t('landing.convert.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.convert.text')}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 pr-4 pl-4 md:w-1/2">
                            <div className="box">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faUsers as IconProp} />
                                </div>
                                <h4 className="title">
                                    {t('landing.resources.heading')}
                                </h4>
                                <p className="description">
                                    {t('landing.resources.text')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {authContext.state.isLoggedIn && (
                <section id="call-to-action">
                    <div className="container mx-auto sm:px-4">
                        <div className="flex flex-wrap ">
                            <div className="lg:w-3/4 pr-4 pl-4 text-center lg:text-left">
                                <h3 className="cta-title">
                                    {t('landing.account.heading')}
                                </h3>
                                <p className="cta-text">
                                    {t('landing.account.text')}
                                </p>
                            </div>
                            <div className="lg:w-1/4 pr-4 pl-4 cta-btn-container text-center">
                                <a
                                    className="cta-btn align-middle"
                                    href="{{ path('pricing') }}"
                                >
                                    {t('register')}
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};
export default Start;
