import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

interface FlagIconProps {
    countryCode: string;
}

function FlagIcon({ countryCode = '' }: FlagIconProps) {
    if (countryCode === 'en') {
        countryCode = 'gb';
    }
    switch (countryCode) {
        case 'de':
            return (
                <svg
                    className="w-8 h-8"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957l-240.077-22.26z"
                        fill="#ffda44"
                    />
                    <path d="m256 0c-110.071 0-203.906 69.472-240.077 166.957l240.077 22.26 240.077-22.261c-36.171-97.484-130.006-166.956-240.077-166.956z" />
                    <path
                        d="m15.923 166.957c-10.29 27.733-15.923 57.729-15.923 89.043s5.633 61.31 15.923 89.043h480.155c10.29-27.733 15.922-57.729 15.922-89.043s-5.632-61.31-15.923-89.043z"
                        fill="#d80027"
                    />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                </svg>
            );
        case 'it':
            return (
                <svg
                    className="w-8 h-8"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
                    <path
                        d="m512 256c0-110.071-69.472-203.906-166.957-240.077v480.155c97.485-36.172 166.957-130.007 166.957-240.078z"
                        fill="#d80027"
                    />
                    <path
                        d="m0 256c0 110.071 69.472 203.906 166.957 240.077v-480.154c-97.485 36.171-166.957 130.006-166.957 240.077z"
                        fill="#6da544"
                    />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                </svg>
            );
        case 'gb':
            return (
                <svg
                    className="w-8 h-8"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
                    <g fill="#0052b4">
                        <path d="m52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178z" />
                        <path d="m503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076z" />
                        <path d="m8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075z" />
                        <path d="m411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177z" />
                        <path d="m100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102v-133.176z" />
                        <path d="m189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075z" />
                        <path d="m322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075z" />
                        <path d="m370.005 322.784 89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076z" />
                    </g>
                    <g fill="#d80027">
                        <path d="m509.833 222.609h-220.44-.001v-220.442c-10.931-1.423-22.075-2.167-33.392-2.167-11.319 0-22.461.744-33.391 2.167v220.44.001h-220.442c-1.423 10.931-2.167 22.075-2.167 33.392 0 11.319.744 22.461 2.167 33.391h220.44.001v220.442c10.931 1.423 22.073 2.167 33.392 2.167 11.317 0 22.461-.743 33.391-2.167v-220.44-.001h220.442c1.423-10.931 2.167-22.073 2.167-33.392 0-11.317-.744-22.461-2.167-33.391z" />
                        <path d="m322.783 322.784 114.236 114.236c5.254-5.252 10.266-10.743 15.048-16.435l-97.802-97.802h-31.482z" />
                        <path d="m189.217 322.784h-.002l-114.235 114.235c5.252 5.254 10.743 10.266 16.435 15.048l97.802-97.804z" />
                        <path d="m189.217 189.219v-.002l-114.236-114.237c-5.254 5.252-10.266 10.743-15.048 16.435l97.803 97.803h31.481z" />
                        <path d="m322.783 189.219 114.237-114.238c-5.252-5.254-10.743-10.266-16.435-15.047l-97.802 97.803z" />
                    </g>
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                </svg>
            );
        case 'es':
            return (
                <svg
                    className="w-8 h-8"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m0 256c0 31.314 5.633 61.31 15.923 89.043l240.077 22.261 240.077-22.261c10.29-27.733 15.923-57.729 15.923-89.043s-5.633-61.31-15.923-89.043l-240.077-22.261-240.077 22.261c-10.29 27.733-15.923 57.729-15.923 89.043z"
                        fill="#ffda44"
                    />
                    <g fill="#d80027">
                        <path d="m496.077 166.957c-36.171-97.484-130.006-166.957-240.077-166.957s-203.906 69.473-240.077 166.957z" />
                        <path d="m15.923 345.043c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957z" />
                    </g>
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                </svg>
            );
    }
}

interface Language {
    key: string;
    name: string;
}

const LANGUAGE_SELECTOR_ID = 'language-selector';

export const LanguageSelector = () => {
    const [languages, setLanguages] = useState<Language[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation(['generic']);

    useEffect(() => {
        var language = window.navigator.language;
        switch (language) {
            case 'de':
            case 'en':
            case 'it':
            case 'es':
                i18n.changeLanguage(language);
                break;
            default:
                i18n.changeLanguage('en');
                break;
        }
    }, []);

    const selectedLanguage = languages.find(
        (language) => language.key === i18n.language
    );
    const handleLanguageChange = async (language: Language) => {
        await i18n.changeLanguage(language.key);
        setIsOpen(false);
    };

    useEffect(() => {
        const setupLanguages = async () => {
            let appLanguages: {
                key: string;
                name: string;
            }[] = [
                { key: 'de', name: 'language_switch_de' },
                { key: 'en', name: 'language_switch_en' },
                { key: 'it', name: 'language_switch_it' },
                { key: 'es', name: 'language_switch_es' },
            ];
            setLanguages(appLanguages);
        };
        setupLanguages();
    }, []);

    useEffect(() => {
        const handleWindowClick = (event: any) => {
            const target = event.target.closest('button');
            if (target && target.id === LANGUAGE_SELECTOR_ID) {
                return;
            }
            setIsOpen(false);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);

    if (!selectedLanguage) {
        return null;
    }
    // "inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    return (
        <>
            <div className="flex items-center z-40">
                <div className="relative inline-block text-left">
                    <div>
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="transition-colors inline-flex items-center justify-center w-full hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50"
                            id={LANGUAGE_SELECTOR_ID}
                            aria-haspopup="true"
                            aria-expanded={isOpen}
                        >
                            <FlagIcon countryCode={selectedLanguage.key} />
                            {/* <div className="ml-2">{t(selectedLanguage.name)}</div> */}
                            <svg
                                className="-mr-1 ml-2 h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                    {isOpen && (
                        <div
                            className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="language-selector"
                        >
                            <div className="py-1 grid grid-cols-2 gap-2" role="none">
                                {languages.map((language, index) => {
                                    return (
                                        <button
                                            key={language.key}
                                            onClick={() =>
                                                handleLanguageChange(language)
                                            }
                                            className={`${
                                                selectedLanguage.key === language.key
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700'
                                            } block px-4 py-2 text-sm text-left items-center inline-flex hover:bg-gray-100 ${index % 2 === 0 ? 'rounded-r' : 'rounded-l'}`}
                                            role="menuitem"
                                        >
                                            <FlagIcon countryCode={language.key} />
                                            <span className="truncate ml-2">
                                                {t(language.name)}
                                            </span>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
