import axios from '../../apis/axiosApi';
import {
    GlobalContentType,
    REDUCER_ACTION_TYPE,
} from '../context/AuthProviderContext';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const setAuth = useAuth();

    const refresh = async () => {
        const response = await axios.get('/auth/refresh', {
            withCredentials: true,
        });
        console.log(JSON.stringify(setAuth.state));
        const accessToken = response.data.accessToken;
        const roles = response.data.roles;
        const name = response.data.name;
        console.log(accessToken);
        setAuth.dispatch({
            type: REDUCER_ACTION_TYPE.SET_USER,
            payload: name,
        });
        setAuth.dispatch({
            type: REDUCER_ACTION_TYPE.SET_ACCESS_TOKEN,
            payload: accessToken,
        });
        setAuth.dispatch({
            type: REDUCER_ACTION_TYPE.SET_ROLES,
            payload: roles,
        });
        return response.data.accessToken;
    };
    return refresh;
};

export default useRefreshToken;
