import React, { FC } from 'react';
import { ButtonGroup, Floater, GenerateProposalsButton } from '../note/buttonux';
import NextProcessStepSelector from '../decisionMethods/components/NextProcessStepSelector';
import {
    REDUCER_ACTION_TYPE,
    useSubjectContextValue,
} from '../context/SubjectContext';
import {
    createAppspace,
    createSettings,
} from '../../utils/fluidContainerMangagemnt';
import { NoteMain } from '../note/note_main';
import { ElementTypeEnum } from '../../utils/app_helpers';

interface DynamicCanvasProps {}

const DynamicCanvas: FC<DynamicCanvasProps> = ({}): JSX.Element => {
    const subjectContext = useSubjectContextValue();
    const fluidWorkspace = subjectContext.state.fluidWS;
    if (fluidWorkspace && fluidWorkspace.apps.root.length == 0) {
        const createSubject = async () => {
            await createSettings(fluidWorkspace);
            // create the workspace in the fluid container
            await createAppspace(fluidWorkspace, 'A');
            //await createAppspace(fluidWorkspace, "B");

            // Set again  in order to rerender
            subjectContext.dispatch({
                type: REDUCER_ACTION_TYPE.SET_FLUID_WORKSPACE,
                payload: fluidWorkspace,
            });
        };

        createSubject();
    }

    return (
        <>
            {fluidWorkspace && fluidWorkspace.apps.root.length > 0 ? (
                <>
                    <NoteMain
                        isWithGroups={true}
                        elementType={ElementTypeEnum.Dynamic}
                    />
                </>
            ) : (
                <>Loading...</>
            )}
        </>
        // <div>
        //     <div className='w-48 h-48'>Tsest</div>
        //     <Floater>
        //         <ButtonGroup>
        //             <NextProcessStepSelector/>
        //         </ButtonGroup>
        //     </Floater>
        // </div>
    );
};

export default DynamicCanvas;
