import React, { FC, useState } from 'react';
import { Input } from '../../../../components/ui/input';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../../components/ui/form';
import { Button } from '../../../../components/ui/button';
import { Textarea } from '../../../../components/ui/textarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { X, ChevronRight, ChevronLast, ChevronLeft } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    REDUCER_ACTION_TYPE as REDUCER_ACTION_TYPE_NEW_SUBJECT,
    useNewSubjectContextValue,
} from '../../context/NewSubjectContext';
import { createFluidContainer } from '../../../utils/fluidContainerMangagemnt';
import { useToast } from '../../../../components/hooks/use-toast';
import axios from 'axios';
import {
    REDUCER_ACTION_TYPE,
    useSubjectContextValue,
} from '../../context/SubjectContext';
import { setSubjectContext } from '../../static_pages/SubjectLoader';
import { Label } from '../../../../components/ui/label';

interface PassiveSuggestionProps {}
const CREATE_SUBJECT_URL = '/subject/newTheme';

const PassiveSuggestion: FC<PassiveSuggestionProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['create_subject']);
    const navigate = useNavigate();
    const newSubjectContext = useNewSubjectContextValue();
    const subjectContext = useSubjectContextValue();
    const { toast } = useToast();
    const [isDisableLabelViewer, setIsDisableLabelViewer] = useState(false);

    const formSchema = z.object({
        title: z.string().min(2, {
            message: 'Username must be at least 2 characters.',
        }),
        description: z.string(),
    });

    function saveTheData(values: z.infer<typeof formSchema>) {
        newSubjectContext.dispatch({
            type: REDUCER_ACTION_TYPE_NEW_SUBJECT.SET_PASSIVE_TITLE,
            payload: values.title,
        });
        newSubjectContext.dispatch({
            type: REDUCER_ACTION_TYPE_NEW_SUBJECT.SET_PASSIVE_DESCRIPTION,
            payload: values.description,
        });
    }

    async function createNewSubject() {
        try {
            // create a new fluid container
            const fluid = await createFluidContainer('');

            const response = await axios.post(
                CREATE_SUBJECT_URL,
                JSON.stringify({
                    type: newSubjectContext.state.type,
                    containerId: fluid.containerIdReady,
                    title: newSubjectContext.state.title,
                    userId: 1,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false,
                }
            );
            console.log(response?.data);
            const data = response?.data;

            console.log(JSON.stringify(response));

            setSubjectContext(data, fluid, subjectContext);
            // write the subject to the context

            navigate('/resistance_measurement', { replace: true });
        } catch (err: any) {
            if (!err?.response) {
                toast({ title: t('No server response') });
            } else {
                toast({ title: err?.response.statusText });
            }
        }
    }

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: newSubjectContext.state.passiveTitle,
            description: newSubjectContext.state.passiveDescription,
        },
    });

    function onBack() {
        saveTheData(form.getValues());
        navigate('/setup/settings', { replace: true });
    }

    function onWithoutPassive() {
        saveTheData(form.getValues());
        setIsDisableLabelViewer(true);
        //  navigate('/setup/distrib_mode', { replace: true });
        createNewSubject();
    }

    function onSubmit(values: z.infer<typeof formSchema>) {
        saveTheData(values);
        //  navigate('/setup/how_to_admister', { replace: true });
        createNewSubject();
    }

    return (
        <div className="form-frame">
            <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 mr-20 ml-20">
                {t('suggestion.no_decision.placeholder')}
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                            <FormItem>
                                {isDisableLabelViewer ? (
                                    <Label>
                                        {t('suggestion.no_decision.title_prefix')}
                                    </Label>
                                ) : (
                                    <FormLabel>
                                        {t('suggestion.no_decision.title_prefix')}
                                    </FormLabel>
                                )}
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={t(
                                            'suggestion.no_decision.title'
                                        )}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t('description')}</FormLabel>
                                <FormControl>
                                    <Textarea className="resize-none" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="flex mt-2 float-right">
                        <Button type="submit" className="mr-2" onClick={onBack}>
                            <div>
                                <ChevronLeft />
                            </div>
                        </Button>
                        <Button
                            type="submit"
                            variant="secondary"
                            className="mr-2 align-top"
                            onClick={onWithoutPassive}
                        >
                            {t('action.delete_no_action_suggestion')}
                        </Button>
                        <Button type="submit" className="align-text-top">
                            {t('action.apply')}
                        </Button>
                    </div>
                    <div className="clear-both" />
                    {/* <Button type="submit">{t('button.ok', { ns: 'buttons' })}</Button> */}
                </form>
            </Form>
        </div>
    );
};

export default PassiveSuggestion;
