import React, { useRef, useCallback, useEffect, useState } from 'react';
import {
    faCheck,
    faTimes,
    faInfoCircle,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { match } from 'assert';
import axiosApi from '../../apis/axiosApi';
import { Button, buttonVariants } from '../../../components/ui/button';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../components/ui/form';
import { Input } from '../../../components/ui/input';
import { Checkbox } from '../../../components/ui/checkbox';
import { Trans, useTranslation } from 'react-i18next';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^[A-z][A-z0-9-_]{1,23}$/; // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/auth/signup';
const EMAIL_REGEX =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export function Register(): JSX.Element {
    const { t, i18n } = useTranslation([
        'register',
        'mysk',
        'clientside',
        'email_content',
    ]);

    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current?.focus();
    }, []);

    const formSchema = z
        .object({
            username: z
                .string()
                .min(2, { message: 'Username must be at least 2 characters.' }),
            password: z
                .string()
                .regex(
                    PWD_REGEX,
                    ' 8 to 24 characters.\nMust include uppercase and lowercase letters, a number and a special character.\n  Allowed special characters:!@#$%'
                ),
            confirm: z.string(),
            email: z.string().email(),
            isAccepted: z.boolean(),
        })
        .refine((data) => data.password === data.confirm, {
            message: "Passwords don't match",
            path: ['confirm'],
        })
        .refine((data) => data.isAccepted === true, {
            message: 'Must be accepted',
            path: ['isAccepted'],
        });

    // 1. Define your form.
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: '',
            email: '',
            isAccepted: false,
        },
    });

    function emailInfo(): string {
        const userN = form.getValues('username');
        const emailSignup =
            "<div style='color:#132F40;font-family:Cabin, Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;'> \
        <div style='font-size: 12px; line-height: 1.2; color: #132F40; font-family: Cabin, Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;'> \
            <p style='font-size: 22px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 26px; margin: 0;'> \
                <span style='font-size: 22px;'>" +
            t('greetings', { ns: 'email_content', pseudonym: userN }) +
            "</span> \
            </p> \
        </div> \
    </div> \
    <div style='color:#555555;font-family:Cabin, Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.5;padding-top:5px;padding-right:10px;padding-bottom:30px;padding-left:10px;'> \
        <div style='font-size: 12px; line-height: 1.5; color: #555555; font-family: Cabin, Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 18px;'> \
            <p style='font-size: 14px; line-height: 1.5; word-break: break-word; mso-line-height-alt: 21px; margin: 0;'> \
                <span style='font-size: 16px;'>" +
            t('thanks_for_joining', { ns: 'email_content' }) +
            "</span><br/> \
                <span style='font-size: 16px;'>" +
            t('your_username_is', { ns: 'email_content' }) +
            "<span \
                            style='color: #000000; font-size: 16px;'><strong>" +
            userN +
            "</strong></span></span> \
            </p> \
        </div> \
    </div> \
    <div style='color:#555555;font-family:Cabin, Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:20px;padding-right:10px;padding-bottom:10px;padding-left:10px;'> \
        <div style='font-size: 12px; line-height: 1.2; color: #555555; font-family: Cabin, Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;'> \
            <p style='font-size: 16px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 19px; margin: 0;'> \
                <span style='font-size: 16px;'>" +
            t('new_registration_confirmation_text', { ns: 'email_content' }) +
            "</span> \
            </p> \
        </div> \
    </div> \
    <table width='100%' style='max-width:100%'> \
        <tr> \
            <td> \
                <table border='0' align='center' cellpadding='0' cellspacing='0' style='margin:0 auto;'> \
                    <tr>\
                        <td align='center'>\
                            <table border='0' cellpadding='0' cellspacing='0' style='margin:0 auto;'> \
                                <tr> \
                                    <td align='left' bgcolor='#f29400' style='min-width:180px; -moz-border-radius: 50px; -webkit-border-radius: 50px; border-radius: 50px; font-family: 'Neutraface-Demi', Arial, 'Helvetica Neue', Helvetica, sans-serif;'>\
                                        <a class='msoAltFont' href='{{ confirmationUrl }}' style='padding: 9px 12px; mso-padding-alt: 9px 12px; min-width:180px; display: block;text-decoration: none;border:0; text-align: center; font-weight: 600;font-size: 16px; color: #000; background: #f29400; border: 1px solid #f29400;-moz-border-radius: 50px; -webkit-border-radius: 50px; border-radius: 50px; mso-line-height-rule: exactly; line-height:32px;'>" +
            t('confirm_mail_address', { ns: 'email_content' }) +
            '</a>\
                                    </td>\
                                </tr>\
                            </table>\
                        </td>\
                    </tr>\
                </table>\
            </td>\
        </tr>\
    </table>\
    <br>';
        return emailSignup;
    }

    async function onSubmit(values: z.infer<typeof formSchema>) {
        const userN = form.getValues('username');
        const title = t('greetings', { ns: 'email_content', pseudonym: userN });

        try {
            const emailInfo1 = emailInfo();
            const response = await axiosApi.post(
                REGISTER_URL,
                JSON.stringify({
                    name: values.username,
                    password: values.password,
                    email: values.email,
                    emailInfo: emailInfo1,
                    title: title,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            console.log(response?.data);
            //FIXXXME   console.log(response?.accessToken);
            console.log(JSON.stringify(response));
            setSuccess(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username taken');
            } else if (err.response?.status === 410) {
                setErrMsg(t('fos_user.email.already_used', { ns: 'clientside' }));
            } else {
                setErrMsg('Registration Failed');
            }
            errRef.current?.focus();
        }
    }

    //const isEmail = form.watch("test") == 'email';

    return (
        <div className="m-14 p-5 bg-slate-300 rounded">
            {success ? (
                <>
                    <h1>{t('thanks_for_registration', { ns: 'mysk' })}</h1>
                    <div>
                        <Trans
                            i18nKey="registration.check_email"
                            t={t}
                            values={{ email: form.getValues('email') }}
                        />
                    </div>
                </>
            ) : (
                <div className="flex">
                    <div className="flex-1 w-64">
                        {success ? (
                            <section>
                                <h1>Success!</h1>
                                <p>
                                    <a href="#">Sign In</a>
                                </p>
                            </section>
                        ) : (
                            <section>
                                <Form {...form}>
                                    <p
                                        ref={errRef}
                                        className={errMsg ? 'errmsg' : 'offscreen'}
                                        aria-live="assertive"
                                    >
                                        {errMsg}
                                    </p>
                                    <h1 className="text-center mb-5">
                                        {t('register', { ns: 'mysk' })}
                                    </h1>
                                    <form
                                        onSubmit={form.handleSubmit(onSubmit)}
                                        className="space-y-8"
                                    >
                                        <FormField
                                            control={form.control}
                                            name="username"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            placeholder={t(
                                                                'nameOrPseudonym'
                                                            )}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="email"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            placeholder={t(
                                                                'register.email'
                                                            )}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            type="password"
                                                            placeholder={t(
                                                                'password'
                                                            )}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="confirm"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            type="password"
                                                            placeholder={t(
                                                                'password_repeat'
                                                            )}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="isAccepted"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <div className="mt-2 flex items-center">
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field.value}
                                                                onCheckedChange={
                                                                    field.onChange
                                                                }
                                                            />
                                                        </FormControl>
                                                        <div className="ml-2">
                                                            <FormLabel>
                                                                <Trans
                                                                    i18nKey="acceptAgb"
                                                                    t={t}
                                                                    ns="mysk"
                                                                    components={{
                                                                        linkTag: (
                                                                            <Link
                                                                                to={
                                                                                    '/terms_conditions'
                                                                                }
                                                                            ></Link>
                                                                        ),
                                                                    }}
                                                                />
                                                            </FormLabel>
                                                        </div>
                                                    </div>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <Button type="submit">
                                            {t('register', { ns: 'mysk' })}
                                        </Button>
                                    </form>
                                    {/* <p>
                                            Already registered?
                                            <br />
                                            <span className="line">
                                               
                                                <Link to={''}>{t('login')}</Link>
                                            </span>
                                        </p> */}
                                </Form>
                            </section>
                        )}
                    </div>

                    <div className="flex-1 w-64 m-5 p-5 bg-blue-300 rounded">
                        <h1 className="text-center mb-5">
                            {t('register_advantages', { ns: 'mysk' })}
                        </h1>
                        <div className="flex justify-start">
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                            <div className="sign-up-advantage ml-3 mb-3">
                                {' '}
                                {t('register_advantage_easy_change', { ns: 'mysk' })}
                            </div>
                        </div>
                        <div className="flex justify-start">
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                            <div className="sign-up-advantage ml-3 mb-3">
                                {' '}
                                {t('register_advantage_my_list', { ns: 'mysk' })}
                            </div>
                        </div>
                        <div className="flex justify-start">
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                            <div className="sign-up-advantage ml-3 mb-3">
                                {t('register_advantage_invitation_lists', {
                                    ns: 'mysk',
                                })}
                            </div>
                        </div>
                        <div className="flex justify-start">
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                            <div className="sign-up-advantage ml-3 mb-3">
                                {t('register_advantage_avatar', { ns: 'mysk' })}
                            </div>
                        </div>
                        <div className="flex justify-start">
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                            <div className="sign-up-advantage ml-3 mb-3">
                                {t('register_advantage_default_psudonym', {
                                    ns: 'mysk',
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
