import React, { FC, useEffect, useRef, useState } from 'react';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../components/ui/form';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '../../../components/ui/input';
import { useTranslation } from 'react-i18next';
import { Button, buttonVariants } from '../../../components/ui/button';
import axiosApi from '../../apis/axiosApi';

const REGISTER_URL = '/users/reset_pwd';

function ForgotPassword(): JSX.Element {
    const { t, i18n } = useTranslation([
        'register',
        'mysk',
        'email_content',
        'invatation',
    ]);
    const [requestSent, setRequestSend] = useState(false);
    const [user, setUser] = useState('');
    const errRef = useRef<HTMLParagraphElement>(null);
    const [errMsg, setErrMsg] = useState('');
    const userRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        userRef.current?.focus();
    }, []);

    const formSchema = z.object({
        username: z.string().min(2, {
            message: 'Username must be at least 2 characters.',
        }),
    });

    // 1. Define your form.
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: '',
        },
    });

    function emailInfo(): string {
        const emailSignup =
            "<div style='color:#132F40;font-family:Cabin, Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;'> \
        <div style='font-size: 12px; line-height: 1.2; color: #132F40; font-family: Cabin, Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;'> \
            <p style='font-size: 22px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 26px; margin: 0;'> \
                <span style='font-size: 22px;'>" +
            t('greetings_without_name', { ns: 'email_content' }) +
            "</span> \
            </p> \
        </div> \
    </div> \
    <div style='color:#555555;font-family:Cabin, Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:20px;padding-right:10px;padding-bottom:10px;padding-left:10px;'> \
        <div style='font-size: 12px; line-height: 1.2; color: #555555; font-family: Cabin, Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;'> \
            <p style='font-size: 16px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 19px; margin: 0;'> \
                <p style='font-size: 16px;'>" +
            t('password_reset_request', { ns: 'email_content' }) +
            "</padding> \
                <p style='font-size: 16px;'>" +
            t('password_reset_did_not_send', { ns: 'email_content' }) +
            "</p> \
                <p style='font-size: 16px;'>" +
            t('password_reset_click_link', { ns: 'email_content' }) +
            "</p> \
            </p> \
        </div> \
    </div> \
    <table width='100%' style='max-width:100%'> \
        <tr> \
            <td> \
                <table border='0' align='center' cellpadding='0' cellspacing='0' style='margin:0 auto;'> \
                    <tr>\
                        <td align='center'>\
                            <table border='0' cellpadding='0' cellspacing='0' style='margin:0 auto;'> \
                                <tr> \
                                    <td align='left' bgcolor='#f29400' style='min-width:180px; -moz-border-radius: 50px; -webkit-border-radius: 50px; border-radius: 50px; font-family: 'Neutraface-Demi', Arial, 'Helvetica Neue', Helvetica, sans-serif;'>\
                                        <a class='msoAltFont' href='{{ resetPasswordUrl }}' style='padding: 9px 12px; mso-padding-alt: 9px 12px; min-width:180px; display: block;text-decoration: none;border:0; text-align: center; font-weight: 600;font-size: 16px; color: #000; background: #f29400; border: 1px solid #f29400;-moz-border-radius: 50px; -webkit-border-radius: 50px; border-radius: 50px; mso-line-height-rule: exactly; line-height:32px;'>" +
            t('password_reset', { ns: 'email_content' }) +
            '</a>\
                                    </td>\
                                </tr>\
                            </table>\
                        </td>\
                    </tr>\
                </table>\
            </td>\
        </tr>\
    </table>\
    <br>';
        return emailSignup;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const emailText = emailInfo();
        const title = t('greetings_without_name', { ns: 'email_content' });
        try {
            const response = await axiosApi.post(
                REGISTER_URL,
                JSON.stringify({ name: user, text: emailText, title: title }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false,
                }
            );
            console.log(response?.data);
            //FIXXXME   console.log(response?.accessToken);
            console.log(JSON.stringify(response));
            setRequestSend(true);
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUser('');
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed');
            }
            errRef.current?.focus();
        }
    };

    return (
        <div className="form-frame">
            <Form {...form}>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <p
                        ref={errRef}
                        className={errMsg ? 'errmsg' : 'offscreen'}
                        aria-live="assertive"
                    >
                        {errMsg}
                    </p>
                    {requestSent ? (
                        <div>
                            <p>
                                Eine E-Mail wurde verschickt. Sie beinhaltet einen
                                Link zum Zurücksetzen des Passwortes.
                            </p>
                            <p>
                                Hinweis: Ein neues Passwort kann nur alle 2 Stunden
                                beantragt werden.
                            </p>
                            <p />
                            <p>
                                Eventuell wurde diese E-Mail als Spam markiert, wenn
                                sie nicht angekommen ist.
                            </p>
                        </div>
                    ) : (
                        <>
                            <p>{t('lost_access_request_info', { ns: 'mysk' })}</p>
                            <p>
                                {t('security.lost_access.email', {
                                    ns: 'invatation',
                                })}
                            </p>
                            <Input
                                type="text"
                                id="username"
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                                aria-describedby="uidnote"
                                ref={userRef}
                            />
                            <Button>
                                {t('confirm_password_reset', {
                                    ns: 'email_content',
                                })}{' '}
                            </Button>
                        </>
                    )}
                </form>
            </Form>
        </div>
    );
}

export default ForgotPassword;
