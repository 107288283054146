import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface termsAndConditionsProps {}

function termsAndConditionsMultilanguage(language: string) {
    switch (language) {
        case 'de':
            return (
                <>
                    <h2 id="agb">
                        Allgemeine Geschäftsbedingungen für die Nutzung von acceptify
                    </h2>
                    <h3>1. Allgemeines</h3>
                    <ol>
                        <li>
                            <p>
                                Die acceptify GmbH (im Folgenden „acceptify“) stellt
                                unter *acceptify.at Software und Dienstleistungen (im
                                Folgenden zusammenfassend „Dienste“) zur Verfügung.
                                Mit der Zustimmung zu den AGBs treten die aktuell
                                gültigen AGBs in Kraft.
                            </p>
                        </li>
                        <li>
                            <p>
                                Bei Änderungen an den AGBs werden Sie erneut zur
                                Zustimmung aufgefordert. Solange Sie nicht dazu
                                aufgefordert werden, gelten die bereits akzeptierten
                                AGBs. Die jeweils aktuellste Version der AGB ist
                                unter{' '}
                                <a href="#agb">https://acceptify.at/de/terms</a>
                                einsehbar.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sollten einzelne Bestimmungen dieser AGB ungültig
                                sein oder werden, bleibt die Gültigkeit der übrigen
                                Bestimmungen unberührt.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify behält sämtliche Urheber- und sonstigen
                                Rechte an den Diensten. Sie sind nicht berechtigt,
                                rechtliche Hinweise (z. B. Urheberrechts- oder
                                Markenhinweise) zu entfernen, zu verdecken oder zu
                                verändern. Sie dürfen die Marke von acceptify nicht
                                für eigene Zwecke nutzen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Verletzen Sie die Bestimmungen dieser Vereinbarungen
                                ist acceptify berechtigt Ihr Benutzerkonto bzw. Ihren
                                Zugriff zu den Diensten zu sperren.
                            </p>
                        </li>
                        <li>
                            <p>
                                Diese AGB bilden die gesamte Vereinbarung zwischen
                                Ihnen und acceptify und ersetzen sämtliche vorherigen
                                Vereinbarungen unter diesem Titel. Mündliche
                                Nebenabreden bestehen nicht.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie stimmen zu von acceptify bei Änderungen an diesen
                                AGBs sowie bei Neuerungen oder Änderungen an den
                                Diensten per E-Mail informiert zu werden.
                            </p>
                        </li>
                        <li>
                            <p>
                                Es gilt in jedem Fall Österreichisches Recht,
                                unabhängig von Ihrem Standort. Gerichtsstand ist
                                Graz, Österreich.
                            </p>
                        </li>
                    </ol>
                    <h3>2. Leistungserbringung durch acceptify</h3>
                    <ol>
                        <li>
                            <p>
                                acceptify übernimmt keine Gewährleistung dafür, dass
                                (i) die Dienste Ihren Anforderungen oder Erwartungen
                                entsprechen, (ii) die Dienste ununterbrochen,
                                zeitgerecht, sicher und fehlerfrei zur Verfügung
                                stehen, (iii) die durch die Benutzung der Dienste
                                erhaltenen Resultate korrekt und zuverlässig sind,
                                (iv) allfällige Fehler in den Diensten korrigiert
                                werden.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify haftet nicht für direkte oder indirekte
                                Schäden irgendwelcher Art, die resultieren durch: (i)
                                die Nutzung bzw. das Unvermögen zur Nutzung der
                                Dienste; (ii) Änderungen an den Diensten; (iii)
                                dauerhafte und vorübergehende Nichtverfügbarkeit der
                                Dienste; (iv) Nichtverfügbarkeit bzw. nicht korrekte
                                Verfügbarkeit von durch die Dienste verarbeiteten
                                Daten; (v) oder andere Ursachen im Zusammenhang mit
                                den Diensten.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify leistet technischen Support auf „Best
                                Effort“-Basis und ausschließlich via E-Mail.
                                acceptify übernimmt keine Gewährleistung für die
                                Verfügbarkeit von technischem Support.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für Support und insbesondere der Einführung unserer
                                Dienste in Ihre Organisation bietet acceptify
                                optionale Schulungs- und Beratungspakete an.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify hat das Recht, die Dienste (oder Teile
                                davon) jederzeit dauerhaft oder vorübergehend
                                einzustellen, zu entfernen oder zu ändern oder neue
                                Dienste hinzuzufügen. Diese Änderungen unterliegen
                                automatisch diesen Bestimmungen. Durch Ihre weitere
                                Nutzung der Dienste nach solchen Änderungen erklären
                                Sie sich stillschweigend mit den Änderungen
                                einverstanden.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify ist berechtigt, die vom Anwender im Rahmen
                                der Nutzung der Dienste eingegebenen und die dabei
                                erzeugten und dem Anwender zurechenbaren Daten zur
                                weiteren Verarbeitung zu speichern. Die Verantwortung
                                für die Zulässigkeit der Erfassung und Richtigkeit
                                der Daten sowie die Wahrung der Vertraulichkeit
                                gegenüber involvierten Personen (z.B. Teilnehmer an
                                Umfragen) liegt beim Anwender. Außerdem trägt der
                                Anwender die alleinige Verantwortung für gespeicherte
                                Inhalte, die lizenzpflichtig sind (z.B. Schriften und
                                Bilder).
                            </p>
                        </li>
                    </ol>
                    <h3>3. Ihre Nutzung der Dienste</h3>
                    <ol>
                        <li>
                            <p>
                                Sie erklären sich einverstanden, die Dienste nur
                                gemäß (i) der vorliegenden Bestimmungen und (ii) der
                                für Sie relevanten Regelungen und Gesetzgebung
                                einzusetzen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für manche Dienste (oder Teile davon) kann acceptify
                                eine Registrierung für ein Benutzerkonto voraussetzen
                                oder optional anbieten. Sie sind verpflichtet, alle
                                im Rahmen einer Registrierung nötigen Informationen
                                korrekt und wahrheitsgemäß auszufüllen und aktuell zu
                                halten. Sie sind außerdem verantwortlich für die
                                Sicherheit Ihres Passworts. Es ist nicht zulässig,
                                Konten automatisiert zu erstellen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Für manche Dienste erlaubt acceptify, ein
                                persönliches Profilbild zu konfigurieren. Sie sind
                                dafür verantwortlich, dass Ihr Profilbild nicht
                                missbräuchlich ist, keine Urheber- oder Marken- oder
                                sonstigen Rechte verletzt und nicht anstößig wirkt
                                auf andere acceptify-Benutzer.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie dürfen sich auf acceptify nicht als eine andere
                                Person ausgeben.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie erklären sich einverstanden, die Dienste nicht in
                                irgendeiner Weise absichtlich zu stören oder zu
                                unterbrechen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie erklären sich einverstanden, die Dienste zu
                                keinem Zweck zu reproduzieren, zu kopieren, zu
                                verkaufen oder zu handeln. Zu diesem Zweck gibt es
                                die Möglichkeit einer Vertriebspartnerschaft.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie erklären sich mit der Datenschutzrichtlinie von
                                acceptify einverstanden. Diese ist einsehbar unter
                                <a href="#privacy">
                                    https://acceptify.at/de/terms#privacy
                                </a>
                                .
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify behält sich vor (aber ist nicht dazu
                                verpflichtet), Inhalte oder Konten innerhalb der
                                Dienste zu prüfen, zu filtern, zu verändern,
                                zurückzuweisen oder zu löschen. Sie nehmen zur
                                Kenntnis, dass Sie durch die Benutzung von acceptify
                                Daten ausgesetzt werden können, die beleidigend,
                                anstößig oder in anderer Weise zu beanstanden sind.
                            </p>
                        </li>
                    </ol>
                    <h3>4. Werbung</h3>
                    <ol>
                        <li>
                            <p>
                                Manche Dienste werden bereits oder in Zukunft durch
                                Werbeanzeigen finanziert. Das Einblenden dieser
                                Werbeanzeigen kann gesteuert werden durch die
                                Informationen, die durch die Benutzung der Dienste
                                entstehen.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie erklären sich damit einverstanden, dass acceptify
                                Werbung einblendet.
                            </p>
                        </li>
                        <li>
                            <p>
                                Art und Umfang der Werbung kann jederzeit geändert
                                werden.
                            </p>
                        </li>
                        <li>
                            <p>
                                Falls Sie einen kostenfreien Dienst benützen, dürfen
                                Sie die Werbung nicht blockieren.
                            </p>
                        </li>
                    </ol>
                    <h3>5. Bezahlte Dienste</h3>
                    <p>
                        Die Benutzung irgendeines der kostenpflichtigen acceptify
                        angebotenen acceptify Bezahl Abos unterliegen diesen AGB
                        sowie den folgenden spezifischen Bestimmungen:
                    </p>
                    <ol>
                        <li>
                            <p>
                                Kostenpflichtiges acceptify wird im Voraus
                                verrechnet. Jegliche Rückerstattung ist
                                ausgeschlossen. Rückerstattung ist explizit auch in
                                folgenden Fällen ausgeschlossen: Ausfall der Dienste
                                während der Abonnements Periode, Downgrade, teilweise
                                Nutzung der Dienste, Änderung des Funktionsumfangs.
                            </p>
                        </li>
                        <li>
                            <p>
                                Jegliche Abos verlängern sich automatisch sofern
                                keine schriftliche Kündigung vor Ende der aktuellen
                                Aboperiode erfolgt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Kündigung: Sie können Ihr Abo jederzeit mit einem
                                formlosen schreiben an{' '}
                                <a href="mailto:vertrieb@acceptify.at">
                                    vertrieb@acceptify.at
                                </a>
                                kündigen. Ihr Abo bleibt bis zum Ende der Aboperiode
                                aktiv.
                            </p>
                        </li>
                        <li>
                            <p>
                                Übermäßiger Gebrauch und Missbrauch von
                                kostenpflichtiges acceptify können die vorübergehende
                                oder dauerhafte Sperrung des Zugangs zur Folge haben.
                                Wann eine Nutzung missbräuchlich oder übermässig ist,
                                entscheidet acceptify alleinig.
                            </p>
                        </li>
                        <li>
                            <p>
                                Wenn Sie kostenpflichtiges acceptify abonnieren,
                                entscheiden Sie sich für eine bestimmte Anzahl
                                zugelassener User. Es handelt sich dabei um die
                                Anzahl User, die an aktuell aktiven Konsensierungen
                                ihrer Organisation teilnehmen kann. Ein User wird
                                anhand seiner E-Mail-Adresse identifiziert. Wenn eine
                                Person mehrere unterschiedliche E-Mail-Adressen
                                benutzt, um Konsensierungen bei acceptify
                                einzurichten, so gilt jede unterschiedliche
                                E-Mail-Adresse als ein separater User. Verwendet ein
                                User keine E-Mail Adresse wird er jedenfalls in jeder
                                Konsensierung in der er teilnimmt gezählt. Sie sind
                                dafür verantwortlich, falls die maximale Userzahl
                                überschritten wurde, ein Upgrade zu einer höheren Abo
                                Stufe zu machen.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify behält sich vor, ein Benutzerkonto
                                dauerhaft oder vorübergehend zu sperren, falls diese
                                Bestimmungen verletzt werden. Wann eine Verletzung
                                vorliegt, entscheidet acceptify alleinig. Falls ein
                                Benutzerkonto gesperrt wird, macht acceptify den
                                Inhaber des Benutzerkontos via E-Mail auf die
                                Sperrung aufmerksam.
                            </p>
                        </li>
                        <li>
                            <p>
                                Ein kostenpflichtiges acceptify ist immer mit einem
                                Benutzerkonto assoziiert. acceptify kommuniziert
                                immer über die im betreffenden Benutzerkonto
                                hinterlegte E-Mail-Adresse. Sie sind dafür
                                verantwortlich, die E-Mail-Adresse auf dem aktuellen
                                Stand zu halten.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify behält sich vor, die Preise aller Dienste
                                jederzeit zu ändern. Preisänderungen haben allerdings
                                keinen Einfluss auf laufende Abonnements oder
                                Verträge sondern treten erst bei der nächsten
                                Vertrags- bzw. Abonnementsverlängerung in Kraft.
                            </p>
                        </li>
                    </ol>
                    <br />
                    <h2 id="privacy">Datenschutz und Eigenverantwortlichkeit</h2>
                    <p>
                        <strong>Datenschutzerkl&auml;rung </strong>
                    </p>
                    <p>
                        Au&szlig;er Ihrer E-Mail-Adresse erheben wir und speichern
                        wir keinerlei personenbezogene Daten.
                    </p>
                    <p>
                        Die Rechtm&auml;&szlig;igkeit dieser Speicherung und
                        Verarbeitung ist laut DSGVO Art. 6 (1) gegeben: Wie Sie den
                        Gr&uuml;nden f&uuml;r die Speicherung aus der Aufz&auml;hlung
                        unten entnehmen k&ouml;nnen, dient diese ausschlie&szlig;lich
                        dazu, die ordnungsgem&auml;&szlig;e Qualit&auml;t der von
                        ihnen erwarteten Leistungen (Konsensierungen, an denen Sie
                        teilnehmen, bzw. Zusendung des Newsletters) zu sichern.
                    </p>
                    <p>Die E-Mail-Adresse wird gespeichert,</p>
                    <ol>
                        <li>
                            um Mehrfach-Teilnahmen derselben Person an derselben
                            Konsensierung zumindest zu erschweren. Falls Sie Ihre
                            E-Mail-Adresse bei einer Konsensierung nicht eingeben
                            wollen und dies bei dieser Konsensierung aber verlangt
                            ist, k&ouml;nnen Sie daran nicht teilnehmen. Ihre
                            E-Mail-Adresse, die aus diesem Grund gespeichert wurde,
                            wird 14 Tage nach dem Ablaufdatum der Konsensierung
                            wieder gel&ouml;scht.
                        </li>
                        <li>
                            falls Sie als MeinSK-Benutzer ein Profil anlegen: um
                            mehrfach-Registrierung derselben Person zumindest zu
                            erschweren. Ohne Angabe einer E-Mail-Adresse k&ouml;nnen
                            Sie sich nicht als MeinSK-Benutzer registrieren. Ihre
                            E-Mail-Adresse, die aus diesem Grund gespeichert wurde,
                            wird gel&ouml;scht, wenn sie den Profileintrag
                            l&ouml;schen. Damit k&ouml;nnen sie auch Ihr
                            Widerrufsrecht jederzeit wahren.
                        </li>
                        <li>
                            um Ihnen den Newsletter zusenden zu k&ouml;nnen, falls
                            Sie diesen beantragen. Ohne Angabe einer E-Mail-Adresse
                            k&ouml;nnen wir Ihnen keinen Newsletter zusenden. Ihre
                            E-Mail-Adresse, die zu Ihrem Benutzer gespeichert wurde,
                            wird wieder gel&ouml;scht wenn sie den Benutzer
                            l&ouml;schen. Damit k&ouml;nnen sie auch Ihr
                            Widerrufsrecht jederzeit wahren.
                        </li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>
                        Widerrufsrecht: Ihr Widerrufsrecht ist in der obigen
                        Aufz&auml;hlung bei jedem Grund gesondert angef&uuml;hrt
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Eine personenbezogene Auswertung Ihrer pers&ouml;nlichen
                        Daten erfolgt nicht. Einzig zahlenm&auml;&szlig;ige
                        Statistiken &uuml;ber die Benutzung des Systems werden
                        erstellt.
                    </p>
                    <p>
                        Die Adresse des Datenschutzbeauftragten ist{' '}
                        <a href="mailto:markus.kickmaier@acceptify.at">
                            markus.kickmaier@acceptify.at
                        </a>
                        .
                    </p>
                    <p>
                        Ihre Daten werden auf europ&auml;ischen Servern gespeichert.
                    </p>
                    <p>
                        Sie haben das Recht, sich bei einer Aufsichtsbeh&ouml;rde zu
                        beschweren, falls Sie der Ansicht sind, dass wir ihre
                        personenbezogenen Daten missbr&auml;uchlich verwenden.
                    </p>
                    <p>
                        Die Eingabe von Daten im Rahmen einer Konsensierung erfolgt
                        ausdr&uuml;cklich auf freiwilliger Basis. Wir versichern,
                        dass wir diese Daten vertraulich behandeln und nicht an
                        Dritte weitergeben werden.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Eigenverantwortlichkeit</strong>
                    </p>
                    <p>
                        F&uuml;r die Eintr&auml;ge im Rahmen einer Konsensierung sind
                        alleine die Verfasser verantwortlich. Wir behalten uns vor,
                        gesetzes- oder sittenwidrige Eintr&auml;ge zu l&ouml;schen.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Haftungsausschluss</strong>
                    </p>
                    <p>
                        Das System wurde von uns sorgf&auml;ltig getestet. Wir
                        bem&uuml;hen uns, es st&auml;ndig online zu halten und Fehler
                        so rasch wie m&ouml;glich zu beheben.
                    </p>
                    <p>
                        Trotzdem k&ouml;nnen wir keine Garantien &uuml;ber
                        Verf&uuml;gbarkeit oder Korrektheit der
                        Konsensierungsresultate geben. Insbesondere &uuml;bernehmen
                        wir keinerlei Haftung f&uuml;r Folgen, die aus der Benutzung
                        des Systems oder eventuellem Datenverlust resultieren.
                    </p>
                </>
            );
            break;
        case 'en':
            return (
                <>
                    <div>
                        <h2 id="agb">
                            General terms and conditions for the use of acceptify
                        </h2>
                        <h3>1. General</h3>
                        <ol>
                            <li>
                                <p>
                                    acceptify GmbH (\"acceptify\") provides software
                                    and services (hereinafter collectively
                                    \"Services\") under *acceptify.at. With the
                                    acceptance of the GTCs, the currently valid GTCs
                                    become effective.
                                </p>
                            </li>
                            <li>
                                <p>
                                    In the event of changes to the GTCs, you will be
                                    asked to agree again. As long as you are not
                                    requested to do so, the already accepted GTCs
                                    apply. The most current version of the GTCs can
                                    be viewed at
                                    <a href="#agb">
                                        {' '}
                                        https://acceptify.at/en/terms{' '}
                                    </a>
                                    .
                                </p>
                            </li>
                            <li>
                                <p>
                                    Should individual provisions of these GTC be or
                                    become invalid, the validity of the remaining
                                    provisions shall remain unaffected.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify retains all copyright and other rights
                                    in the Services. You may not remove, obscure, or
                                    alter any legal notices (e.g., copyright or
                                    trademark notices). You may not use acceptify's
                                    trademark for your own purposes.
                                </p>
                            </li>
                            <li>
                                <p>
                                    If you violate the terms of this Agreement,
                                    acceptify may terminate your account or access to
                                    the Services.
                                </p>
                            </li>
                            <li>
                                <p>
                                    These TOS constitute the entire agreement between
                                    you and acceptify and supersede all prior
                                    agreements under this heading. There are no oral
                                    side agreements.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You agree to be notified by acceptify by email of
                                    any changes to these TOS and of any new features
                                    or changes to the Services.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Austrian law applies in any case, regardless of
                                    your location. The place of jurisdiction is Graz,
                                    Austria.
                                </p>
                            </li>
                        </ol>
                        <h3>2. Performance of services by acceptify</h3>
                        <ol>
                            <li>
                                <p>
                                    acceptify does not warrant that (i) the Services
                                    will meet your requirements or expectations, (ii)
                                    the Services will be uninterrupted, timely,
                                    secure or error-free, (iii) the results obtained
                                    from the use of the Services will be accurate and
                                    reliable, (iv) any errors in the Services will be
                                    corrected.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify shall not be liable for any direct or
                                    indirect damages of any kind resulting from: (i)
                                    the use of or inability to use the Services; (ii)
                                    modifications to the Services; (iii) permanent
                                    and temporary unavailability of the Services;
                                    (iv) unavailability or improper availability of
                                    data processed by the Services; (v) or other
                                    causes related to the Services.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify provides technical support on a \"best
                                    effort\" basis and via email only. acceptify does
                                    not guarantee the availability of technical
                                    support.
                                </p>
                            </li>
                            <li>
                                <p>
                                    For support and in particular the introduction of
                                    our Services into your organization, acceptify
                                    offers optional training and consulting packages.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify has the right at any time to
                                    discontinue, remove, or modify the Services (or
                                    any part thereof), or to add new Services,
                                    permanently or temporarily. Such changes will
                                    automatically be subject to these Terms. Your
                                    continued use of the Services following such
                                    changes constitutes your tacit acceptance of the
                                    changes.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify is entitled to store the data entered
                                    by the user in the course of using the services
                                    and the data generated thereby and attributable
                                    to the user for further processing. The
                                    responsibility for the permissibility of the
                                    collection and correctness of the data as well as
                                    the maintenance of confidentiality vis-à-vis
                                    involved persons (e.g. participants in surveys)
                                    lies with the user. In addition, the user bears
                                    sole responsibility for stored content that is
                                    subject to licensing (e.g., fonts and images).
                                </p>
                            </li>
                        </ol>
                        <h3>3. Your use of the services</h3>
                        <ol>
                            <li>
                                <p>
                                    You agree to use the Services only in accordance
                                    with (i) these Terms and (ii) the regulations and
                                    legislation relevant to you.
                                </p>
                            </li>
                            <li>
                                <p>
                                    For some services (or parts thereof) acceptify
                                    may require or optionally offer registration for
                                    a user account. You are required to accurately
                                    and truthfully complete and keep current all
                                    information required as part of any registration.
                                    You are also responsible for maintaining the
                                    security of your password. You are not permitted
                                    to create accounts in an automated manner.
                                </p>
                            </li>
                            <li>
                                <p>
                                    For some services, acceptify allows you to
                                    configure a personal profile picture. You are
                                    responsible for ensuring that your profile
                                    picture is not abusive, does not infringe any
                                    copyright or trademark or other rights, and is
                                    not offensive to other acceptify users.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You may not impersonate any other person on
                                    acceptify.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You agree not to intentionally interfere with or
                                    disrupt the Services in any way.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You agree not to reproduce, copy, sell or trade
                                    the Services for any purpose. There is a
                                    distribution partnership option for this purpose.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You agree to acceptify's privacy policy. This can
                                    be viewed at
                                    <a href="#privacy">
                                        {' '}
                                        https://acceptify.at/en/terms#privacy
                                    </a>
                                    .
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify reserves the right (but is not
                                    obligated) to review, filter, modify, reject or
                                    delete any content or accounts within the
                                    Services. You acknowledge that by using
                                    acceptify, you may be exposed to data that is
                                    offensive, indecent or otherwise objectionable.
                                </p>
                            </li>
                        </ol>
                        <h3>4 Advertising</h3>
                        <ol>
                            <li>
                                <p>
                                    Some services are already or will be financed by
                                    advertisements. The display of these
                                    advertisements may be controlled by the
                                    information generated by the use of the Services.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You agree that acceptify may display
                                    advertisements.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The type and amount of advertising can be changed
                                    at any time.
                                </p>
                            </li>
                            <li>
                                <p>
                                    If you use a free service, you must not block the
                                    ads.
                                </p>
                            </li>
                        </ol>
                        <h3>5. Paid services</h3>
                        <p>
                            The use of any of the paid acceptify offered acceptify
                            paid subscriptions are subject to these TOS and the
                            following specific terms:
                        </p>
                        <ol>
                            <li>
                                <p>
                                    Chargeable acceptify will be charged in advance.
                                    Any refund is excluded. Refund is explicitly
                                    excluded also in the following cases: Failure of
                                    the services during the subscription period,
                                    downgrade, partial use of the services, change of
                                    functionality.
                                </p>
                            </li>
                            <li>
                                <p>
                                    All subscriptions are automatically renewed
                                    unless cancelled in writing before the end of the
                                    current subscription period.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Cancellation: You may cancel your subscription at
                                    any time by sending an informal letter to{' '}
                                    <a href="mailto:vertrieb@acceptify.at">
                                        vertrieb@acceptify.at
                                    </a>
                                    . Your subscription will remain active until the
                                    end of the subscription period.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Excessive use and abuse of paid acceptify may
                                    result in temporary or permanent suspension of
                                    access. Acceptify will be the sole judge of when
                                    usage is abusive or excessive..
                                </p>
                            </li>
                            <li>
                                <p>
                                    When you subscribe to paid acceptify, you opt in
                                    to a certain number of approved users. This is
                                    the number of users who can participate in
                                    currently active consensuses of their
                                    organization. A user is identified by their email
                                    address. If a person uses multiple different
                                    email addresses to set up consensuses on
                                    acceptify, each different email address is
                                    considered a separate user. If a user does not
                                    use an email address, they will be counted in
                                    each consensus in which they participate. You are
                                    responsible for upgrading to a higher
                                    subscription level if the maximum number of users
                                    is exceeded.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify reserves the right to block a user
                                    account permanently or temporarily if these terms
                                    are violated. Acceptify shall be the sole judge
                                    of when a violation has occurred. If a user
                                    account is blocked, acceptify will notify the
                                    owner of the user account of the blocking via
                                    e-mail.
                                </p>
                            </li>
                            <li>
                                <p>
                                    A paid acceptify is always associated with a user
                                    account. acceptify always communicates via the
                                    e-mail address stored in the relevant user
                                    account. You are responsible for keeping the
                                    e-mail address up to date.
                                </p>
                            </li>
                            <li>
                                <p>
                                    acceptify reserves the right to change the prices
                                    of all services at any time. However, price
                                    changes will not affect current subscriptions or
                                    contracts but will only take effect at the next
                                    contract or subscription renewal.
                                </p>
                            </li>
                        </ol>
                        <h2 id="privacy">
                            Data protection and personal responsibility
                        </h2>
                        <p>
                            <strong>Privacy policy</strong>
                        </p>
                        <p>
                            Except for your e-mail address, we do not collect or
                            store any personal data.
                        </p>
                        <p>
                            The legality of this storage and processing is given
                            according to DSGVO Art. 6 (1): As you can see from the
                            reasons for the storage from the enumeration below, it is
                            exclusively used to ensure the proper quality of the
                            services expected from them (consensuses in which you
                            participate or sending of the newsletter).
                        </p>
                        <p>The e-mail address is stored,</p>
                        <ol>
                            <li>
                                in order to at least make it more difficult for the
                                same person to participate in the same consensus. If
                                you do not want to enter your e-mail address for a
                                consensus and this is required for this consensus,
                                you will not be able to participate. Your e-mail
                                address stored for this reason will be deleted 14
                                days after the expiration date of the consensus.
                            </li>
                            <li>
                                if you create a profile as a MeinSK user: to make
                                multiple registrations of the same person at least
                                more difficult. You cannot register as a MeinSK user
                                without providing an e-mail address. Your e-mail
                                address, which was stored for this reason, will be
                                deleted when you delete the profile entry. This also
                                allows you to exercise your right of withdrawal at
                                any time.
                            </li>
                            <li>
                                to be able to send you the newsletter, if you request
                                it. We cannot send you a newsletter if you do not
                                provide an e-mail address. Your e-mail address, which
                                was saved to your user, will be deleted again when
                                you delete the user. This also allows you to exercise
                                your right of withdrawal at any time.
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>
                            {' '}
                            Right of revocation: Your right of revocation is listed
                            separately for each reason in the above list.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            A personal evaluation of your personal data does not take
                            place. Only numerical statistics about the use of the
                            system are created.
                        </p>
                        <p>
                            The address of the data protection officer is{' '}
                            <a href="mailto:markus.kickmaier@acceptify.at">
                                markus.kickmaier@acceptify.at
                            </a>
                            .
                        </p>
                        <p>Your data will be stored on European servers.</p>
                        <p>
                            You have the right to complain to a supervisory authority
                            if you believe that we are misusing your personal data.
                        </p>
                        <p>
                            The input of data in the context of a consensus is
                            expressly on a voluntary basis. We assure you that we
                            will treat this data confidentially and will not pass it
                            on to third parties.
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>Personal responsibility</strong>
                        </p>
                        <p>
                            The authors alone are responsible for the entries made in
                            the context of a consensus. We reserve the right to
                            delete entries that are illegal or immoral.
                        </p>
                        <p>
                            <strong>&nbsp;</strong>
                        </p>
                        <p>
                            <strong>Disclaimer</strong>
                        </p>
                        <p>
                            The system has been carefully tested by us. We make every
                            effort to keep it online at all times and to correct
                            errors as quickly as possible.
                        </p>
                        <p>
                            Nevertheless, we cannot give any guarantees about
                            availability or correctness of the consensus results. In
                            particular, we do not assume any liability for
                            consequences resulting from the use of the system or
                            possible loss of data.
                        </p>
                    </div>
                </>
            );
        case 'it':
            return (
                <>
                    <h2 id="agb">Condizioni generali di utilizzo di acceptify</h2>
                    <h3>1. Generale</h3>
                    <ol>
                        <li>
                            <p>
                                acceptify GmbH (di seguito "acceptify") fornisce
                                software e servizi (di seguito collettivamente
                                "Servizi") sotto *acceptify.at. Accettando le CGC, le
                                CGC attualmente in vigore diventano effettive.
                            </p>
                        </li>
                        <li>
                            <p>
                                Se vengono apportate modifiche alle CGC, vi sarà
                                chiesto di accettare di nuovo. Finché non vi viene
                                richiesto, si applicano le GTC già accettate. La
                                versione più recente delle GTC può essere consultata
                                su <a href="#agb">https://acceptify.at/de/terms</a>.
                            </p>
                        </li>
                        <li>
                            <p>
                                Se singole disposizioni di queste CG sono o diventano
                                non valide, a validità delle restanti disposizioni
                                rimane inalterata.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify conserva tutti i diritti d'autore e altri
                                diritti sui Servizi. Non puoi rimuovere, oscurare o
                                alterare qualsiasi avviso legale (come gli avvisi di
                                copyright o di marchio). Non potete utilizzare il
                                marchio di fabbrica di acceptify per i vostri scopi.
                            </p>
                        </li>
                        <li>
                            <p>
                                Se violate i termini del presente Accordo, acceptify
                                può terminare il vostro account o l'accesso ai
                                Servizi.
                            </p>
                        </li>
                        <li>
                            <p>
                                I presenti TOS costituiscono l'intero accordo tra voi
                                e acceptify e sostituiscono tutti gli accordi
                                precedenti sotto questo titolo. Non ci sono accordi
                                collaterali orali.
                            </p>
                        </li>
                        <li>
                            <p>
                                Accettate di essere informati da acceptify via e-mail
                                di qualsiasi modifica dei presenti TOS e di qualsiasi
                                nuova caratteristica o modifica dei Servizi.
                            </p>
                        </li>
                        <li>
                            <p>
                                La legge austriaca si applica in tutti i casi,
                                indipendentemente dalla vostra posizione. Il luogo di
                                giurisdizione è Graz, Austria.
                            </p>
                        </li>
                    </ol>
                    <h3>2. Prestazione di servizi da parte di acceptify</h3>
                    <ol>
                        <li>
                            <p>
                                acceptify non garantisce che (i) i Servizi
                                soddisferanno le vostre esigenze o aspettative, (ii)
                                i Servizi saranno ininterrotti, puntuali, sicuri o
                                privi di errori, (iii) i risultati ottenuti dall'uso
                                dei Servizi saranno accurati e affidabili, (iv)
                                eventuali errori nei Servizi saranno corretti.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify non è responsabile per danni diretti o
                                indiretti di qualsiasi tipo derivanti da: (i)
                                l'utilizzo o l'impossibilità di utilizzare i Servizi;
                                (ii) le modifiche ai Servizi; (iii) l'indisponibilità
                                permanente e temporanea dei Servizi; (iv)
                                l'indisponibilità o la disponibilità impropria dei
                                dati trattati dai Servizi; (v) o qualsiasi altra
                                causa relativa ai Servizi.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify fornisce supporto tecnico su una base di
                                "Best Effort" e solo via e-mail. acceptify non
                                garantisce la disponibilità del supporto tecnico.
                            </p>
                        </li>
                        <li>
                            <p>
                                Per il supporto e in particolare l'introduzione dei
                                nostri servizi nella vostra organizzazione, acceptify
                                offre pacchetti opzionali di formazione e consulenza
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify ha il diritto in qualsiasi momento di
                                interrompere, rimuovere o modificare i Servizi (o
                                qualsiasi parte di essi), in modo permanente o
                                temporaneo, o di aggiungere nuovi Servizi. Tali
                                modifiche saranno automaticamente soggette a queste
                                Condizioni. L'uso continuato dei Servizi da parte
                                dell'utente dopo tali modifiche costituisce la sua
                                tacita accettazione di tali modifiche.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify ha il diritto di memorizzare i dati immessi
                                dall'utente nell'ambito dell'utilizzo dei servizi e i
                                dati così generati e attribuibili all'utente per
                                l'ulteriore elaborazione. L'utente è responsabile
                                della liceità della raccolta e della correttezza dei
                                dati, nonché del mantenimento della riservatezza nei
                                confronti delle persone coinvolte (ad esempio i
                                partecipanti ai sondaggi). Inoltre, l'utente è
                                l'unico responsabile dei contenuti memorizzati che
                                sono soggetti a licenza (ad esempio, font e
                                immagini).
                            </p>
                        </li>
                    </ol>
                    <h3>3. Il suo uso dei servizi</h3>
                    <ol>
                        <li>
                            <p>
                                Lei accetta di utilizzare i Servizi solo in
                                conformità con (i) le presenti Condizioni e (ii) i
                                regolamenti e la legislazione che la riguardano.
                            </p>
                        </li>
                        <li>
                            <p>
                                Per alcuni servizi (o parti di essi), acceptify può
                                richiedere o rendere facoltativa la registrazione per
                                un conto utente. L'utente è responsabile di
                                completare in modo accurato e veritiero e di
                                mantenere aggiornate tutte le informazioni richieste
                                come parte di qualsiasi registrazione. Lei è anche
                                responsabile del mantenimento della sicurezza della
                                sua password. Non ti è permesso creare account in
                                modo automatico.
                            </p>
                        </li>
                        <li>
                            <p>
                                Per alcuni servizi, acceptify vi permette di
                                configurare un'immagine di profilo personale. Siete
                                responsabili di assicurare che la vostra immagine del
                                profilo non sia abusiva, non infranga alcun copyright
                                o marchio o altri diritti, e non sia offensiva per
                                altri utenti di acceptify.
                            </p>
                        </li>
                        <li>
                            <p>Non potete impersonare altre persone su acceptify.</p>
                        </li>
                        <li>
                            <p>
                                Lei accetta di non interferire intenzionalmente o
                                interrompere i Servizi in qualsiasi modo.
                            </p>
                        </li>
                        <li>
                            <p>
                                Lei accetta di non riprodurre, copiare, vendere o
                                commerciare i Servizi per qualsiasi scopo. Esiste
                                un'opzione di partnership di distribuzione per questo
                                scopo.
                            </p>
                        </li>
                        <li>
                            <p>
                                Accettate la politica di privacy di acceptify. Questo
                                può essere visto su
                                <a href="#privacy">
                                    https://acceptify.at/it/terms#privacy
                                </a>
                                .
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify si riserva il diritto (ma non è obbligato)
                                di rivedere, filtrare, modificare, rifiutare o
                                cancellare qualsiasi contenuto o account all'interno
                                dei Servizi. Voi riconoscete che utilizzando
                                acceptify, potreste essere esposti a dati che sono
                                offensivi, discutibili o altrimenti discutibili.
                            </p>
                        </li>
                    </ol>
                    <h3>4. Pubblicità</h3>
                    <ol>
                        <li>
                            <p>
                                Alcuni servizi sono o saranno finanziati dalla
                                pubblicità. La visualizzazione di queste pubblicità
                                può essere controllata dalle informazioni generate
                                dal suo utilizzo dei Servizi.
                            </p>
                        </li>
                        <li>
                            <p>
                                Accettate che acceptify possa visualizzare annunci
                                pubblicitari.
                            </p>
                        </li>
                        <li>
                            <p>
                                Il tipo e la quantità di pubblicità possono essere
                                cambiati in qualsiasi momento.
                            </p>
                        </li>
                        <li>
                            <p>
                                Se state usando un servizio gratuito, non potete
                                bloccare la pubblicità.
                            </p>
                        </li>
                    </ol>
                    <h3>5. Servizi a pagamento</h3>
                    <p>
                        L'uso di uno qualsiasi dei servizi a pagamento acceptify
                        offerti da acceptify è soggetto alle presenti TOS e ai
                        seguenti termini specifici:
                    </p>
                    <ol>
                        <li>
                            <p>
                                L'accettazione pagata viene fatturata in anticipo.
                                Qualsiasi rimborso è escluso. I rimborsi sono anche
                                esplicitamente esclusi nei seguenti casi: Mancanza di
                                servizi durante il periodo di sottoscrizione,
                                downgrade, uso parziale dei servizi, cambiamento del
                                set di funzionalità.
                            </p>
                        </li>
                        <li>
                            <p>
                                Tutti gli abbonamenti si rinnovano automaticamente a
                                meno che non vengano annullati per iscritto prima
                                della fine del periodo di abbonamento in corso.
                            </p>
                        </li>
                        <li>
                            <p>
                                Politica di cancellazione: Puoi cancellare il tuo
                                abbonamento in qualsiasi momento inviando una lettera
                                informale a{' '}
                                <a href="mailto:vertrieb@acceptify.at">
                                    vertrieb@acceptify.at
                                </a>
                                Il tuo abbonamento rimarrà attivo fino alla fine del
                                periodo di abbonamento.
                            </p>
                        </li>
                        <li>
                            <p>
                                L'uso eccessivo e l'abuso dell'accezione a pagamento
                                può comportare la sospensione temporanea o permanente
                                dell'accesso. Acceptify sarà il solo a giudicare
                                quando l'uso è abusivo o eccessivo.
                            </p>
                        </li>
                        <li>
                            <p>
                                Quando ti abboni a pay acceptify, decidi un certo
                                numero di utenti autorizzati. Questo è il numero di
                                utenti che possono partecipare ai consensi
                                attualmente attivi della tua organizzazione. Un
                                utente è identificato dal suo indirizzo e-mail. Se
                                una persona usa più indirizzi email diversi per
                                impostare i consensi su acceptify, ogni indirizzo
                                email diverso è considerato un utente separato. Se un
                                utente non usa un indirizzo e-mail, sarà contato in
                                ogni consenso a cui partecipa. Sei responsabile
                                dell'aggiornamento a un livello di abbonamento
                                superiore se il numero massimo di utenti viene
                                superato.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify si riserva il diritto di sospendere
                                permanentemente o temporaneamente un conto utente se
                                questi termini sono violati. Acceptify sarà l'unico
                                giudice di quando si è verificata una violazione. Se
                                un conto utente viene bloccato, acceptify notificherà
                                il blocco al proprietario del conto utente via
                                e-mail.
                            </p>
                        </li>
                        <li>
                            <p>
                                Un acceptify a pagamento è sempre associato a un
                                conto utente. acceptify comunica sempre tramite
                                l'indirizzo e-mail memorizzato nel relativo conto
                                utente. Lei è responsabile dell'aggiornamento
                                dell'indirizzo e-mail.
                            </p>
                        </li>
                        <li>
                            <p>
                                acceptify si riserva il diritto di modificare i
                                prezzi di tutti i servizi in qualsiasi momento.
                                Tuttavia, le modifiche dei prezzi non influenzeranno
                                gli abbonamenti o i contratti in corso, ma avranno
                                effetto solo al momento del prossimo rinnovo del
                                contratto o dell'abbonamento.
                            </p>
                        </li>
                    </ol>
                    <br />
                    <h2 id="privacy">
                        Protezione dei dati e responsabilità personale
                    </h2>
                    <p>
                        <strong>Politica sulla privacy</strong>
                    </p>
                    <p>
                        A parte il tuo indirizzo e-mail, non raccogliamo né
                        conserviamo alcun dato personale.
                    </p>
                    <p>
                        La legittimità di questa memorizzazione ed elaborazione è
                        data secondo l'art. 6 (1) del DSGVO: Come si può vedere dai
                        motivi per l'immagazzinamento dall'enumerazione qui sotto,
                        questo serve esclusivamente per assicurare la qualità
                        adeguata dei servizi che ci si aspetta da loro (consensi a
                        cui si partecipa o invio della newsletter).
                    </p>
                    <p>L'indirizzo e-mail è memorizzato,</p>
                    <ol>
                        <li>
                            per rendere almeno più difficile la partecipazione della
                            stessa persona allo stesso consenso. Se non vuoi inserire
                            il tuo indirizzo e-mail per un consenso e questo è
                            richiesto per questo consenso, non potrai partecipare. Il
                            tuo indirizzo e-mail memorizzato per questo motivo sarà
                            cancellato 14 giorni dopo la data di scadenza del
                            consenso.
                        </li>
                        <li>
                            se si crea un profilo come utente MeinSK: per rendere
                            almeno più difficili le registrazioni multiple della
                            stessa persona. Non puoi registrarti come utente di
                            MeinSK senza fornire un indirizzo e-mail. Il tuo
                            indirizzo e-mail, che è stato memorizzato per questo
                            motivo, sarà cancellato quando cancellerai la voce del
                            profilo. Questo le permette anche di esercitare il suo
                            diritto di recesso in qualsiasi momento.
                        </li>
                        <li>
                            per poterle inviare la newsletter se la richiede. Non
                            possiamo inviarti una newsletter senza il tuo indirizzo
                            e-mail. Il tuo indirizzo e-mail, che è stato salvato nel
                            tuo utente, sarà cancellato di nuovo quando cancelli
                            l'utente. Questo le permette anche di esercitare il suo
                            diritto di recesso in qualsiasi momento.
                        </li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>
                        Diritto di revoca: Il suo diritto di revoca è elencato
                        separatamente per ogni motivo nella lista di cui sopra.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        Una valutazione personale dei suoi dati personali non ha
                        luogo. Vengono create solo statistiche numeriche sull'uso del
                        sistema.Una valutazione personale dei suoi dati personali non
                        ha luogo. Vengono create solo statistiche numeriche sull'uso
                        del sistema.
                    </p>
                    <p>
                        L'indirizzo del responsabile della protezione dei dati è{' '}
                        <a href="mailto:markus.kickmaier@acceptify.at">
                            markus.kickmaier@acceptify.at
                        </a>
                        .
                    </p>
                    <p>I suoi dati saranno conservati su server europei.</p>
                    <p>
                        Ha il diritto di presentare un reclamo a un'autorità di
                        vigilanza se ritiene che stiamo abusando dei suoi dati
                        personali.
                    </p>
                    <p>
                        L'inserimento dei dati nel contesto di un consenso è
                        espressamente su base volontaria. Vi assicuriamo che
                        tratteremo questi dati in modo confidenziale e non li
                        trasmetteremo a terzi.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Responsabilità personale</strong>
                    </p>
                    <p>
                        Gli autori sono gli unici responsabili delle loro voci nel
                        contesto di un consenso. Ci riserviamo il diritto di
                        cancellare le voci che sono illegali o immorali.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Disclaimer</strong>
                    </p>
                    <p>
                        Il sistema è stato accuratamente testato da noi. Facciamo
                        ogni sforzo per tenerlo sempre online e per correggere gli
                        errori il più rapidamente possibile.
                    </p>
                    <p>
                        Tuttavia, non possiamo dare alcuna garanzia sulla
                        disponibilità o sulla correttezza dei risultati del consenso.
                        In particolare, non ci assumiamo alcuna responsabilità per le
                        conseguenze derivanti dall'uso del sistema o da qualsiasi
                        perdita di dati.
                    </p>
                </>
            );
        case 'es':
            <></>;
        default:
            return <></>;
    }
}

const TermsAndConditions: FC<termsAndConditionsProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['mysk']);

    return (
        <div className="m-14 p-5 bg-slate-300 rounded">
            {termsAndConditionsMultilanguage(i18n.language)}
        </div>
    );
};

export default TermsAndConditions;
