import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const BrowserHandling = (): ReactElement => {
    const navigate = useNavigate();
    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //logic for showing popup warning on page refresh
        window.onbeforeunload = function () {
            return 'Data will be lost if you leave the page, are you sure?';
        };
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    const onBackButtonEvent = (e: PopStateEvent) => {
        e.preventDefault();
        navigate(-1);
    };

    return <></>;
};

export default BrowserHandling;
