import React, { FC, useEffect, useState } from 'react';
import { NoteMain } from '../../note/note_main';
import { ElementTypeEnum } from '../../../utils/app_helpers';
import { resetPageSetAndGetLinkInfo } from '../../components/LinkRouting';
import axios from 'axios';
import { string } from 'zod';
import {
    createAppspace,
    createFluidContainer,
    createSettings,
    FluidWorkspace,
} from '../../../utils/fluidContainerMangagemnt';
import { ContainerSchema, IFluidContainer, Tree, TreeView } from 'fluid-framework';
import { Apps, Group, Settings } from '../../../schema/app_schema';
import { createUndoRedoStacks } from '../../../utils/undo';
import { AzureContainerServices } from '@fluidframework/azure-client';
import { OdspClient, OdspContainerServices } from '@fluidframework/odsp-client/beta';
import {
    REDUCER_ACTION_TYPE,
    useSubjectContextValue,
} from '../../context/SubjectContext';
import { useParams } from 'react-router-dom';
import { useFastConsensingContextValue } from '../../context/FastConsensingContext';
import FluidTextInput from '../components/FluidTextInput';
import FastConsensingHeader from './FastConsensingHeader';

interface FastConsensingProps {}

const LOAD_SUBJECT_URL = '/subject/confirm_email';
const CREATE_SUBJECT_URL = '/subject/newTheme';

const FastConsensing: FC<FastConsensingProps> = ({}): JSX.Element => {
    const subjectContext = useSubjectContextValue();
    const [publicLink, setPublicLink] = useState<string>();
    const fastConsensingContextValue = useFastConsensingContextValue();
    let { mode } = useParams();
    const [title, setTitle] = useState('');
    //   const [fluidWorkspace, setFluidWorkspace] = useState<FluidWorkspace>();

    //const subjectKey = resetPageSetAndGetLinkInfo();

    // setup the fluid
    useEffect(() => {
        if (mode == 'new') {
            // New subject
            const createSubject = async () => {
                // create fluid container
                const fluid = await createFluidContainer('');

                subjectContext.dispatch({
                    type: REDUCER_ACTION_TYPE.SET_FLUID_WORKSPACE,
                    payload: fluid,
                });

                // create the subject in the database
                const fetchData = async () => {
                    try {
                        const response = await axios.post(
                            CREATE_SUBJECT_URL,
                            JSON.stringify({
                                type: 'fc',
                                containerId: fluid.containerIdReady,
                                userId: '1',
                                title: 'test',
                            }),
                            {
                                headers: { 'Content-Type': 'application/json' },
                            }
                        );
                        if (response?.status == 200) {
                            const publicL = response.data.public_link;
                            setPublicLink(publicL);
                            subjectContext.dispatch({
                                type: REDUCER_ACTION_TYPE.SET_PUBLIC_LINK,
                                payload: publicL,
                            });
                        }

                        setTitle(fastConsensingContextValue.state.title);

                        //         for (let i = 0;i < fastConsensingContextValue.state.numberOfSuggestions ; i++) {
                        //             var chr = String.fromCharCode(65 + i);
                        //             const gr =(subjectContext.state.fluidWS?.apps.root[0]);
                        //    //         (subjectContext.state.fluidWS?.apps.root[1] as Group).items.addNode('1',chr);
                        //           }

                        //         //   (subjectContext.state.fluidWS?.apps.root[0] as Settings).updateTitle( title);
                    } catch (ex) {
                        subjectContext.dispatch({
                            type: REDUCER_ACTION_TYPE.RESET_ALL,
                        });
                        console.log(ex);
                    }
                };
                fetchData();
            };

            createSubject();

            // change events
        } else {
            // Set public link
            setPublicLink(subjectContext.state.publicLink);
            // Set initial title
        }

        // Set the Header

        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_HEADER_CONTROLS,
            payload: FastConsensingHeader,
        });
    }, []);

    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        if (subjectContext.state.fluidWS) {
            const unsubscribe = Tree.on(
                subjectContext.state.fluidWS.apps.root[0] as Settings,
                'nodeChanged',
                () => {
                    setTitle(
                        (subjectContext.state.fluidWS?.apps.root[0] as Settings)
                            .title
                    );
                }
            );
            return unsubscribe;
        }
    }, [subjectContext.state.fluidWS]);

    const fluidWorkspace = subjectContext.state.fluidWS;
    if (fluidWorkspace && fluidWorkspace.apps.root.length == 0) {
        const createSubject = async () => {
            await createSettings(fluidWorkspace);
            // create the workspace in the fluid container
            await createAppspace(fluidWorkspace, 'A');

            for (
                let i = 0;
                i < fastConsensingContextValue.state.numberOfSuggestions;
                i++
            ) {
                var chr = String.fromCharCode(65 + i);
                const gr = subjectContext.state.fluidWS?.apps.root[0];
                (fluidWorkspace.apps.root[1] as Group).items.addNode('1', chr);
            }

            //   (fluidWorkspace.apps.root[0] as Settings).updateTitle( title);
        };

        createSubject();
    }

    let header = FastConsensingHeader;
    return (
        <>
            {fluidWorkspace && fluidWorkspace.apps.root.length > 1 ? (
                <>
                    {/* <p>{publicLink}</p>
        <p><FastConsensingHeader/></p> */}
                    <FluidTextInput
                        settings={fluidWorkspace.apps.root[0] as Settings}
                    />

                    <NoteMain
                        isWithGroups={true}
                        elementType={ElementTypeEnum.ResistanceMeas}
                    />
                </>
            ) : (
                <>Loading...</>
            )}
        </>
    );
};

export default FastConsensing;
