import exp from 'constants';
import {
    ChangeEvent,
    Dispatch,
    ReactElement,
    createContext,
    useCallback,
    useContext,
    useReducer,
    useState,
} from 'react';

export type GlobalContentType = {
    user: string;
    pwd: string;
    roles: number[];
    accessToken: string;
    persist: string;
    isLoggedIn: boolean;
    //  setAccessToken: React.Dispatch<React.SetStateAction<string>>
    //  setUser: React.Dispatch<React.SetStateAction<string>>
};

const initState: GlobalContentType = {
    user: '',
    accessToken: '',
    pwd: '',
    roles: [],
    persist: localStorage.getItem('persist') || '""',
    isLoggedIn: false,
};

export const enum REDUCER_ACTION_TYPE {
    SET_USER,
    SET_PASSWORD,
    SET_ROLES,
    SET_ACCESS_TOKEN,
    SET_PERSIST,
    RESET_ALL,
}

type ReducerAction = {
    type: REDUCER_ACTION_TYPE;
    payload?: any;
};

const reducer = (
    state: GlobalContentType,
    action: ReducerAction
): GlobalContentType => {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.SET_USER:
            var newState = { ...state, isLoggedIn: action.payload ? true : false };
            return { ...newState, user: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_PASSWORD:
            return { ...state, pwd: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_ROLES:
            return { ...state, roles: action.payload ?? [] };
        case REDUCER_ACTION_TYPE.SET_ACCESS_TOKEN:
            return { ...state, accessToken: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_PERSIST:
            localStorage.setItem('persist', action.payload ?? '');
            return { ...state, persist: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.RESET_ALL:
            return initState;
        default:
            throw new Error('AuthProviderContext reducer action.type not found');
    }
};
type ContextValue = {
    state: GlobalContentType;
    dispatch: Dispatch<ReducerAction>;
};

const AuthProviderContext = createContext<ContextValue>({
    state: initState,
    dispatch: () => {},
});

export const AuthProvider = ({
    children,
}: {
    children: JSX.Element;
}): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <AuthProviderContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthProviderContext.Provider>
    );
};

export const useAuthContextValue = (): ContextValue => {
    return useContext(AuthProviderContext);
};
