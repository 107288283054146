import React, { FC, useState } from 'react';
import {
    ExploreResistanceImage,
    FirstIdeasImage,
    PassiveOptionImage,
    ProposalsImage,
    ResistanceMeasurementImage,
    ShineThroughImage,
} from '../../../react/button_sc_ux';
import { Button } from '../../../../components/ui/button';
import { cva } from 'class-variance-authority';
import { Tree } from 'fluid-framework';
import { Items, Note } from '../../../schema/app_schema';
import { Session } from '../../../schema/session_schema';
import { ElementTypeEnum, findNote, moveItem } from '../../../utils/app_helpers';
import { getSelectedNotes } from '../../../utils/session_helpers';
import { IconButton } from '../../../react/note/buttonux';
import { NotebookAddRegular, NoteRegular } from '@fluentui/react-icons';

interface NextProcessStepSelectorProps {
    items: Items;
    session: Session;
    clientId: string;
}

const buttonStyle = cva(
    'h-20  px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50'
);

const NextProcessStepSelector: FC<NextProcessStepSelectorProps> = ({
    items,
    session,
    clientId,
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    let elementType: ElementTypeEnum = ElementTypeEnum.Note;
    const setProcessStep = (type: ElementTypeEnum) => {
        Tree.runTransaction(items, () => {
            const group = items.addElement('', type);
            const ids = getSelectedNotes(session, clientId);
            for (const id of ids) {
                const n = findNote(items, id);
                if (Tree.is(n, Note)) {
                    moveItem(n, Infinity, group.items);
                }
            }
        });
        setIsOpen(false);
    };
    return (
        <div>
            {/* <button type='button' onClick={() => setIsOpen(!isOpen)}>Neuer Prozessschritt</button> */}
            <IconButton
                color="white"
                background="black"
                handleClick={(e: React.MouseEvent) => setIsOpen(!isOpen)}
                icon={<NotebookAddRegular />}
            >
                Prozesschritt Hinufügen
            </IconButton>
            {isOpen && (
                <div className="absolute -top-64 bg-white border-gray-600">
                    <div className="flex flex-wrap w-72 h-auto">
                        <button
                            type="button"
                            onClick={() => setProcessStep(ElementTypeEnum.Note)}
                            className={buttonStyle()}
                        >
                            <FirstIdeasImage />
                        </button>
                        <button
                            type="button"
                            onClick={() => setProcessStep(ElementTypeEnum.Propsals)}
                            className={buttonStyle()}
                        >
                            <ProposalsImage />
                        </button>
                        <button
                            type="button"
                            onClick={() => setProcessStep(ElementTypeEnum.Passive)}
                            className={buttonStyle()}
                        >
                            <PassiveOptionImage />
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setProcessStep(ElementTypeEnum.ShineThrough)
                            }
                            className={buttonStyle()}
                        >
                            <ShineThroughImage />
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setProcessStep(ElementTypeEnum.ResistanceMeas)
                            }
                            className={buttonStyle()}
                        >
                            <ResistanceMeasurementImage />
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setProcessStep(ElementTypeEnum.ExploreResistance)
                            }
                            className={buttonStyle()}
                        >
                            <ExploreResistanceImage />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NextProcessStepSelector;
