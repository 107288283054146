import React, { FC } from 'react';
import { useAuthContextValue } from '../context/AuthProviderContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/ui/button';

interface MainSelectionProps {
    title: string;
    description?: string[];
    buttonText: string;
    linkToSelected: string;
    children?: React.ReactNode;
}

const MainSelection: FC<MainSelectionProps> = ({
    title,
    description,
    buttonText,
    linkToSelected,
    children,
}): JSX.Element => {
    return (
        <div className="lg:w-1/3 pr-4 pl-4 pricing-item green">
            <div className="pricing-divider ">
                <h4 className="my-0 text-2xl text-gray-100 font-normal mb-3">
                    <span className="h5">{title}</span>
                </h4>
                <svg
                    className="pricing-divider-img"
                    enableBackground="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xmlns="http://www.w3.org/2000/svg"
                    y="0px"
                >
                    <path
                        className="deco-layer deco-layer--1"
                        d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                        fill="#FFFFFF"
                        opacity="0.6"
                    ></path>
                    <path
                        className="deco-layer deco-layer--2"
                        d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                        fill="#FFFFFF"
                        opacity="0.6"
                    ></path>
                    <path
                        className="deco-layer deco-layer--3"
                        d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
H42.401L43.415,98.342z"
                        fill="#FFFFFF"
                        opacity="0.7"
                    ></path>
                    <path
                        className="deco-layer deco-layer--4"
                        d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                        fill="#FFFFFF"
                    ></path>
                </svg>
            </div>

            <div className="flex-auto p-6 bg-white mt-0 shadow">
                <ul className="list-unstyled mb-5 relative">
                    {description?.map(function (data) {
                        return <li>{data}</li>;
                    })}
                </ul>
                {children}
                <Link to={linkToSelected}>
                    <Button>{buttonText}</Button>
                </Link>
            </div>
        </div>
    );
};

export default MainSelection;
