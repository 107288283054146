import React, { FC } from 'react';
import { Input } from '../../../../components/ui/input';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../../components/ui/form';
import { Button } from '../../../../components/ui/button';
import { Textarea } from '../../../../components/ui/textarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { X, ChevronRight } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    REDUCER_ACTION_TYPE,
    useNewSubjectContextValue,
} from '../../context/NewSubjectContext';
import axios from 'axios';
import { createFluidContainer } from '../../../utils/fluidContainerMangagemnt';
import { setSubjectContext } from '../../../react/static_pages/SubjectLoader';
import { useSubjectContextValue } from '../../../react/context/SubjectContext';

interface LifeMeetingSetupProps {}
const CREATE_SUBJECT_URL = '/subject/newTheme';

const LifeMeetingSetup: FC<LifeMeetingSetupProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['create_subject', 'buttons']);
    const navigate = useNavigate();
    let { setup_type } = useParams();
    const newSubjectContext = useNewSubjectContextValue();
    const subjectContext = useSubjectContextValue();
    async function onSubmit(values: FormSchema) {
        if (setup_type) {
            newSubjectContext.dispatch({
                type: REDUCER_ACTION_TYPE.SET_TYPE,
                payload: setup_type,
            });
        }
        newSubjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_TITLE,
            payload: values.title,
        });
        newSubjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_DESCRIPTION,
            payload: values.description,
        });
        if (setup_type == 'combined' || setup_type == 'dynamic') {
            const fluid = await createFluidContainer('');

            const response = await axios.post(
                CREATE_SUBJECT_URL,
                JSON.stringify({
                    type: setup_type,
                    containerId: fluid.containerIdReady,
                    title: newSubjectContext.state.title,
                    userId: 1,
                }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false,
                }
            );
            console.log(response?.data);
            const data = response?.data;

            console.log(JSON.stringify(response));

            setSubjectContext(data, fluid, subjectContext);
            // write the subject to the context
            switch (setup_type) {
                case 'combined':
                    navigate('/combined/first_ideas', { replace: true });
                    break;
                case 'dynamic':
                    navigate('/dynamic', { replace: true });
                    break;
            }
        } else {
            navigate('/setup/how_to_admister', { replace: true });
        }
    }

    type FormSchema = z.infer<typeof formSchema>;

    function onCancel() {
        navigate('/', { replace: true });
    }

    const formSchema = z.object({
        title: z.string().min(2, {
            message: 'Username must be at least 2 characters.',
        }),
        description: z.string(),
    });

    const form = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: newSubjectContext.state.title,
            description: newSubjectContext.state.description,
        },
    });
    return (
        <div className="form-frame">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    {t('subject.title.short_info')}
                                </FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t('description')}</FormLabel>
                                <FormControl>
                                    <Textarea className="resize-none" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="mt-2 float-right">
                        <Button type="submit" className="mr-2" onClick={onCancel}>
                            <X />
                        </Button>
                        <Button type="submit">
                            <ChevronRight />
                        </Button>
                    </div>
                    <div className="clear-both" />

                    {/* <Button type="submit">{t('button.ok', { ns: 'buttons' })}</Button> */}
                </form>
            </Form>
        </div>
    );
};

export default LifeMeetingSetup;
