import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
    ContextValue,
    REDUCER_ACTION_TYPE,
    useSubjectContextValue,
} from '../context/SubjectContext';
import {
    createFluidContainer,
    FluidWorkspace,
} from '../../utils/fluidContainerMangagemnt';

interface SubjectLoaderProps {}
const LOAD_SUBJECT_URL = '/subject/get_subject';

const SubjectLoader: FC<SubjectLoaderProps> = ({}): JSX.Element => {
    const navigate = useNavigate();
    const subjectContext = useSubjectContextValue();
    let { subject_key } = useParams();

    useEffect(() => {
        // get the subject
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    LOAD_SUBJECT_URL,
                    JSON.stringify({ public_link: subject_key }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );
                if (response?.status == 200) {
                    const descriptorJson = response.data.descriptor;
                    const descriptor = JSON.parse(descriptorJson);

                    const type = descriptor.type;

                    // load the context
                    const fluid = await createFluidContainer(descriptor.containerId);
                    setSubjectContext(response.data, fluid, subjectContext);

                    // go to the page for using that context
                    switch (type) {
                        case 'fc':
                            navigate('/fast_consensing/loaded');
                            break;
                    }
                }
            } catch (ex) {
                navigate('/');
            }
        };

        // call the function
        fetchData();
    }, []);

    return <div>Loading...</div>;
};

export function setSubjectContext(
    data: any,
    fluid: FluidWorkspace,
    subjectContext: ContextValue
) {
    subjectContext.dispatch({
        type: REDUCER_ACTION_TYPE.SET_CONTAINER_ID,
        payload: data.container_id,
    });
    subjectContext.dispatch({
        type: REDUCER_ACTION_TYPE.SET_PUBLIC_LINK,
        payload: data.public_link,
    });
    subjectContext.dispatch({
        type: REDUCER_ACTION_TYPE.SET_TYPE,
        payload: data.type,
    });
    subjectContext.dispatch({
        type: REDUCER_ACTION_TYPE.SET_FLUID_WORKSPACE,
        payload: fluid,
    });
}

export default SubjectLoader;
