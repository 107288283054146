import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Note, Group, Items } from '../../schema/app_schema';
import {
    deleteNote,
    ElementTypeEnum,
    moveItem,
    updateNoteTextProposal,
} from '../../utils/app_helpers';
import { dragType, getRotation, selectAction } from '../../utils/utils';
import {
    testRemoteNoteSelection,
    updateRemoteNoteSelection,
} from '../../utils/session_helpers';
import { ConnectableElement, useDrag, useDrop } from 'react-dnd';
import { useTransition } from 'react-transition-state';
import { Tree } from 'fluid-framework';
import { IconButton, MiniThumb, DeleteButton } from './buttonux';
import { Session } from '../../schema/session_schema';
import { Checkbox } from '../../../components/ui/checkbox';
import TextareaAutosize from 'react-textarea-autosize';
import { RateAndShow } from '../sc_view/rate_and_show';

// export function NoteContainer(props: {
//     group: Group;
//     clientId: string;
//     session: Session;
//     fluidMembers: string[];
//     elementType: ElementTypeEnum;
// }): JSX.Element {
//     const notesArray = [];
//     for (const n of props.group.notes) {
//         notesArray.push(
//             <NoteView
//                 key={n.id}
//                 note={n}
//                 clientId={props.clientId}
//                 notes={props.group.notes}
//                 session={props.session}
//                 fluidMembers={props.fluidMembers}
//                 elementType={props.elementType}
//             />
//         );
//     }

//     notesArray.push(
//         <AddNoteButton
//             key="newNote"
//             group={props.group}
//             clientId={props.clientId}
//             elementType={props.elementType}
//         />
//     );

//     return <div className="flex flex-row flex-wrap gap-4">{notesArray}</div>;
// }

export function RootNoteWrapper(props: {
    note: Note;
    clientId: string;
    session: Session;
    fluidMembers: string[];
    elementType: ElementTypeEnum;
}): JSX.Element {
    const renderSwitch = (param: ElementTypeEnum) => {
        switch (param) {
            case ElementTypeEnum.Note:
                return (
                    <div className="bg-transparent flex flex-col justify-center h-64">
                        <NoteView {...props} />
                    </div>
                );
            case ElementTypeEnum.CombineProp:
            case ElementTypeEnum.ShineThrough:
            case ElementTypeEnum.ResistanceMeas:
                return (
                    <div className="w-full">
                        <NoteView {...props} />
                    </div>
                );
            case ElementTypeEnum.Dynamic:
                return (
                    <div className="w-full">
                        <NoteView {...props} />
                    </div>
                );
        }
    };
    return <>{renderSwitch(props.elementType)}</>;
}

export function NoteView(props: {
    note: Note;
    clientId: string;
    session: Session;
    fluidMembers: string[];
    elementType: ElementTypeEnum;
}): JSX.Element {
    const mounted = useRef(false);

    const [{ status }, toggle] = useTransition({
        timeout: 1000,
    });

    const [selected, setSelected] = useState(false);
    const [remoteSelected, setRemoteSelected] = useState(false);
    const [bgColor, setBgColor] = useState('bg-yellow-100');
    const [rotation] = useState(getRotation(props.note));
    const [invalSelection, setInvalSelection] = useState(0);

    const [noteVoteCount, setNoteVoteCount] = useState(props.note.votes.length);

    const [calculatedHights, setCalculatedHights] = useState([0, 0, 0]);

    const parent = Tree.parent(props.note);
    if (parent === undefined || !Tree.is(parent, Items)) {
        return <></>;
    }

    const testSelection = (
        note: Note,
        session: Session,
        clientId: string,
        fluidMembers: string[]
    ) => {
        const result = testRemoteNoteSelection(
            note,
            session,
            clientId,
            fluidMembers
        );
        setSelected(result.selected);
        setRemoteSelected(result.remoteSelected);
    };

    const updateSelection = (action: selectAction) => {
        updateRemoteNoteSelection(props.note, action, props.session, props.clientId);
    };

    // Register for tree deltas when the component mounts.
    // Any time the selection changes, the app will update
    // We are using the treeChanged event because we are listening for all
    // changes in the session tree.
    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        const unsubscribe = Tree.on(props.session, 'treeChanged', () => {
            setInvalSelection(invalSelection + Math.random());
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        testSelection(props.note, props.session, props.clientId, props.fluidMembers);
    }, [invalSelection]);

    // Register for tree deltas when the component mounts.
    // Any time the node changes, the app will update
    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        const unsubscribe = Tree.on(props.note, 'nodeChanged', () => {
            setNoteVoteCount(props.note.votes.length);
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        testSelection(props.note, props.session, props.clientId, props.fluidMembers);
    }, [props.fluidMembers]);

    useEffect(() => {
        mounted.current = true;
        testSelection(props.note, props.session, props.clientId, props.fluidMembers);

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (selected) {
            setBgColor('bg-yellow-400');
        } else {
            setBgColor('bg-yellow-100');
        }
    }, [selected]);

    toggle(false);

    useEffect(() => {
        toggle(true);
    }, [Tree.parent(props.note)]);

    useEffect(() => {
        if (mounted.current) {
            toggle(true);
        }
    }, [props.note.text]);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragType.NOTE,
        item: props.note,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: [dragType.NOTE, dragType.GROUP],
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
        canDrop: (item) => {
            if (Tree.is(item, Note)) return true;
            if (Tree.is(item, Group) && !Tree.contains(item, parent)) return true;
            return false;
        },
        drop: (item) => {
            if (Tree.is(item, Group) || Tree.is(item, Note)) {
                moveItem(item, parent.indexOf(props.note), parent);
            }
            return;
        },
    }));

    const attachRef = (el: ConnectableElement) => {
        drag(el);
        drop(el);
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (selected) {
            updateSelection(selectAction.REMOVE);
        } else if (e.shiftKey || e.ctrlKey) {
            updateSelection(selectAction.MULTI);
        } else {
            updateSelection(selectAction.SINGLE);
        }
    };

    const renderSwitch = (param: ElementTypeEnum) => {
        switch (param) {
            case ElementTypeEnum.Note:
                return (
                    <div
                        onClick={(e) => handleClick(e)}
                        className={`transition duration-500${
                            status === 'exiting'
                                ? ' transform ease-out scale-110'
                                : ''
                        }`}
                    >
                        <div
                            ref={attachRef}
                            className={
                                isOver && canDrop
                                    ? 'border-l-4 border-dashed border-gray-500'
                                    : 'border-l-4 border-dashed border-transparent'
                            }
                        >
                            <div
                                style={{ opacity: isDragging ? 0.5 : 1 }}
                                className={
                                    'relative transition-all flex flex-col ' +
                                    bgColor +
                                    ' h-48 w-48 shadow-md hover:shadow-lg hover:rotate-0 p-2 ' +
                                    rotation +
                                    ' ' +
                                    (isOver && canDrop ? 'translate-x-3' : '')
                                }
                            >
                                <NoteToolbar
                                    voted={
                                        props.note.votes.indexOf(props.clientId) > -1
                                    }
                                    toggleVote={() =>
                                        props.note.toggleVote(props.clientId)
                                    }
                                    voteCount={noteVoteCount}
                                    deleteNote={props.note.delete}
                                />
                                <NoteTextArea
                                    note={props.note}
                                    select={updateSelection}
                                    elementType={param}
                                />
                                <NoteSelection show={remoteSelected} />
                            </div>
                        </div>
                    </div>
                );
            case ElementTypeEnum.CombineProp:
                return (
                    <div
                        onClick={(e) => handleClick(e)}
                        className={`w-full transition duration-500$`}
                    >
                        <div
                            ref={attachRef}
                            className={
                                isOver && canDrop
                                    ? 'border-dashed border-gray-500'
                                    : 'border-dashed border-transparent'
                            }
                        >
                            <div
                                style={{ opacity: isDragging ? 0.5 : 1 }}
                                className={
                                    'flex flex-row ' +
                                    bgColor +
                                    ' h-full w-full shadow-md hover:shadow-lg hover:rotate-0 p-1 ' +
                                    (isOver && canDrop ? 'translate-x-3' : '')
                                }
                            >
                                <CombineProposalsToolBar
                                    note={props.note}
                                    clientId={props.clientId}
                                />
                                <NoteTextArea
                                    note={props.note}
                                    select={updateSelection}
                                    elementType={param}
                                />
                                {/* <DeleteNoteButton {...props} /> */}
                                <NoteSelection show={remoteSelected} />
                            </div>
                        </div>
                    </div>
                );
            case ElementTypeEnum.ShineThrough:
            case ElementTypeEnum.ResistanceMeas:
                return (
                    <div
                        onClick={(e) => handleClick(e)}
                        className={`w-full transition duration-500$`}
                    >
                        <div
                            ref={attachRef}
                            className={
                                isOver && canDrop
                                    ? 'border-4-1 border-dashed border-gray-500'
                                    : 'border-4-1 border-dashed border-transparent'
                            }
                        >
                            <div
                                style={{ opacity: isDragging ? 0.5 : 1 }}
                                className={
                                    'transition-all flex flex-col bg-yellow-100 w-1200px shadow-md hover:shadow-lg p-2 p-bottom-0' +
                                    ' ' +
                                    (isOver && canDrop ? 'translate-y-2' : '')
                                }
                            >
                                <ProposalToolbar2
                                    note={props.note}
                                    clientId={props.clientId}
                                    elementType={props.elementType}
                                />
                                {/* <ProposalTextArea note={props.note} update={update} /> */}

                                <table>
                                    <tr>
                                        <td>
                                            <ProposalTextArea2
                                                note={props.note}
                                                title="Vorschlag"
                                                entryFieldType={EntryField.Proposal}
                                                textAreaNumber={0}
                                                calculatedHeights={calculatedHights}
                                                setCalculatedHights={
                                                    setCalculatedHights
                                                }
                                            />
                                        </td>
                                        <td>
                                            <ProposalTextArea2
                                                note={props.note}
                                                title="Vorteile"
                                                entryFieldType={
                                                    EntryField.Advantages
                                                }
                                                textAreaNumber={1}
                                                calculatedHeights={calculatedHights}
                                                setCalculatedHights={
                                                    setCalculatedHights
                                                }
                                            />
                                        </td>
                                        <td>
                                            <ProposalTextArea2
                                                note={props.note}
                                                title="Nachteile"
                                                entryFieldType={
                                                    EntryField.Disadvantages
                                                }
                                                textAreaNumber={2}
                                                calculatedHeights={calculatedHights}
                                                setCalculatedHights={
                                                    setCalculatedHights
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                                <NoteSelection show={remoteSelected} />
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return <>{renderSwitch(props.elementType)}</>;
}

function NoteSelection(props: { show: boolean }): JSX.Element {
    if (props.show) {
        return (
            <div className="absolute -top-2 -left-2 h-52 w-52 rounded border-dashed border-indigo-800 border-4" />
        );
    } else {
        return <></>;
    }
}

function NoteTextArea(props: {
    note: Note;
    elementType: ElementTypeEnum;
    select: (value: selectAction) => void;
}): JSX.Element {
    // The text field updates the Fluid data model on every keystroke in this demo.
    // This works well with small strings but doesn't scale to very large strings.
    // A Future iteration of SharedTree will include support for collaborative strings
    // that make real-time collaboration on this type of data efficient and simple.

    const [noteText, setNoteText] = useState<string>(props.note['text']);

    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        const unsubscribe = Tree.on(props.note, 'nodeChanged', () => {
            setNoteText(props.note.text);
        });
        return unsubscribe;
    }, []);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (e.ctrlKey) {
            props.select(selectAction.MULTI);
        } else {
            props.select(selectAction.SINGLE);
        }
    };
    const renderSwitch = (param: ElementTypeEnum) => {
        switch (param) {
            case ElementTypeEnum.Note:
                return (
                    <textarea
                        className="p-2 bg-transparent h-full w-full resize-none z-50"
                        value={noteText}
                        onClick={(e) => handleClick(e)}
                        onChange={(e) => props.note.updateText(e.target.value)}
                    />
                );
            case ElementTypeEnum.CombineProp:
                return (
                    <textarea
                        className="bg-transparent h-6 w-full resize-none z-50"
                        value={noteText}
                        onClick={(e) => handleClick(e)}
                        onChange={(e) => props.note.updateText(e.target.value)}
                    />
                );
        }
    };

    return <>{renderSwitch(props.elementType)}</>;
}

export enum EntryField {
    Proposal,
    Advantages,
    Disadvantages,
}

function getTextValueFromType(note: Note, entryFieldType: EntryField) {
    switch (entryFieldType) {
        case EntryField.Proposal: {
            return note.text;
        }
        case EntryField.Advantages: {
            return note.advantages;
        }
        case EntryField.Disadvantages: {
            return note.disadvantages;
        }
    }
}

function ProposalTextArea2(props: {
    note: Note;

    title: string;
    entryFieldType: EntryField;
    textAreaNumber: number;
    setCalculatedHights: Dispatch<SetStateAction<number[]>>;
    calculatedHeights: number[];
}): JSX.Element {
    const [noteText, setNoteText] = useState<string>(() => {
        return getTextValueFromType(props.note, props.entryFieldType);
    });

    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        const unsubscribe = Tree.on(props.note, 'nodeChanged', () => {
            setNoteText(getTextValueFromType(props.note, props.entryFieldType));
        });
        return unsubscribe;
    }, []);

    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const divREf = useRef<HTMLDivElement | null>(null);

    const maxValue: number = Math.max(...props.calculatedHeights);

    textAreaRef.current?.style.setProperty(
        'height',
        maxValue?.toString() + `px`,
        'important'
    );

    return (
        <div>
            <div className="small-text small-margin">{props.title}</div>
            <div ref={divREf}>
                <TextareaAutosize
                    minRows={1}
                    maxRows={5}
                    className="bg-transparent w-full  resize-none medium-text h-full p-1"
                    value={noteText}
                    onChange={(event) => {
                        updateNoteTextProposal(
                            props.note,
                            event.target.value,
                            props.entryFieldType
                        );
                    }}
                    onHeightChange={(calculatedHeight) => {
                        let heightsArray = [];
                        props.setCalculatedHights((prevState) => {
                            heightsArray = prevState;
                            heightsArray[props.textAreaNumber] = calculatedHeight;
                            return heightsArray;
                        });
                    }}
                    ref={textAreaRef}
                />
            </div>
        </div>
    );
}

function NoteToolbar(props: {
    voted: boolean;
    toggleVote: () => void;
    voteCount: number;
    deleteNote: () => void;
}): JSX.Element {
    return (
        <div className="flex justify-between z-50">
            <LikeButton {...props} />
            <DeleteNoteButton {...props} />
        </div>
    );
}

function ProposalToolbar2(props: {
    note: Note;
    clientId: string;
    elementType: ElementTypeEnum;
}): JSX.Element {
    return (
        <div className="flex justify-between">
            {/* <LikeButton note={props.note} user={props.user} /> */}
            {props.elementType == ElementTypeEnum.ResistanceMeas ? (
                <RateAndShow user={props.clientId} note={props.note} />
            ) : (
                <></>
            )}
            <SuggestionTitle note={props.note} />
            <DeleteProposalButton {...props} />
        </div>
    );
}

function SuggestionTitle(props: { note: Note }): JSX.Element {
    const [text, setText] = useState<string>(props.note.title);

    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        const unsubscribe = Tree.on(props.note, 'nodeChanged', () => {
            setText(props.note.title);
        });
        return unsubscribe;
    }, []);
    return (
        <input
            className="block mb-2 w-full text-lg text-black bg-transparent h-full ml-1"
            type="text"
            value={text}
            onChange={(event) => (props.note.title = event.target.value)}
            placeholder="Titel"
        />
    );
}

function CombineProposalsToolBar(props: {
    note: Note;
    clientId: string;
}): JSX.Element {
    const CheckBoxes = () => {
        const td: any[] = [];
        for (let i = 0; i < 5; i++) {
            const checked = props.note.combinedSelect.indexOf(i) > -1;
            td.push(
                <div
                    className={
                        'ml-1 ' + (i % 2 == 0 ? 'bg-gray-200' : 'bg-yellow-400')
                    }
                >
                    <Checkbox
                        key={i}
                        checked={checked}
                        onCheckedChange={(checked) =>
                            props.note.setCombination(i, checked)
                        }
                    ></Checkbox>
                </div>
            );
        }
        return td;
    };

    return (
        <div className="flex flex-row">
            {CheckBoxes()}
            <div className="ml-1 mr-2">{props.note.votes.length}</div>
            {/* <LikeButton note={props.note} clientId={props.clientId} /> */}
        </div>
    );
}

export function AddNoteButton(props: {
    target: Items;
    clientId: string;
    elementType: ElementTypeEnum;
}): JSX.Element {
    const [{ isActive }, drop] = useDrop(() => ({
        accept: [dragType.NOTE, dragType.GROUP],
        collect: (monitor) => ({
            isActive: monitor.canDrop() && monitor.isOver(),
        }),
        canDrop: (item) => {
            if (Tree.is(item, Note)) return true;
            if (Tree.is(item, Group) && !Tree.contains(item, props.target))
                return true;
            return false;
        },
        drop: (item) => {
            if (Tree.is(item, Note) || Tree.is(item, Group)) {
                const parent = Tree.parent(item);
                if (Tree.is(parent, Items)) {
                    const index = parent.indexOf(item);
                    props.target.moveToEnd(index, parent);
                }
            }
            return;
        },
    }));

    let size = 'h-48 w-48';
    let buttonText = 'Vorschlag Hinufügen';
    if (props.target.length > 0) {
        buttonText = '+';
        size = 'h-48';
    }

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.target.addNode(props.clientId);
    };

    const renderSwitch = (param: ElementTypeEnum) => {
        // if (props.group.notes.length > 0) {
        //     buttonText = '+';
        //     size = 'h-48';
        // }
        switch (param) {
            case ElementTypeEnum.Note:
                const hoverEffectStyleVertical =
                    'absolute top-0 left-0 border-l-4 border-dashed h-48 ';
                return (
                    <div className="relative transition-all">
                        <div
                            className={
                                isActive
                                    ? hoverEffectStyleVertical + 'border-gray-500'
                                    : hoverEffectStyleVertical + 'border-transparent'
                            }
                        ></div>
                        <div
                            ref={drop}
                            className={
                                'transition-all text-2xl place-content-center font-bold flex flex-col text-center cursor-pointer bg-transparent border-white border-dashed border-4 ' +
                                size +
                                ' p-4 hover:border-black' +
                                ' ' +
                                (isActive ? 'translate-x-3' : '')
                            }
                            onClick={(e) => handleClick(e)}
                        >
                            {buttonText}
                        </div>
                    </div>
                );
            case ElementTypeEnum.CombineProp:
            case ElementTypeEnum.ResistanceMeas:
            case ElementTypeEnum.ShineThrough:
                const hoverEffectStyleHorizontal =
                    'border-4-1 border-dashed w-full ';
                size = 'w-full';
                console.log('isActive: ' + isActive);
                return (
                    <div className="w-full">
                        <div
                            className={
                                isActive
                                    ? hoverEffectStyleHorizontal + 'border-gray-500'
                                    : hoverEffectStyleHorizontal +
                                      'border-transparent'
                            }
                        ></div>
                        <div
                            ref={drop}
                            className={
                                'transition-all text-2xl place-content-center font-bold flex flex-col text-center cursor-pointer bg-transparent border-white border-dashed border-4 ' +
                                size +
                                ' hover:border-black' +
                                ' ' +
                                (isActive ? 'translate-y-3' : '')
                            }
                            onClick={(e) => handleClick(e)}
                        >
                            {buttonText}
                        </div>
                    </div>
                );
        }
    };
    return <>{renderSwitch(props.elementType)}</>;
}

function LikeButton(props: {
    voted: boolean;
    toggleVote: () => void;
    voteCount: number;
}): JSX.Element {
    const setColor = () => {
        if (props.voted) {
            return 'text-white';
        } else {
            return undefined;
        }
    };

    const setBackground = () => {
        if (props.voted) {
            return 'bg-green-600';
        } else {
            return undefined;
        }
    };

    return (
        <div className="relative flex z-50">
            <IconButton
                color={setColor()}
                background={setBackground()}
                handleClick={() => props.toggleVote()}
                icon={MiniThumb()}
            >
                {props.voteCount}
            </IconButton>
        </div>
    );
}

function DeleteNoteButton(props: { deleteNote: () => void }): JSX.Element {
    return <DeleteButton handleClick={() => props.deleteNote()}></DeleteButton>;
}

function DeleteProposalButton(props: { note: Note }): JSX.Element {
    return <DeleteButton handleClick={() => deleteNote(props.note)}></DeleteButton>;
}
