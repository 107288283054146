import {
    Dispatch,
    ReactElement,
    createContext,
    useContext,
    useReducer,
} from 'react';

export type FastConsensingType = {
    title: string;
    numberOfSuggestions: number;
    evaluationActive: boolean;
    allowUserEditing: boolean;
    allowSuggestionsFromParticipants: boolean;
    emailRequired: boolean;
    deleteAt: Date;
};

const initState: FastConsensingType = {
    title: '',
    numberOfSuggestions: 5,
    evaluationActive: true,
    allowUserEditing: false,
    allowSuggestionsFromParticipants: false,
    emailRequired: false,
    deleteAt: new Date(new Date().setDate(new Date().getDate() + 30)),
};

export const enum REDUCER_ACTION_TYPE {
    SET_TITLE,
    SET_NUMBER_OF_SUGGESTIONS,
    SET_EVALUATION_ACTIVE,
    SET_ALLOW_USER_EDITING,
    SET_ALLOW_SUGGESTIONS_FROM_PARTICIPANTS,
    SET_EMAIL_REQUIRED,
    SET_DELETE_AT,
    RESET_ALL,
}

type ReducerAction = {
    type: REDUCER_ACTION_TYPE;
    payload?: any;
};

const reducer = (
    state: FastConsensingType,
    action: ReducerAction
): FastConsensingType => {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.SET_TITLE:
            return { ...state, title: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_NUMBER_OF_SUGGESTIONS:
            return { ...state, numberOfSuggestions: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_EVALUATION_ACTIVE:
            return { ...state, evaluationActive: action.payload ?? [] };
        case REDUCER_ACTION_TYPE.SET_ALLOW_USER_EDITING:
            return { ...state, allowUserEditing: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_ALLOW_SUGGESTIONS_FROM_PARTICIPANTS:
            return {
                ...state,
                allowSuggestionsFromParticipants: action.payload ?? '',
            };
        case REDUCER_ACTION_TYPE.SET_EMAIL_REQUIRED:
            return { ...state, emailRequired: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_DELETE_AT:
            return { ...state, deleteAt: action.payload ?? '' };
        default:
            throw new Error('FastConsnensingContext reducer action.type not found');
    }
};

type ContextValue = {
    state: FastConsensingType;
    dispatch: Dispatch<ReducerAction>;
};

const SubjectContext = createContext<ContextValue>({
    state: initState,
    dispatch: () => {},
});

export const FastConsensingProvider = ({
    children,
}: {
    children: JSX.Element;
}): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <SubjectContext.Provider value={{ state, dispatch }}>
            {children}
        </SubjectContext.Provider>
    );
};

export const useFastConsensingContextValue = (): ContextValue => {
    return useContext(SubjectContext);
};
