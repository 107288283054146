import React, { FC } from 'react';
import { useAuthContextValue } from './context/AuthProviderContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '../../components/ui/navigation-menu';
import { cn } from '../../lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faLightbulb,
    faGlobe,
    faBarChart,
    faMobile,
    faThumbsUp,
    faUsers,
    faReceipt,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import Login from './authentication/Login';

import {
    Menubar,
    MenubarCheckboxItem,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarRadioGroup,
    MenubarRadioItem,
    MenubarSeparator,
    MenubarShortcut,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarTrigger,
} from '../../components/ui/menubar';
import { useTranslation } from 'react-i18next';
import { Label } from 'recharts';
import useLogout from './hooks/useLogout';
import { Button } from '../../components/ui/button';

interface loginfieldProps {}

const Loginfield: FC<loginfieldProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['mysk']);
    const authContext = useAuthContextValue();
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('');
    };
    return (
        <>
            {
                authContext.state.isLoggedIn ? (
                    <Menubar>
                        <MenubarMenu>
                            <MenubarTrigger className="text-black">
                                <p className="text-2xl">
                                    {authContext.state.user.toUpperCase()[0]}
                                </p>
                            </MenubarTrigger>
                            <MenubarContent>
                                <MenubarItem>{authContext.state.user}</MenubarItem>
                                <MenubarItem>
                                    <Link to={'/my_subjects' + location.hash}>
                                        {t('my_subjects')}
                                    </Link>
                                </MenubarItem>
                                <MenubarItem>
                                    <Link to={'/my_mailing_lists' + location.hash}>
                                        {t('subject.create')}
                                    </Link>
                                </MenubarItem>
                                <MenubarItem>
                                    <Link to={'/my_mailing_lists' + location.hash}>
                                        {t('my_mailing_lists')}
                                    </Link>
                                </MenubarItem>
                                <MenubarItem>
                                    <Link to={'/my_pass' + location.hash}>
                                        {t('my_pass')}
                                    </Link>
                                </MenubarItem>
                                <MenubarItem>
                                    <Button onClick={signOut}>
                                        {t('security.logout')}
                                    </Button>
                                </MenubarItem>
                            </MenubarContent>
                        </MenubarMenu>
                    </Menubar>
                ) : (
                    <Menubar>
                        <MenubarMenu>
                            <MenubarTrigger>
                                <FontAwesomeIcon
                                    fontSize={'50px'}
                                    icon={faUserCircle as IconProp}
                                    color="blue"
                                />
                            </MenubarTrigger>
                            <MenubarContent>
                                <Login />
                            </MenubarContent>
                        </MenubarMenu>
                    </Menubar>
                )
                //   {/* <NavigationMenuItem className=''>
                //   <NavigationMenuTrigger className=''>
                //     <FontAwesomeIcon  icon={faBarChart as IconProp} />
                //   </NavigationMenuTrigger>
                //   <NavigationMenuContent className='z-40 hover:z-50 bg-slate-300' >
                //     <Login />
                //   </NavigationMenuContent>
                // </NavigationMenuItem> */}
            }
        </>
    );
};

const ListItem = React.forwardRef<
    React.ElementRef<'a'>,
    React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
                        className
                    )}
                    {...props}
                >
                    <div className="text-sm font-medium leading-none">{title}</div>
                    <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                        {children}
                    </p>
                </a>
            </NavigationMenuLink>
        </li>
    );
});
ListItem.displayName = 'ListItem';

export default Loginfield;
