import React, { ElementType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContextValue } from './context/AuthProviderContext';
import {
    ConnectionState,
    IFluidContainer,
    IMember,
    IServiceAudience,
} from 'fluid-framework';
import { undefinedUserId } from '../utils/utils';
import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    navigationMenuTriggerStyle,
} from '../../components/ui/navigation-menu';
import acceptif_logo_white from './../images/acceptify_logo_white.svg';
import { Link } from 'react-router-dom';
import Loginfield from './Loginfield';
import { LanguageSelector } from './components/LanguageSelector';
import { IAzureAudience } from '@fluidframework/azure-client';
import { cva } from 'class-variance-authority';
import { useSubjectContextValue } from './context/SubjectContext';
import LinkShower from './components/LinkShower';

export function Header(props: {
    fluidMembers: string[];
    clientId: string;
    currentUser: string;
    //    setConnectionState: (arg: string) => void;
    //    setSaved: (arg: boolean) => void;
    setCurrentUser: (arg: string) => void;
    setFluidMembers: (arg: string[]) => void;
}): JSX.Element {
    const subjectContext = useSubjectContextValue();
    const { t, i18n } = useTranslation(['home', 'main']);
    const authContext = useAuthContextValue();
    const [connectionState, setConnectionState] = useState('');
    let savedSetting = false;
    if (subjectContext.state.fluidWS) {
        savedSetting = !subjectContext.state.fluidWS.container.isDirty;
    }
    const [saved, setSaved] = useState(savedSetting);

    const navigationMenuTriggerStyle2 = cva(
        'transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50'
    );

    useEffect(() => {
        const fluid = subjectContext.state.fluidWS;
        if (fluid) {
            const updateConnectionState = () => {
                if (fluid.container.connectionState === ConnectionState.Connected) {
                    setConnectionState('connected');
                } else if (
                    fluid.container.connectionState === ConnectionState.Disconnected
                ) {
                    setConnectionState('disconnected');
                } else if (
                    fluid.container.connectionState ===
                    ConnectionState.EstablishingConnection
                ) {
                    setConnectionState('connecting');
                } else if (
                    fluid.container.connectionState === ConnectionState.CatchingUp
                ) {
                    setConnectionState('catching up');
                }
            };
            updateConnectionState();
            setSaved(!fluid.container.isDirty);
            fluid.container.on('connected', updateConnectionState);
            fluid.container.on('disconnected', updateConnectionState);
            fluid.container.on('dirty', () => setSaved(false));
            fluid.container.on('saved', () => setSaved(true));
            fluid.container.on('disposed', updateConnectionState);
        }
    }, [subjectContext.state.fluidWS]);

    return (
        <>
            <div className="supergreen text-white sticky top-0 z-10">
                <section className="max-w-4xl mx-auto p-4 flex justify-between items-center">
                    <Link to={'/' + location.hash}>
                        <img src={acceptif_logo_white} className="w-24"></img>
                    </Link>
                    {/* <div>{authContext.state.user}</div>
                    {saved ? 'saved' : 'not saved'} | {connectionState} | users: {props.fluidMembers.length}
                 */}
                    <NavigationMenu>
                        <NavigationMenuList>
                            {subjectContext.state.publicLink && (
                                <LinkShower link={subjectContext.state.publicLink} />
                            )}
                            <NavigationMenuItem>
                                <Link to={'/why_acceptify' + location.hash}>
                                    <NavigationMenuLink
                                        className={navigationMenuTriggerStyle2()}
                                        href={'/why_acceptify' + location.hash}
                                    >
                                        Warum Acceptify
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>
                            <NavigationMenuItem>
                                <Link to={'/pricing' + location.hash}>
                                    <NavigationMenuLink
                                        className={navigationMenuTriggerStyle2()}
                                        href={'/pricing' + location.hash}
                                    >
                                        Preise
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>

                            {authContext.state.isLoggedIn ? (
                                <p></p>
                            ) : (
                                <Link to={'/register' + location.hash}>
                                    <NavigationMenuLink
                                        href={'/register' + location.hash}
                                    >
                                        Registrieren
                                    </NavigationMenuLink>
                                </Link>
                            )}
                        </NavigationMenuList>
                    </NavigationMenu>
                    <Loginfield />
                    <LanguageSelector />
                </section>
            </div>
        </>
    );
}
