import { Button } from '../../../components/ui/button';
import { Copy, Link } from 'lucide-react';
import React, { FC } from 'react';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '../../../components/ui/dialog';
import { Label } from '../../../components/ui/label';
import { Input } from '../../../components/ui/input';
import QRCode from 'react-qr-code';

interface LinkShowerProps {
    link: string;
}

const LinkShower: FC<LinkShowerProps> = ({ link }): JSX.Element => {
    async function onCopyToClipboard() {
        await navigator.clipboard.writeText(link);
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button type="submit">
                    <Link />
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-md">
                <DialogHeader>
                    <DialogTitle>Link für gemeinsames Entscheiden</DialogTitle>
                </DialogHeader>
                <div
                    style={{
                        height: 'auto',
                        margin: '0 auto',
                        maxWidth: 512,
                        width: '100%',
                    }}
                >
                    <QRCode
                        size={256}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value={link}
                        viewBox={`0 0 256 256`}
                    />
                </div>
                <div className="flex items-center space-x-2">
                    <div className="grid flex-1 gap-2">
                        <Label htmlFor="link" className="sr-only">
                            Link
                        </Label>
                        <Input id="link" defaultValue={link} readOnly />
                    </div>
                    <Button
                        type="submit"
                        size="sm"
                        className="px-3"
                        onClick={onCopyToClipboard}
                    >
                        <span className="sr-only">Kopieren</span>
                        <Copy className="h-4 w-4" />
                    </Button>
                </div>
                <DialogFooter className="sm:justify-start">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Schließen
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default LinkShower;
