import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useAuthContextValue } from '../context/AuthProviderContext';

const RequireAuth = ({ allowedRoles }: any) => {
    const useAuth = useAuthContextValue();
    const location = useLocation();

    return (allowedRoles == undefined && useAuth.state?.user) ||
        useAuth.state?.roles?.find((role) =>
            allowedRoles == undefined ? true : allowedRoles?.includes(role)
        ) ? (
        <Outlet />
    ) : useAuth.state?.user ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default RequireAuth;
