import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LegalnfoProps {}

function legalInfoMultiLanguage(language: string) {
    switch (language) {
        case 'en':
        case 'de':
        case 'it':
        case 'es':
            return (
                <>
                    <div>
                        <h3 className="main">
                            <strong>Impressum</strong>
                        </h3>
                        <p>Medieninhaber:</p>
                        <address>
                            <strong>
                                <i>acceptify GmbH</i>
                            </strong>
                            <br />
                            <i>
                                Paracelsusweg 1<br />
                                8144 Tobelbad
                                <br />
                                Österreich
                            </i>
                            <br />
                            Email:{' '}
                            <a href="mailto:service@acceptify.at">
                                service@acceptify.at
                            </a>
                            <br />
                            Web:{' '}
                            <a href="http://www.acceptify.at">www.acceptify.at</a>
                            <br />
                        </address>
                        <h4 data-cy="acceptify_goals">Ziele von acceptify:</h4>
                        <strong>Vom Widerstand zur Akzeptanz = acceptify</strong>
                        <p>
                            <b>Warum bringen wir acceptify in die Welt?</b>
                        </p>
                        <ul>
                            <li>
                                Damit ein Klima, wie mit echten Freunden sich in
                                Firmen, Teams, Gemeinden, Bildungssystem und
                                politischen Gremien einstellen kann.
                            </li>
                            <li>
                                Damit Unterschiedlichkeit nicht zu Streit und
                                Konflikt führt, sondern zu Innovationen und
                                gleichzeitig pragmatischen Lösungen.
                            </li>
                            <li>
                                Damit Beteiligung als ein strategischer Mehrwert
                                erschlossen werden kann.
                            </li>
                        </ul>
                        <p>
                            <b>Was tun wir?</b>
                        </p>
                        <ul>
                            <li>
                                Wir unterstützen die Gesellschaft bei Entscheidungen
                                und verbessern das Zusammenleben.
                            </li>
                            <li>
                                Wir sind Lösungsgestalter mit Vertrauen in die
                                Intelligenz der Gruppe und Einzelner. Dabei nutzen
                                wir das SK-Prinzip und verwandte Methoden zur
                                Aktivierung der Gruppenintelligenz und
                                hochqualitative State of the Art Software um einen
                                hohen Kundennutzen zu erreichen.
                            </li>
                            <li>
                                Unsere Software fördert kollektive Problemlösungen
                                und ermöglicht tragfähige Entscheidungen mit hoher
                                Akzeptanz.
                            </li>
                        </ul>
                        <p>
                            <b>Wer sind wir?</b>
                        </p>
                        <ul>
                            <li>
                                Ein partizipatives Startup, das mit den Erfindern und
                                Entwicklern der Methode Systemisches Konsensieren
                                gemeinsam Partizipations-Software-Lösungen
                                entwickelt.
                            </li>
                            <li>
                                Dabei kooperieren wir mit dem bestehenden SK-Prinzip
                                Netzwerk als Trainings-, Beratungs- und Service
                                Partner für ergänzende Dienstleistungen.
                            </li>
                        </ul>

                        <h3>Unser Team</h3>
                        <p>
                            Dominik Berger
                            <br />
                            Erich Visotschnig
                            <br />
                            Frank Strieder <br />
                            Gerhard Hammer
                            <br />
                            Markus Kickmaier
                            <br />
                            Volker Visotschnig
                            <br />
                        </p>

                        <p>
                            <strong>Offenlegung gemäß §25 Mediengesetz</strong>
                            <br />
                            Medieninhaber: siehe oben
                            <br />
                            Unternehmensgegenstand: siehe oben
                            <br />
                            Beteiligungen:{' '}
                            <a href="https://www.sk-prinzip.eu">
                                Isykonsens International
                            </a>
                            ,<a href="https://www.apus.at">Apus Software GmbH</a>
                            <br />
                            Blattlinie: Das Systemische Konsensieren: Werkzeug,
                            Informationen, Software, Dienstleistungen, Beteiligung,
                            Partizipation, Bürgerbeteiligung, Direkte Demokratie,
                            Agile Teams, Mitbestimmung, Kollektive Führung,
                            Partnerschaftliche Führung, Group Centered Leadership,
                            Gruppenentscheidungen, Gruppenintelligenz.
                        </p>
                    </div>
                </>
            );
    }
}

const Legalnfo: FC<LegalnfoProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['mysk']);
    return (
        <div className="m-14 p-5 bg-slate-300 rounded">
            {legalInfoMultiLanguage(i18n.language)}
        </div>
    );
};

export default Legalnfo;
