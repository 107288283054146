import React from 'react';

//import { RootNote, Note } from '../schema/notes_schema';

import firstIdeas from './images/firstIdeas.png';
import proposals from './images/proposals.png';
import shineThrough from './images/shine_through.png';
import resistanceMeasurement from './images/resistance_measurement.png';
import exploreResistance from './images/explore_resistances.png';
import passiveOption from './images/passive_option.png';

// export function FirstIdeasButton(props: {
//     root: RootNote;
//     selection: Note[];
// }): JSX.Element {
//     const handleClick = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         // const group = addGroup(props.root, '[new group]');
//         // for (const s of props.selection) {
//         //     moveItem(s, Infinity, group.notes);
//         // }
//     };
//     return (
//         <ImageButton
//             color="white"
//             background="black"
//             handleClick={(e: React.MouseEvent) => handleClick(e)}
//             image={firstIdeas}
//         >
//             Add Group
//         </ImageButton>
//     );
// }

// export function proposalsButton(props: {
//     root: RootNote;
//     selection: Note[];
// }): JSX.Element {
//     const handleClick = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         // const group = addGroup(props.root, '[new group]');
//         // for (const s of props.selection) {
//         //     moveItem(s, Infinity, group.notes);
//         // }
//     };
//     return (
//         <ImageButton
//             color="white"
//             background="black"
//             handleClick={(e: React.MouseEvent) => handleClick(e)}
//             image={proposals}
//         >
//             Add Group
//         </ImageButton>
//     );
// }

export function ImageButton(props: {
    handleClick: (value: React.MouseEvent) => void;
    children?: React.ReactNode;
    image: any;
    color?: string;
    background?: string;
}): JSX.Element {
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.handleClick(e);
    };

    return (
        <button
            className={
                props.color +
                ' ' +
                props.background +
                ' hover:bg-gray-600 hover:text-white font-bold px-2 py-1 rounded inline-flex items-center h-6 grow'
            }
            onClick={(e) => handleClick(e)}
        >
            <img src={props.image} width={80} />
        </button>
    );
}

export function FirstIdeasImage(): JSX.Element {
    return (
        <ImageWithText color="white" background="black" image={firstIdeas}>
            Add Group
        </ImageWithText>
    );
}

export function ProposalsImage(): JSX.Element {
    return (
        <ImageWithText color="white" background="black" image={proposals}>
            Add Group
        </ImageWithText>
    );
}

export function ShineThroughImage(): JSX.Element {
    return (
        <ImageWithText color="white" background="black" image={shineThrough}>
            Add Group
        </ImageWithText>
    );
}

export function ResistanceMeasurementImage(): JSX.Element {
    return (
        <ImageWithText
            color="white"
            background="black"
            image={resistanceMeasurement}
        >
            Add Group
        </ImageWithText>
    );
}

export function ExploreResistanceImage(): JSX.Element {
    return (
        <ImageWithText color="white" background="black" image={exploreResistance}>
            Add Group
        </ImageWithText>
    );
}

export function PassiveOptionImage(): JSX.Element {
    return (
        <ImageWithText color="white" background="black" image={passiveOption}>
            Add Group
        </ImageWithText>
    );
}

export function ImageWithText(props: {
    children?: React.ReactNode;
    image: any;
    color?: string;
    background?: string;
}): JSX.Element {
    return <img src={props.image} width={100} />;
}
