import React, { FC, useState } from 'react';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
} from '../../../components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../components/ui/input';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';
import { Button } from '../../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Textarea } from '../../../components/ui/textarea';
import { useToast } from '../../../components/hooks/use-toast';
import axios from 'axios';

interface FeedbackProps {}

const FEEDBACK_URL = '/manage/feedback';

const Feedback: FC<FeedbackProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['feedback']);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const { toast } = useToast();

    const formSchema = z.object({
        text: z.string().min(5, t('Fülle dieses Feld aus')),
        email: z.string().email(),
    });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            text: '',
            email: '',
        },
    });
    async function onSubmit(values: z.infer<typeof formSchema>) {
        try {
            const stringified = JSON.stringify({
                email: values.email,
                text: values.text,
            });
            const response = await axios.post(
                FEEDBACK_URL,
                JSON.stringify({ email: values.email, text: values.text }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const emailNotFound = response?.status == 204;
            if (emailNotFound) {
                toast({ title: t('info.unknown_email') });
                return;
            }

            // setSuccess(true);
        } catch (err: any) {
            if (!err?.response) {
                toast({ title: t('No Server Response') });
            } else if (err.response?.status === 400) {
                toast({ title: t('No Server Response') });
            } else {
                toast({ title: t('Failed') });
            }
        }
    }

    return (
        <div className="m-14 p-5 bg-slate-300 rounded">
            {success ? (
                <>
                    <div>{t('thank_you_message_after')}</div>
                    <Button type="submit" onClick={() => navigate(-1)}>
                        <ChevronLeft />
                    </Button>
                </>
            ) : (
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="text"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        {t('thank_you_message_before')}.
                                    </FormLabel>
                                    <FormControl>
                                        <Textarea {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{t('feedback.email')}.</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>
                                        {t('feedback.email.help')}
                                    </FormDescription>
                                </FormItem>
                            )}
                        />
                        <Button type="submit" onClick={() => navigate(-1)}>
                            <X />
                        </Button>
                        <Button type="submit">
                            <ChevronRight />
                        </Button>
                    </form>
                </Form>
            )}
        </div>
    );
};

export default Feedback;
