import React, { FC } from 'react';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../../components/ui/form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '../../../../components/ui/accordion';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../../components/ui/label';
import { Switch } from '../../../../components/ui/switch';
import SwitchFormField from '../../components/SwitchFormField';
import { Button } from '../../../../components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../../../components/ui/input';
import moment from 'moment';
import {
    REDUCER_ACTION_TYPE,
    useNewSubjectContextValue,
} from '../../context/NewSubjectContext';

interface SettingsProps {}

const Settings: FC<SettingsProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['settings', 'subject']);
    const navigate = useNavigate();
    const subjectContext = useNewSubjectContextValue();
    const dateNow = new Date().toISOString();

    const dateStr = dateNow.split('.')[0];

    const formSchema = z.object({
        startSuggestionPeriod: z.string().default(dateStr),
        endSuggestionPeriod: z.string().default(dateStr),
        startRatingPeriod: z.string().default(dateStr),
        endRatingPeriod: z.string().default(dateStr),
        closingDate: z.string().default(dateStr),

        allow_withdraw: z.boolean().default(false),
        allowSuggestionsFrom_participants: z.boolean().default(false),
        allow_discussion: z.boolean().default(false),
        allow_suggestion_completion: z.boolean().default(false),
        allow_addendum_from_author: z.boolean().default(false),
        allow_addendum_from_moderator: z.boolean().default(false),

        invitationOnly: z.boolean().default(false),
        autoLoginAsDummyUser: z.boolean().default(false),
        participantsInvite: z.boolean().default(false),
        emailRequired: z.boolean().default(false),

        export_with_users: z.boolean().default(false),
        show_user_list: z.boolean().default(false),
        show_author_names: z.boolean().default(false),
        show_pseudonyms_on_comments: z.boolean().default(false),
        show_acceptance: z.boolean().default(false),

        isSuggestionsOn: z.boolean(),
        isRatingOn: z.boolean(),
        isShowResults: z.boolean(),
    });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            startSuggestionPeriod: dateStr,
            endSuggestionPeriod: dateStr,
            allowSuggestionsFrom_participants:
                subjectContext.state.allowSuggestionsFromParticipants,
            allow_suggestion_completion:
                subjectContext.state.allowSuggestionCompletion,
            isSuggestionsOn: subjectContext.state.isSuggestionsOn,
            isRatingOn: subjectContext.state.isRatingOn,
            isShowResults: subjectContext.state.isShowResults,
        },
    });

    function saveTheData(values: z.infer<typeof formSchema>) {
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_ALLOW_SUGGESTIONS_FROM_PARTICIPANTS,
            payload: values.allowSuggestionsFrom_participants,
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_ALLOW_SUGGESTION_COMPLETION,
            payload: values.allow_suggestion_completion,
        });

        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_IS_SUGGESTIONS_ON,
            payload: values.isSuggestionsOn,
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_IS_RATING_ON,
            payload: values.isRatingOn,
        });
        subjectContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_IS_SHOW_RESULTS_ON,
            payload: values.isShowResults,
        });
    }

    function onSubmit(values: z.infer<typeof formSchema>) {
        // Do something with the form values.
        // ✅ This will be type-safe and validated.
        saveTheData(values);
        console.log(values);
        navigate('/setup/passive_suggestion', { replace: true });
    }

    function onBack() {
        console.log(form.getValues());
        saveTheData(form.getValues());
        navigate('/setup/how_to_admister', { replace: true });
    }
    return (
        <div className="form-frame">
            <div className="rounded-md border p-4">{t('display_settings_info')}</div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Accordion type="single" collapsible>
                        <AccordionItem value="item-1">
                            <AccordionTrigger>
                                {subjectContext.state.isTimed
                                    ? t('dates')
                                    : 'Steuerung'}
                            </AccordionTrigger>
                            <AccordionContent>
                                <div>
                                    {subjectContext.state.isTimed ? (
                                        <>
                                            <div>
                                                {t('subject.suggestion_period')}
                                            </div>
                                            <div className="flex">
                                                <FormField
                                                    control={form.control}
                                                    name="startSuggestionPeriod"
                                                    render={({ field }) => (
                                                        <FormItem className="space-y-3">
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="datetime-local"
                                                                />
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />
                                                <div className="w-5 text-center my-2">
                                                    {' '}
                                                    -{' '}
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name="endSuggestionPeriod"
                                                    render={({ field }) => (
                                                        <FormItem className="space-y-3">
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="datetime-local"
                                                                />
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <div>{t('subject.rating_period')}</div>
                                            <div className="flex">
                                                <FormField
                                                    control={form.control}
                                                    name="startRatingPeriod"
                                                    render={({ field }) => (
                                                        <FormItem className="space-y-3">
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="datetime-local"
                                                                />
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />
                                                <div className="w-5 text-center my-2">
                                                    {' '}
                                                    -{' '}
                                                </div>
                                                <FormField
                                                    control={form.control}
                                                    name="endRatingPeriod"
                                                    render={({ field }) => (
                                                        <FormItem className="space-y-3">
                                                            <FormControl>
                                                                <Input
                                                                    {...field}
                                                                    type="datetime-local"
                                                                />
                                                            </FormControl>
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <SwitchFormField
                                                name={'isSuggestionsOn'}
                                                form={form}
                                                label={t(
                                                    'subject.suggestion_period'
                                                )}
                                            />
                                            <SwitchFormField
                                                name={'isRatingOn'}
                                                form={form}
                                                label={t('subject.rating_period')}
                                            />
                                            <SwitchFormField
                                                name={'isShowResults'}
                                                form={form}
                                                label="Ergebnisse und Zwischenergebnisse anzeigen"
                                            />
                                        </>
                                    )}
                                    <div>
                                        {t('subject.closing_date', {
                                            ns: 'subject',
                                        })}
                                    </div>
                                    <div className="flex">
                                        <FormField
                                            control={form.control}
                                            name="closingDate"
                                            render={({ field }) => (
                                                <FormItem className="space-y-3">
                                                    <FormControl>
                                                        <Input
                                                            {...field}
                                                            type="datetime-local"
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-2">
                            <AccordionTrigger>{t('Allgemeines')}</AccordionTrigger>
                            <AccordionContent>
                                <SwitchFormField
                                    name={'allowSuggestionsFrom_participants'}
                                    form={form}
                                    label={t(
                                        'subject.allowSuggestionsFrom_participants',
                                        { ns: 'subject' }
                                    )}
                                />
                                <SwitchFormField
                                    name={'allow_discussion'}
                                    form={form}
                                    label={t('comment.allow_discussion', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'allow_suggestion_completion'}
                                    form={form}
                                    label={t('subject.allow_suggestion_completion', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'allow_addendum_from_author'}
                                    form={form}
                                    label={t('subject.allow_addendum_from_author', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'allow_addendum_from_moderator'}
                                    form={form}
                                    label={t(
                                        'subject.allow_addendum_from_moderator',
                                        { ns: 'subject' }
                                    )}
                                />
                                <SwitchFormField
                                    name={'allow_withdraw'}
                                    form={form}
                                    label={t('subject.allow_withdraw', {
                                        ns: 'subject',
                                    })}
                                />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-3">
                            <AccordionTrigger>{t('security')}?</AccordionTrigger>
                            <AccordionContent>
                                <SwitchFormField
                                    name={'invitationOnly'}
                                    form={form}
                                    label={t('subject.invitation.how.title', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'autoLoginAsDummyUser'}
                                    form={form}
                                    label={t('subject.autologin.title', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'participantsInvite'}
                                    form={form}
                                    label={t('subject.invitation.who.title', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'emailRequired'}
                                    form={form}
                                    label={t('subject.invitation.mail.title', {
                                        ns: 'subject',
                                    })}
                                />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-4">
                            <AccordionTrigger>{t('display')}</AccordionTrigger>
                            <AccordionContent>
                                <SwitchFormField
                                    name={'export_with_users'}
                                    form={form}
                                    label={t('subject.export_with_users', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'show_user_list'}
                                    form={form}
                                    label={t('subject.allow_suggestion_completion', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'show_author_names'}
                                    form={form}
                                    label={t('subject.allow_addendum_from_author', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'show_pseudonyms_on_comments'}
                                    form={form}
                                    label={t('subject.show_pseudonyms_on_comments', {
                                        ns: 'subject',
                                    })}
                                />
                                <SwitchFormField
                                    name={'show_acceptance'}
                                    form={form}
                                    label={t('subject.show_acceptance', {
                                        ns: 'subject',
                                    })}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    {/* <pre>{JSON.stringify(form.watch(),null,2)}</pre> */}
                    <div className="mt-2 float-right">
                        <Button type="submit" className="mr-2" onClick={onBack}>
                            <ChevronLeft />
                        </Button>
                        <Button type="submit">
                            <ChevronRight />
                        </Button>
                    </div>
                    <div className="clear-both" />
                </form>
            </Form>
        </div>
    );
};

export default Settings;
