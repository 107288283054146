import { Tree } from 'fluid-framework';
import React, { FC, useEffect, useState } from 'react';
import { Settings } from 'src/schema/app_schema';

interface FluidTextInputProps {
    settings: Settings;
}

const FluidTextInput: FC<FluidTextInputProps> = ({ settings }): JSX.Element => {
    const [text, setText] = useState<string>(settings.title);

    useEffect(() => {
        // Returns the cleanup function to be invoked when the component unmounts.
        const unsubscribe = Tree.on(settings, 'nodeChanged', () => {
            setText(settings.title);
        });
        return unsubscribe;
    }, []);
    return (
        <input
            className="block mb-2 w-full text-lg text-black bg-transparent ml-1"
            type="text"
            value={text}
            onChange={(event) => (settings.title = event.target.value)}
            placeholder="Titel"
        />
    );
};

export default FluidTextInput;
