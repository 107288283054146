import React, { FC, ReactElement } from 'react';
import { Link, Outlet, useMatch, useResolvedPath } from 'react-router-dom';
import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    navigationMenuTriggerStyle,
} from '../../../../../components/ui/navigation-menu';
import {
    ExploreResistanceImage,
    FirstIdeasImage,
    PassiveOptionImage,
    ProposalsImage,
    ResistanceMeasurementImage,
    ShineThroughImage,
} from '../../../../react/button_sc_ux';

import { cva } from 'class-variance-authority';

interface combinedSubjectHeaderProps {}

const navigationMenuTriggerStyleDoubleHeight = cva(
    'group inline-flex h-20 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50'
);

const CombinedSubjectHeader: FC<combinedSubjectHeaderProps> = ({}): JSX.Element => {
    return (
        <div>
            <NavigationMenu>
                <NavigationMenuList>
                    <NavigationMenuItem>
                        <CustomLink
                            to={'/combined/first_ideas' + location.hash}
                            button={<FirstIdeasImage />}
                        />
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <CustomLink
                            to={'/combined/proposals' + location.hash}
                            button={<ProposalsImage />}
                        />
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <CustomLink
                            to={'/combined/passive_option' + location.hash}
                            button={<PassiveOptionImage />}
                        />
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <CustomLink
                            to={'/combined/shine_through' + location.hash}
                            button={<ShineThroughImage />}
                        />
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <CustomLink
                            to={'/combined/resistance_measurement' + location.hash}
                            button={<ResistanceMeasurementImage />}
                        />
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <CustomLink
                            to={'/combined/explore_resistance' + location.hash}
                            button={<ExploreResistanceImage />}
                        />
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
            <Outlet />
        </div>
    );
};

export function CustomLink(props: { to: any; button: ReactElement | string }) {
    const resolvedPath = useResolvedPath(props.to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const isActiveBool = isActive ? true : false;
    return (
        <Link to={props.to + location.hash}>
            <NavigationMenuLink
                active={isActiveBool}
                className={navigationMenuTriggerStyleDoubleHeight()}
            >
                {props.button}
            </NavigationMenuLink>
        </Link>
    );
}

export default CombinedSubjectHeader;
