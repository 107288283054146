import { Note } from '../../schema/app_schema';

//import { escapeLeadingUnderscores } from "typescript";

//import { parentField } from '@fluid-experimental/tree2';
//import { Note } from "../../schema/notes_schema";

export type RateAndShowModel = Readonly<{
    CreateOrUpdateVote(voteValue: number, user: string): void;
    AcceptanceLevel: number;
    // setChangeListener(listener: (changed: any, local: any) => void): void;
    // removeChangeListener(listener: (changed: any, local: any) => void): void;
    GetUserVoting(user: string): number;
}>;

export function createRateAndShowModel(note: Note): RateAndShowModel {
    const isVoter = function isVoter(
        note: Note,
        user: { name: string; id: string }
    ) {
        for (const u of note.votes) {
            //   if (u.id == user) {
            //       return u;
            //   }
        }
        return undefined;
    };

    return {
        async CreateOrUpdateVote(voteValue: number, user: string) {
            // delete if already exists vote
            var index = null;
            for (const u of note.ratings) {
                if (u.userId == user) {
                    index = note.ratings.indexOf(u);
                    break;

                    //  note.ratings.deleteNodes(u[parentField].index,1)
                }
            }

            if (index != null) {
                note.ratings.removeAt(index);
            }

            if (voteValue >= 0) {
                const rating = { userId: user, voteValue: voteValue };
                note.ratings.insertAtEnd(rating);
                // note.ratings.insertNodes(note.votes.length, [rating])
            }
        },
        get AcceptanceLevel(): number {
            var numberOfVoters: number = 0;
            var restistance: number = 0;

            for (const u of note.ratings) {
                numberOfVoters += 1;
                restistance += u.voteValue;
            }

            let resistanceLevel = restistance / (numberOfVoters * 10);
            let accetpanceLev: number = 1 - resistanceLevel;
            return accetpanceLev;
        },
        GetUserVoting(user: string): number {
            for (const u of note.ratings) {
                if (u.userId == user) {
                    return u.voteValue;
                }
            }
            return -1;
        },
        // setChangeListener(listener: (changed: any, local: any) => void): void {
        //    note.ratings.  sharedMap.on("valueChanged", listener);
        // },
        // removeChangeListener(listener: (changed: any, local: any) => void): void {
        //   sharedMap.off("valueChanged", listener);
        // }
    };
}
