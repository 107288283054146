import { clientProps } from '../infra/clientProps';
import { loadFluidData } from '../infra/fluid';
import { containerSchema } from '../schema/container_schema';
import { AzureClient, AzureContainerServices } from '@fluidframework/azure-client';
import { OdspClient, OdspContainerServices } from '@fluidframework/odsp-client/beta';
import { ContainerSchema, IFluidContainer, TreeView } from 'fluid-framework';
import { Session, sessionTreeConfiguration } from '../schema/session_schema';
import {
    Apps,
    Group,
    Items,
    Settings,
    treeSchameBaseConfiguration,
} from '../schema/app_schema';
import { v4 as uuid } from 'uuid';
import { createUndoRedoStacks, undoRedo } from './undo';
import { SharedMap } from 'fluid-framework/lib';

export class FluidWorkspace {
    services: AzureContainerServices | OdspContainerServices;
    container: IFluidContainer;
    containerIdReady: string;
    apps: TreeView<typeof Apps>;
    sessionTree: TreeView<typeof Session>;
    undoRedo: undoRedo;

    constructor(
        services: AzureContainerServices | OdspContainerServices,
        container: IFluidContainer,
        containerIdReady: string,
        apps: TreeView<typeof Apps>,
        sessionTree: TreeView<typeof Session>,
        undoRedo: undoRedo
    ) {
        this.services = services;
        this.container = container;
        this.containerIdReady = containerIdReady;
        this.apps = apps;
        this.sessionTree = sessionTree;
        this.undoRedo = undoRedo;
    }
}
export async function createSettings(workspace: FluidWorkspace) {
    const timeStamp = new Date().getTime();
    const settings = new Settings({
        id: uuid(),
        title: '',
        decription: '',
        lastChanged: timeStamp,
    });
    workspace.apps.root.insertAtEnd(settings);
}

export async function createAppspace(workspace: FluidWorkspace, name: string) {
    const app = new Group({
        id: uuid(),
        name: name,
        items: new Items([]),
        type: 0,
        isCollapsed: false,
    });
    workspace.apps.root.insertAtEnd(app);
}

export async function createFluidContainer(
    containerId: string
): Promise<FluidWorkspace> {
    const client = new AzureClient(clientProps);
    // Initialize Fluid Container
    const { services, container } = await loadFluidData(
        containerId,
        containerSchema,
        client
    );

    const sessionTree = container.initialObjects.sessionData.viewWith(
        sessionTreeConfiguration
    );
    if (sessionTree.compatibility.canInitialize) {
        sessionTree.initialize({ clients: [] });
    }

    const apps = container.initialObjects.appData.viewWith(
        treeSchameBaseConfiguration
    );
    if (apps.compatibility.canInitialize) {
        apps.initialize(new Apps([]));
    }
    let containerIdReady = containerId;
    if (container.attachState != 'Attached') {
        containerIdReady = await container.attach();
    }

    const undoRedo = createUndoRedoStacks(apps.events);

    const fluidWorkspace = new FluidWorkspace(
        services,
        container,
        containerIdReady,
        apps,
        sessionTree,
        undoRedo
    );

    return fluidWorkspace;
}
