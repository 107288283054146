import React, { FC, useEffect, useState } from 'react';
import { resetPageSetAndGetLinkInfo } from '../components/LinkRouting';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

interface ConfrimEmailProps {}
const CONFIRM_URL = '/auth/confirm_email';

const ConfirmEmail: FC<ConfrimEmailProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['mysk']);
    const emailToken = resetPageSetAndGetLinkInfo();
    const [confirmed, setConfirmed] = useState<boolean>(false);

    //Confirm the e-mail
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.post(
                CONFIRM_URL,
                JSON.stringify({ token: emailToken }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            if (response?.status == 200) {
                setConfirmed(true);
            }
        };

        // call the function
        fetchData();
    }, []);

    return (
        <div className="m-14 p-5 bg-slate-300 rounded">
            {confirmed ? (
                <>
                    <p>{t('register.confirmed')}</p>
                    <p>{t('you_may_login')}</p>
                </>
            ) : (
                <div>Email could not be approved!</div>
            )}
        </div>
    );
};

export default ConfirmEmail;
