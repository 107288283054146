import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

let externalLinkSet: boolean = false;
let linkInfo: string | null = null;

export function resetPageSetAndGetLinkInfo(): string | null {
    if (externalLinkSet) {
        externalLinkSet = false;
        return linkInfo;
    }
    return null;
}

export const LinkRouting = (): ReactElement => {
    const navigate = useNavigate();

    let path = location.hash.substring(1);
    let pathArray = path.split('/');

    useEffect(() => {
        if (pathArray.length > 2) {
            linkInfo = pathArray[2];
            externalLinkSet = true;
            switch (pathArray[1]) {
                case 'confirm':
                    navigate('/confirm_email');
                    break;
                case 'subject':
                    navigate('/load_subject/' + linkInfo);
                    break;
                default:
                    // If not matching, get just to the start page
                    navigate('/');
                    break;
            }
        }
    }, []);

    return <></>;
};

export default LinkRouting;
