import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MainSelection from '../components/MainSelection';
import FastConsensingForm from './FastConsensingForm';

interface DecisionSystemSelectionProps {}

const DecisionSystemSelection: FC<
    DecisionSystemSelectionProps
> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['register', 'mysk']);
    return (
        <div>
            <div className="main-area pad">
                <h2 className="text-center mt-2">
                    Entscheidungsverfahren auswählen
                </h2>

                <div className="container mx-auto sm:px-4 max-w-full md:p-12 mt-1 bg-gray-500">
                    <div className="flex flex-wrap  m-auto text-center">
                        <MainSelection
                            title="Schnellkonsensierung"
                            buttonText="Starte Schnellkonsensierung"
                            linkToSelected="/fast_consensing/new"
                        >
                            <FastConsensingForm />
                        </MainSelection>
                        <MainSelection
                            title="Einfach"
                            description={[
                                'Führen Sie eine Entscheidung in einem Live Meeting oder auch über einen längeren Zeitraum durch',
                            ]}
                            buttonText="Starte einfache Entscheidung"
                            linkToSelected="/setup/distrib_mode/standard"
                        />
                        <MainSelection
                            title="Kombinierte Entscheidung"
                            description={[
                                'Eine Kombinationsentscheidung treffen',
                                'Beispiel: Weihnachtsfeier mit Lokation, Essen und Rahmenprogramm',
                            ]}
                            buttonText="Starte kombinierte Entscheidung"
                            linkToSelected="/setup/distrib_mode/combined"
                        />
                        <MainSelection
                            title="Gestalteter Entscheidungsprozess"
                            description={[
                                'Gestalten Sie die Schritte je nach Anforderung dynamisch',
                            ]}
                            buttonText="Starte gestalteter Entscheidungsprozess"
                            linkToSelected="/setup/distrib_mode/dynamic"
                        />
                        <MainSelection
                            title="Nutzwert-bewertete Entscheidung"
                            description={[
                                'Expertenentscheidungsfindung durch Nutzerwertbewertung',
                                'Beispiel: Welches Auto wollen wir als nächstes kaufen',
                            ]}
                            buttonText="Starte Nutzerwertbewertung"
                            linkToSelected="/setup/settings"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DecisionSystemSelection;
