import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

//Import all translation files
//English
import actionsEn from './Translation/English/actions.json';
import buttonsEn from './Translation/English/buttons.json';
import clientsideEn from './Translation/English/clientside.json';
import commentEn from './Translation/English/comment.json';
import create_subjectEn from './Translation/English/create_subject.json';
import denied_messagesEn from './Translation/English/denied_messages.json';
import duplicateEn from './Translation/English/duplicate.json';
import email_contentEn from './Translation/English/email_content.json';
import emailEn from './Translation/English/email.json';
import error_pagesEn from './Translation/English/error_pages.json';
import exportEn from './Translation/English/export.json';
import feedbackEn from './Translation/English/feedback.json';
import filterEn from './Translation/English/filter.json';
import formsEn from './Translation/English/forms.json';
import genericEn from './Translation/English/generic.json';
import helpEn from './Translation/English/help.json';
import invatationEn from './Translation/English/invitation.json';
import invatationlistEn from './Translation/English/invitationlist.json';
import inviteEn from './Translation/English/invite.json';
import landingEn from './Translation/English/landing.json';
import licenseEn from './Translation/English/license.json';
import loginEn from './Translation/English/login.json';
import matrixEn from './Translation/English/matrix.json';
import mylistEn from './Translation/English/mylist.json';
import myskEn from './Translation/English/mysk.json';
import pricingEn from './Translation/English/pricing.json';
import registerEn from './Translation/English/register.json';
import reportEn from './Translation/English/report.json';
import resultEn from './Translation/English/result.json';
import settingsEn from './Translation/English/settings.json';
import staticEn from './Translation/English/static.json';
import statisticsEn from './Translation/English/statistics.json';
import subjectEn from './Translation/English/subject.json';
import suggestionEn from './Translation/English/suggestion.json';
import translationEn from './Translation/English/translation.json';
import userEn from './Translation/English/user.json';
import validatorsEn from './Translation/English/validators.json';

// German
import actionsDe from './Translation/German/actions.json';
import buttonsDe from './Translation/German/buttons.json';
import clientsideDe from './Translation/German/clientside.json';
import commentDe from './Translation/German/comment.json';
import create_subjectDe from './Translation/German/create_subject.json';
import denied_messagesDe from './Translation/German/denied_messages.json';
import duplicateDe from './Translation/German/duplicate.json';
import email_contentDe from './Translation/German/email_content.json';
import emailDe from './Translation/German/email.json';
import error_pagesDe from './Translation/German/error_pages.json';
import exportDe from './Translation/German/export.json';
import feedbackDe from './Translation/German/feedback.json';
import filterDe from './Translation/German/filter.json';
import formsDe from './Translation/German/forms.json';
import genericDe from './Translation/German/generic.json';
import helpDe from './Translation/German/help.json';
import invatationDe from './Translation/German/invitation.json';
import invatationlistDe from './Translation/German/invitationlist.json';
import inviteDe from './Translation/German/invite.json';
import landingDe from './Translation/German/landing.json';
import licenseDe from './Translation/German/license.json';
import loginDe from './Translation/German/login.json';
import matrixDe from './Translation/German/matrix.json';
import mylistDe from './Translation/German/mylist.json';
import myskDe from './Translation/German/mysk.json';
import pricingDe from './Translation/German/pricing.json';
import registerDe from './Translation/German/register.json';
import reportDe from './Translation/German/report.json';
import resultDe from './Translation/German/result.json';
import settingsDe from './Translation/German/settings.json';
import staticDe from './Translation/German/static.json';
import statisticsDe from './Translation/German/statistics.json';
import subjectDe from './Translation/German/subject.json';
import suggestionDe from './Translation/German/suggestion.json';
import translationDe from './Translation/German/translation.json';
import userDe from './Translation/German/user.json';
import validatorsDe from './Translation/German/validators.json';

// Italian
import actionsIt from './Translation/Italian/actions.json';
import buttonsIt from './Translation/Italian/buttons.json';
import clientsideIt from './Translation/Italian/clientside.json';
import commentIt from './Translation/Italian/comment.json';
import create_subjectIt from './Translation/Italian/create_subject.json';
import denied_messagesIt from './Translation/Italian/denied_messages.json';
import duplicateIt from './Translation/Italian/duplicate.json';
import email_contentIt from './Translation/Italian/email_content.json';
import emailIt from './Translation/Italian/email.json';
import error_pagesIt from './Translation/Italian/error_pages.json';
import exportIt from './Translation/Italian/export.json';
import feedbackIt from './Translation/Italian/feedback.json';
import filterIt from './Translation/Italian/filter.json';
import formsIt from './Translation/Italian/forms.json';
import genericIt from './Translation/Italian/generic.json';
import helpIt from './Translation/Italian/help.json';
import invatationIt from './Translation/Italian/invitation.json';
import invatationlistIt from './Translation/Italian/invitationlist.json';
import inviteIt from './Translation/Italian/invite.json';
import landingIt from './Translation/Italian/landing.json';
import licenseIt from './Translation/Italian/license.json';
import loginIt from './Translation/Italian/login.json';
import matrixIt from './Translation/Italian/matrix.json';
import mylistIt from './Translation/Italian/mylist.json';
import myskIt from './Translation/Italian/mysk.json';
import pricingIt from './Translation/Italian/pricing.json';
import registerIt from './Translation/Italian/register.json';
import reportIt from './Translation/Italian/report.json';
import resultIt from './Translation/Italian/result.json';
import settingsIt from './Translation/Italian/settings.json';
import staticIt from './Translation/Italian/static.json';
import statisticsIt from './Translation/Italian/statistics.json';
import subjectIt from './Translation/Italian/subject.json';
import suggestionIt from './Translation/Italian/suggestion.json';
//import translationIt from './Translation/Italian/translation.json';
import userIt from './Translation/Italian/user.json';
import validatorsIt from './Translation/Italian/validators.json';

// Spanish
import actionsEs from './Translation/Spanish/actions.json';
import buttonsEs from './Translation/Spanish/buttons.json';
import clientsideEs from './Translation/Spanish/clientside.json';
import commentEs from './Translation/Spanish/comment.json';
import create_subjectEs from './Translation/Spanish/create_subject.json';
import denied_messagesEs from './Translation/Spanish/denied_messages.json';
//import duplicateEs from './Translation/Spanish/duplicate.json';
import email_contentEs from './Translation/Spanish/email_content.json';
import emailEs from './Translation/Spanish/email.json';
import error_pagesEs from './Translation/Spanish/error_pages.json';
import exportEs from './Translation/Spanish/export.json';
import feedbackEs from './Translation/Spanish/feedback.json';
import filterEs from './Translation/Spanish/filter.json';
import formsEs from './Translation/Spanish/forms.json';
import genericEs from './Translation/Spanish/generic.json';
import helpEs from './Translation/Spanish/help.json';
import invatationEs from './Translation/Spanish/invitation.json';
import invatationlistEs from './Translation/Spanish/invitationlist.json';
import inviteEs from './Translation/Spanish/invite.json';
import landingEs from './Translation/Spanish/landing.json';
import licenseEs from './Translation/Spanish/license.json';
import loginEs from './Translation/Spanish/login.json';
import matrixEs from './Translation/Spanish/matrix.json';
import mylistEs from './Translation/Spanish/mylist.json';
import myskEs from './Translation/Spanish/mysk.json';
import pricingEs from './Translation/Spanish/pricing.json';
import registerEs from './Translation/Spanish/register.json';
import reportEs from './Translation/Spanish/report.json';
import resultEs from './Translation/Spanish/result.json';
import settingsEs from './Translation/Spanish/settings.json';
import staticEs from './Translation/Spanish/static.json';
import statisticsEs from './Translation/Spanish/statistics.json';
import subjectEs from './Translation/Spanish/subject.json';
import suggestionEs from './Translation/Spanish/suggestion.json';
//import translationEs from './Translation/Spanish/translation.json';
import userEs from './Translation/Spanish/user.json';
import validatorsEs from './Translation/Spanish/validators.json';

//---Using translation
// const resources = {
//     en: {
//         translation: translationEnglish,
//     },
//     es: {
//         translation: translationSpanish,
//     },
//     fr: {
//         translation: translationFrench,
//     },
// }

//---Using different namespaces
const resources = {
    en: {
        actions: actionsEn,
        buttons: buttonsEn,
        clientside: clientsideEn,
        comment: commentEn,
        create_subject: create_subjectEn,
        denied_messages: denied_messagesEn,
        duplicate: duplicateEn,
        email_content: email_contentEn,
        email: emailEn,
        error_pages: error_pagesEn,
        export: exportEn,
        feedback: feedbackEn,
        filter: filterEn,
        forms: formsEn,
        generic: genericEn,
        help: helpEn,
        invatation: invatationEn,
        invatationlis: invatationlistEn,
        invite: inviteEn,
        landing: landingEn,
        license: licenseEn,
        login: loginEn,
        matrix: matrixEn,
        mylist: mylistEn,
        mysk: myskEn,
        pricing: pricingEn,
        register: registerEn,
        report: reportEn,
        result: resultEn,
        settings: settingsEn,
        static: staticEn,
        statistics: statisticsEn,
        subject: subjectEn,
        suggestion: suggestionEn,
        translation: translationEn,
        user: userEn,
        validators: validatorsEn,
    },
    de: {
        actions: actionsDe,
        buttons: buttonsDe,
        clientside: clientsideDe,
        comment: commentDe,
        create_subject: create_subjectDe,
        denied_messages: denied_messagesDe,
        duplicate: duplicateDe,
        email_content: email_contentDe,
        email: emailDe,
        error_pages: error_pagesDe,
        export: exportDe,
        feedback: feedbackDe,
        filter: filterDe,
        forms: formsDe,
        generic: genericDe,
        help: helpDe,
        invatation: invatationDe,
        invatationlis: invatationlistDe,
        invite: inviteDe,
        landing: landingDe,
        license: licenseDe,
        login: loginDe,
        matrix: matrixDe,
        mylist: mylistDe,
        mysk: myskDe,
        pricing: pricingDe,
        register: registerDe,
        report: reportDe,
        result: resultDe,
        settings: settingsDe,
        static: staticDe,
        statistics: statisticsDe,
        subject: subjectDe,
        suggestion: suggestionDe,
        translation: translationDe,
        user: userDe,
        validators: validatorsDe,
    },
    it: {
        actions: actionsIt,
        buttons: buttonsIt,
        clientside: clientsideIt,
        comment: commentIt,
        create_subject: create_subjectIt,
        denied_messages: denied_messagesIt,
        duplicate: duplicateIt,
        email_content: email_contentIt,
        email: emailIt,
        error_pages: error_pagesIt,
        export: exportIt,
        feedback: feedbackIt,
        filter: filterIt,
        forms: formsIt,
        generic: genericIt,
        help: helpIt,
        invatation: invatationIt,
        invatationlis: invatationlistIt,
        invite: inviteIt,
        landing: landingIt,
        license: licenseIt,
        login: loginIt,
        matrix: matrixIt,
        mylist: mylistIt,
        mysk: myskIt,
        pricing: pricingIt,
        register: registerIt,
        report: reportIt,
        result: resultIt,
        settings: settingsIt,
        static: staticIt,
        statistics: statisticsIt,
        subject: subjectIt,
        suggestion: suggestionIt,
        //        translation: translationIt,
        user: userIt,
        validators: validatorsIt,
    },
    es: {
        actions: actionsEs,
        buttons: buttonsEs,
        clientside: clientsideEs,
        comment: commentEs,
        create_subject: create_subjectEs,
        denied_messages: denied_messagesEs,
        //      duplicate: duplicateEs,
        email_content: email_contentEs,
        email: emailEs,
        error_pages: error_pagesEs,
        export: exportEs,
        feedback: feedbackEs,
        filter: filterEs,
        forms: formsEs,
        generic: genericEs,
        help: helpEs,
        invatation: invatationEs,
        invatationlis: invatationlistEs,
        invite: inviteEs,
        landing: landingEs,
        license: licenseEs,
        login: loginEs,
        matrix: matrixEs,
        mylist: mylistEs,
        mysk: myskEs,
        pricing: pricingEs,
        register: registerEs,
        report: reportEs,
        result: resultEs,
        settings: settingsEs,
        static: staticEs,
        statistics: statisticsEs,
        subject: subjectEs,
        suggestion: suggestionEs,
        //      translation: translationEs,
        user: userEs,
        validators: validatorsEs,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: 'en', //default language
});

export default i18next;
