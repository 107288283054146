import { useContext, useDebugValue } from 'react';
import { useAuthContextValue } from '../context/AuthProviderContext';

const useAuth = () => {
    const contextValue = useAuthContextValue();
    useDebugValue(contextValue.state, (auth) =>
        contextValue.state.accessToken != null ? 'Logged In' : 'Logged Out'
    );
    return contextValue;
};

export default useAuth;
