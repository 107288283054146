import { useRef, useState, useEffect, useContext } from 'react';
import {
    REDUCER_ACTION_TYPE,
    useAuthContextValue,
} from '../context/AuthProviderContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { useTranslation } from 'react-i18next';
import { Separator } from '../../../components/ui/separator';

const LOGIN_URL = '/auth/login';

const Login = () => {
    const authContext = useAuthContextValue();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLParagraphElement>(null);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [rMe, setrMe] = useState(false);

    const { t, i18n } = useTranslation(['mysk', 'login', 'register']);

    useEffect(() => {
        const userStore = localStorage.getItem('username');
        const passStore = localStorage.getItem('password');
        const rmeStore = localStorage.getItem('rememberMe');
        setrMe(rmeStore == 'true');

        if (userStore) {
            setUser(userStore);
        } else {
            setUser('');
        }
        if (passStore) {
            setPwd(passStore);
        } else {
            setPwd('');
        }

        userRef.current?.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (rMe) {
            localStorage.setItem('username', user);
            localStorage.setItem('password', pwd);
            localStorage.setItem('rememberMe', 'true');
        } else {
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
        }

        try {
            const response = await axios.post(
                LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            authContext.dispatch({
                type: REDUCER_ACTION_TYPE.SET_USER,
                payload: user,
            });
            authContext.dispatch({
                type: REDUCER_ACTION_TYPE.SET_PASSWORD,
                payload: pwd,
            });
            authContext.dispatch({
                type: REDUCER_ACTION_TYPE.SET_ROLES,
                payload: roles,
            });
            authContext.dispatch({
                type: REDUCER_ACTION_TYPE.SET_ACCESS_TOKEN,
                payload: accessToken,
            });

            setUser('');
            setPwd('');

            // navigate('/selection', { replace: true });
        } catch (err: any) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else if (err.response?.status === 402) {
                setErrMsg('Not Email Authenticated');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current?.focus();
        }
    };

    return (
        <section className="p-2">
            <p
                ref={errRef}
                className={errMsg ? 'errmsg' : 'offscreen'}
                aria-live="assertive"
            >
                {errMsg}
            </p>
            <form onSubmit={handleSubmit}>
                <Input
                    type="text"
                    ref={userRef}
                    autoComplete="false"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                    placeholder={t('username_or_email', { ns: 'login' })}
                />

                <Input
                    className="mt-2"
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    placeholder={t('password', { ns: 'register' })}
                />
                <div>
                    <input
                        className="mt-2"
                        type="checkbox"
                        checked={rMe}
                        onChange={() => setrMe(!rMe)}
                    />
                    Remember me
                </div>

                <Button>Sign In</Button>
            </form>
            <p>
                <span className="line">
                    <Link to={'/lost_pwd' + location.hash}>{t('lost_pass')}</Link>
                </span>
            </p>
            <Separator className="my-4" />
            <p>
                {t('no_account')}
                <br />
                <span className="line">
                    <Link to={'/register' + location.hash}>{t('register')}</Link>
                </span>
            </p>
        </section>
    );
};

export default Login;
