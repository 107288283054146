import React from 'react';
import { ButtonGroup, Floater, GenerateProposalsButton } from '../note/buttonux';
import {
    ConnectionState,
    IFluidContainer,
    IMember,
    IServiceAudience,
    Tree,
    TreeView,
} from 'fluid-framework';
import { Session } from '../../schema/session_schema';
import { Note, Group, Items } from '../../schema/app_schema';
import { IAzureAudience } from '@fluidframework/azure-client';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../components/ui/form';
import { Input } from '../../../components/ui/input';
import { Textarea } from '../../../components/ui/textarea';
import { Button } from '../../../components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';

export function PassiveOption(props: {}): JSX.Element {
    const { t, i18n } = useTranslation(['create_subject']);

    const formSchema = z.object({
        title: z.string().min(2, {
            message: 'Username must be at least 2 characters.',
        }),
        description: z.string(),
    });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: '',
            description: '',
        },
    });

    function onWithoutPassive() {}

    function onSubmit(values: z.infer<typeof formSchema>) {}
    return (
        <div>
            <div className="form-frame">
                <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 mr-20 ml-20">
                    {t('suggestion.no_decision.placeholder')}
                </div>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>
                                        {t('suggestion.no_decision.title_prefix')}
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={t(
                                                'suggestion.no_decision.title'
                                            )}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>{t('description')}</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            className="resize-none"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex mt-2 float-right">
                            <Button
                                type="submit"
                                variant="secondary"
                                className="mr-2 align-top"
                                onClick={onWithoutPassive}
                            >
                                {t('action.delete_no_action_suggestion')}
                            </Button>
                            <Button type="submit" className="align-text-top">
                                {t('action.apply')}
                            </Button>
                        </div>
                        <div className="clear-both" />

                        {/* <Button type="submit">{t('button.ok', { ns: 'buttons' })}</Button> */}
                    </form>
                </Form>
            </div>
            {/* <Floater>
                <ButtonGroup>
                    <GenerateProposalsButton
                        session={props.sessionTree.root}
                        app={props.appTree}
                        clientId={''}
                        generateTree={props.generateTree}
                    />
                </ButtonGroup>
            </Floater> */}
        </div>
    );
}
