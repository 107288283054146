/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
//import { RootNote,  BaseNote } from '../schema/notes_schema';
import { Session } from '../schema/session_schema';
import '../output.css';
import {
    ConnectionState,
    IFluidContainer,
    IMember,
    IServiceAudience,
} from 'fluid-framework';

import { TreeView } from 'fluid-framework';
import { undefinedUserId } from '../utils/utils';
import { TaskMenu } from './task_control/task_menu';
import { BrowserRouter, Routes, Route, MemoryRouter, Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
//import { ScViewMain } from './sc_view/sc_view_main';
import { Register } from './authentication/Register';
import { Button } from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../components/ui/select';
import { Group, Items } from '../schema/app_schema';
// import { ScViewMain } from './sc_view/sc_view_main';
import { ElementTypeEnum } from '../utils/app_helpers';
import { PassiveOption } from './passiveOption/passive_option';
import { Header } from './header';
import { NoteMain } from './note/note_main';
import Login from './authentication/Login';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './authentication/Unauthorized';
import Admin from './administration/Admin';
import PersistLogin from './authentication/PersistLogin';
import Pricing from './static_pages/pricing';
import Start from './static_pages/start';
import DecisionSystemSelection from './static_pages/DecisionSystemSelection';
import LifeMeetingSetup from './decisionMethods/forms/LifeMeetingSetup';
import ForgotPassword from './authentication/ForgotPassword';
import HowToAdmisterTheTopic from './decisionMethods/forms/HowToAdmisterTheTopic';
import Legalnfo from './static_pages/Legalnfo';
import TermsAndConditions from './static_pages/TermsAndConditions';
import Footer from './sc_view/footer';
import Feedback from './static_pages/Feedback';
import BrowserHandling from './components/BrowserHandling';
import { LinkRouting } from './components/LinkRouting';
import ConfirmEmail from './authentication/ConfirmEmail';
import Settings from './decisionMethods/forms/Settings';
import PassiveSuggestion from './decisionMethods/forms/PassiveSuggestion';
import FastConsensing from './decisionMethods/fast/FastConsensing';
import SubjectLoader from './static_pages/SubjectLoader';
import CombinedSubjectHeader from './decisionMethods/components/combined/combinedSubjectHeader';
import DynamicCanvas from './dynamic/DynamicCanvas';

export function ReactApp(props: {}): JSX.Element {
    const [currentUser, setCurrentUser] = useState(undefinedUserId);
    const [fluidMembers, setFluidMembers] = useState<string[]>([]);

    return (
        <MemoryRouter>
            <LinkRouting />
            <BrowserHandling />
            <DndProvider backend={HTML5Backend}></DndProvider>
            <div className="h-screen w-screen flex">
                <div className="">
                    <Header
                        fluidMembers={fluidMembers}
                        clientId={currentUser}
                        currentUser={currentUser}
                        setFluidMembers={setFluidMembers}
                        setCurrentUser={setCurrentUser}
                    />

                    {/* <TaskMenu /> */}
                    <Routes>
                        <Route path="/combined" element={<CombinedSubjectHeader />}>
                            <Route
                                path={'first_ideas'}
                                element={
                                    <NoteMain
                                        isWithGroups={true}
                                        elementType={ElementTypeEnum.Note}
                                    />
                                }
                            />
                            <Route
                                path={'proposals'}
                                element={
                                    <NoteMain
                                        isWithGroups={true}
                                        elementType={ElementTypeEnum.CombineProp}
                                    />
                                }
                            />
                            <Route
                                path={'passive_option'}
                                element={<PassiveOption />}
                            />

                            <Route
                                path={'resistance_measurement'}
                                element={
                                    <NoteMain
                                        isWithGroups={true}
                                        elementType={ElementTypeEnum.ResistanceMeas}
                                    />
                                }
                            />
                        </Route>
                        <Route path="/dynamic" element={<DynamicCanvas />} />

                        <Route element={<PersistLogin />}>
                            <Route path={'/'} element={<Start />} />

                            <Route
                                path={'/selection'}
                                element={<DecisionSystemSelection />}
                            />
                            <Route
                                path={'/erste'}
                                element={
                                    <NoteMain
                                        isWithGroups={true}
                                        elementType={ElementTypeEnum.Note}
                                    />
                                }
                            />
                            <Route path={'/pricing'} element={<Pricing />} />

                            <Route path="/setup">
                                <Route
                                    path="basic_data/:setup_type"
                                    element={<LifeMeetingSetup />}
                                />
                                <Route
                                    path="distrib_mode/:setup_type"
                                    element={<LifeMeetingSetup />}
                                />
                                <Route
                                    path="how_to_admister"
                                    element={<HowToAdmisterTheTopic />}
                                />
                                <Route path="settings" element={<Settings />} />
                                <Route
                                    path="passive_suggestion"
                                    element={<PassiveSuggestion />}
                                />
                            </Route>

                            <Route
                                path={'/fast_consensing/:mode'}
                                element={<FastConsensing />}
                            />

                            <Route element={<RequireAuth />}>
                                <Route
                                    path={'/proposals'}
                                    element={
                                        <NoteMain
                                            isWithGroups={true}
                                            elementType={ElementTypeEnum.CombineProp}
                                        />
                                    }
                                />
                                <Route
                                    path={'/passive_option'}
                                    element={<PassiveOption />}
                                />

                                <Route
                                    path={'/shine_through'}
                                    element={
                                        <NoteMain
                                            isWithGroups={true}
                                            elementType={
                                                ElementTypeEnum.ShineThrough
                                            }
                                        />
                                    }
                                />

                                <Route
                                    path={'/resistance_measurement'}
                                    element={
                                        <NoteMain
                                            isWithGroups={true}
                                            elementType={
                                                ElementTypeEnum.ResistanceMeas
                                            }
                                        />
                                    }
                                />
                            </Route>
                            <Route path={'/admin'} element={<Admin />} />
                        </Route>

                        <Route path={'/register'} element={<Register />} />
                        <Route path={'/lost_pwd'} element={<ForgotPassword />} />
                        <Route path={'/login'} element={<Login />} />
                        <Route path={'/unauthorized'} element={<Unauthorized />} />
                        <Route path={'/legal_info'} element={<Legalnfo />} />
                        <Route
                            path={'/terms_conditions'}
                            element={<TermsAndConditions />}
                        />
                        <Route path={'/feedback'} element={<Feedback />} />
                        <Route path={'/confirm_email'} element={<ConfirmEmail />} />
                        <Route
                            path={'/load_subject/:subject_key'}
                            element={<SubjectLoader />}
                        />
                    </Routes>
                    <Footer />
                </div>
            </div>
        </MemoryRouter>
    );
}
