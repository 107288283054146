import {
    ChangeEvent,
    Dispatch,
    ReactElement,
    createContext,
    useCallback,
    useContext,
    useReducer,
    useState,
} from 'react';

export type NewSubjectType = {
    type: string;
    title: string;
    description: string;
    isEmail: boolean;
    email: string;
    name: string;
    isTimed: boolean;
    isAcceptedAgb: boolean;
    allowSuggestionsFromParticipants: boolean;
    allowSuggestionCompletion: boolean;

    isSuggestionsOn: boolean;
    isRatingOn: boolean;
    isShowResults: boolean;

    passiveTitle: string;
    passiveDescription: string;
};

const initState: NewSubjectType = {
    type: '',
    title: '',
    description: '',
    isEmail: false,
    email: '',
    name: '',
    isTimed: true,
    isAcceptedAgb: false,
    allowSuggestionsFromParticipants: false,
    allowSuggestionCompletion: false,

    isSuggestionsOn: true,
    isRatingOn: false,
    isShowResults: false,

    passiveTitle: '',
    passiveDescription: '',
};

export const enum REDUCER_ACTION_TYPE {
    SET_TITLE,
    SET_DESCRIPTION,
    SET_TYPE,
    SET_IS_EMAIL,
    SET_NAME,
    SET_EMAIL,
    SET_IS_TIMED,
    SET_IS_ACCEPTED_AGB,
    SET_ALLOW_SUGGESTIONS_FROM_PARTICIPANTS,
    SET_ALLOW_SUGGESTION_COMPLETION,
    SET_IS_SUGGESTIONS_ON,
    SET_IS_RATING_ON,
    SET_IS_SHOW_RESULTS_ON,
    SET_PASSIVE_TITLE,
    SET_PASSIVE_DESCRIPTION,
    RESET_ALL,
}

type ReducerAction = {
    type: REDUCER_ACTION_TYPE;
    payload?: any;
};

const reducer = (state: NewSubjectType, action: ReducerAction): NewSubjectType => {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.SET_TITLE:
            return { ...state, title: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_DESCRIPTION:
            return { ...state, description: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_TYPE:
            return { ...state, type: action.payload ?? [] };
        case REDUCER_ACTION_TYPE.SET_IS_EMAIL:
            return { ...state, isEmail: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_EMAIL:
            return { ...state, email: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_NAME:
            return { ...state, name: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_IS_TIMED:
            return { ...state, isTimed: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_IS_ACCEPTED_AGB:
            return { ...state, isAcceptedAgb: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_ALLOW_SUGGESTIONS_FROM_PARTICIPANTS:
            return {
                ...state,
                allowSuggestionsFromParticipants: action.payload ?? '',
            };
        case REDUCER_ACTION_TYPE.SET_ALLOW_SUGGESTION_COMPLETION:
            return { ...state, allowSuggestionCompletion: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_IS_SUGGESTIONS_ON:
            return { ...state, isSuggestionsOn: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_IS_RATING_ON:
            return { ...state, isRatingOn: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_IS_SHOW_RESULTS_ON:
            return { ...state, isShowResults: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_PASSIVE_TITLE:
            return { ...state, passiveTitle: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_PASSIVE_DESCRIPTION:
            return { ...state, passiveDescription: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.RESET_ALL:
            return initState;
        default:
            throw new Error('AuthProviderContext reducer action.type not found');
    }
};

type ContextValue = {
    state: NewSubjectType;
    dispatch: Dispatch<ReducerAction>;
};

const SubjectContext = createContext<ContextValue>({
    state: initState,
    dispatch: () => {},
});

export const NewSubjectProvider = ({
    children,
}: {
    children: JSX.Element;
}): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <SubjectContext.Provider value={{ state, dispatch }}>
            {children}
        </SubjectContext.Provider>
    );
};

export const useNewSubjectContextValue = (): ContextValue => {
    return useContext(SubjectContext);
};
