/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    AzureRemoteConnectionConfig,
    AzureClientProps,
    AzureLocalConnectionConfig,
} from '@fluidframework/azure-client';

import { AzureFunctionTokenProvider, azureUser, user } from './tokenProvider';
import { InsecureTokenProvider } from './azureTokenProvider';

const useAzure = process.env.FLUID_CLIENT === 'azure';
if (!useAzure) {
    console.warn(`Configured to use local tinylicious.`);
}

// const remoteConnectionConfig: AzureRemoteConnectionConfig = {
//     type: 'remote',
//     tenantId: process.env.AZURE_TENANT_ID!,
//     tokenProvider: new AzureFunctionTokenProvider(
//         process.env.AZURE_FUNCTION_TOKEN_PROVIDER_URL!,
//         azureUser
//     ),
//     endpoint: process.env.AZURE_ORDERER!,
// };

const localConnectionConfig: AzureLocalConnectionConfig = {
    type: 'local',
    tokenProvider: new InsecureTokenProvider('VALUE_NOT_USED', user),
    endpoint: 'http://localhost:7070',
};

const myConfig: AzureRemoteConnectionConfig = {
    tenantId: '24cec534-46bd-4b99-ac1b-16b6ea18ef82',
    tokenProvider: new InsecureTokenProvider(
        '3bdfc6e7ef1bb2f47939dd2dc0d5c5d2',
        user
    ),
    endpoint: 'https://eu.fluidrelay.azure.com',
    type: 'remote',
};

const connectionConfig: AzureRemoteConnectionConfig | AzureLocalConnectionConfig =
    myConfig;
//   useAzure ? remoteConnectionConfig : localConnectionConfig;
export const clientProps: AzureClientProps = {
    connection: connectionConfig,
};
