import React, { FC } from 'react';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from '../../../components/ui/collapsible';
import { Button } from '../../../components/ui/button';
import { ChevronsUpDown } from 'lucide-react';
import { ElementTypeEnum } from '../../utils/app_helpers';
import {
    ExploreResistanceImage,
    FirstIdeasImage,
    PassiveOptionImage,
    ProposalsImage,
    ResistanceMeasurementImage,
    ShineThroughImage,
} from '../button_sc_ux';
import { DeletePileButton } from '../note/groupux';
interface ProcessStepContainerProps {
    children: JSX.Element;
    type: ElementTypeEnum;
    deleteProcess: () => void;
}

const ProcessStepContainer: FC<ProcessStepContainerProps> = ({
    children,
    type,
    deleteProcess,
}): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState(true);
    const headerView = (type: ElementTypeEnum) => {
        switch (type) {
            case ElementTypeEnum.Note:
                return <FirstIdeasImage />;
            case ElementTypeEnum.Propsals:
                return <ProposalsImage />;
            case ElementTypeEnum.Passive:
                return <PassiveOptionImage />;
            case ElementTypeEnum.ShineThrough:
                return <ShineThroughImage />;
            case ElementTypeEnum.ResistanceMeas:
                return <ResistanceMeasurementImage />;
            case ElementTypeEnum.ExploreResistance:
                return <ExploreResistanceImage />;
        }
    };

    return (
        <Collapsible
            open={isOpen}
            onOpenChange={setIsOpen}
            className="w-full space-y-2"
        >
            <div className="flex flex-row justify-between">
                {headerView(type)}
                <div>
                    <DeletePileButton deletePile={deleteProcess} />
                    <CollapsibleTrigger asChild>
                        <Button variant="ghost" size="sm" className="w-9 p-0">
                            <ChevronsUpDown className="h-4 w-4" />
                            <span className="sr-only">Toggle</span>
                        </Button>
                    </CollapsibleTrigger>
                </div>
            </div>
            <CollapsibleContent className="space-y-2">{children}</CollapsibleContent>
        </Collapsible>
    );
};

export default ProcessStepContainer;
