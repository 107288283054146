import React, { FC } from 'react';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../../components/ui/form';
import { Switch } from '../../../components/ui/switch';

interface SwitchFormFieldProps {
    name: string;
    form: any;
    label: string;
}

const SwitchFormField: FC<SwitchFormFieldProps> = ({
    name,
    form,
    label,
}): JSX.Element => {
    return (
        <FormField
            control={form.control}
            name={name}
            render={({ field }) => (
                <FormItem className="mt-2 flex items-center">
                    <FormControl>
                        <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                        />
                    </FormControl>
                    <FormLabel className="ml-2">{label}</FormLabel>
                </FormItem>
            )}
        />
    );
};

export default SwitchFormField;
