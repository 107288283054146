import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface footerProps {}

const Footer: FC<footerProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['generic']);
    return (
        <div className="ml-16 mr-16 flex justify-between">
            <div>
                <Link to="/legal_info">{t('about_us')}</Link>
            </div>
            <div>
                <Link to="/terms_conditions">{t('terms')}</Link>
            </div>
            <div>
                <Link to="https://sk-prinzip.eu/">{t('help.more_info')}</Link>
            </div>
            <div>
                <Link to="/feedback">{t('feedback')}</Link>
            </div>
        </div>
    );
};

export default Footer;
