import {
    ChangeEvent,
    Dispatch,
    ReactElement,
    createContext,
    useCallback,
    useContext,
    useReducer,
    useState,
} from 'react';
import { FluidWorkspace } from 'src/utils/fluidContainerMangagemnt';

export type SubjectType = {
    type: string;
    containerId: string;
    publicLink: string;
    subjectId: number;
    fluidWS: FluidWorkspace | null;
    headerControls: JSX.Element | null;
};

const initState: SubjectType = {
    type: '',
    containerId: '',
    publicLink: '',
    subjectId: 0,
    fluidWS: null,
    headerControls: null,
};

export const enum REDUCER_ACTION_TYPE {
    SET_TYPE,
    SET_CONTAINER_ID,
    SET_PUBLIC_LINK,
    SET_SUBJECT_ID,
    SET_FLUID_WORKSPACE,
    SET_HEADER_CONTROLS,
    RESET_ALL,
}

type ReducerAction = {
    type: REDUCER_ACTION_TYPE;
    payload?: any;
};

const reducer = (state: SubjectType, action: ReducerAction): SubjectType => {
    switch (action.type) {
        case REDUCER_ACTION_TYPE.SET_TYPE:
            return { ...state, type: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_CONTAINER_ID:
            return { ...state, containerId: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_PUBLIC_LINK:
            return { ...state, publicLink: action.payload ?? [] };
        case REDUCER_ACTION_TYPE.SET_SUBJECT_ID:
            return { ...state, subjectId: action.payload ?? '' };
        case REDUCER_ACTION_TYPE.SET_FLUID_WORKSPACE:
            return { ...state, fluidWS: action.payload };
        case REDUCER_ACTION_TYPE.SET_HEADER_CONTROLS:
            return { ...state, headerControls: action.payload };
        case REDUCER_ACTION_TYPE.RESET_ALL:
            return initState;
        default:
            throw new Error('AuthProviderContext reducer action.type not found');
    }
};

export type ContextValue = {
    state: SubjectType;
    dispatch: Dispatch<ReducerAction>;
};

const SubjectContext = createContext<ContextValue>({
    state: initState,
    dispatch: () => {},
});

export const SubjectProvider = ({
    children,
}: {
    children: JSX.Element;
}): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <SubjectContext.Provider value={{ state, dispatch }}>
            {children}
        </SubjectContext.Provider>
    );
};

export const useSubjectContextValue = (): ContextValue => {
    return useContext(SubjectContext);
};
