import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContextValue } from '../context/AuthProviderContext';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '../../../components/ui/accordion';

interface pricingProps {}

const Pricing: FC<pricingProps> = ({}) => {
    const { t, i18n } = useTranslation(['pricing', 'mysk']);
    const authContext = useAuthContextValue();
    return (
        <div>
            <div className="main-area pad">
                <h2 className="text-center mt-2">{t('pricing_header')}</h2>

                <div
                    id="contact-sales"
                    className="mx-5 my-3 flex-grow-[1 !important] text-center"
                >
                    {t('contact_sales')}
                    <a href="mailto:sales@acceptify.at">sales@acceptify.at</a>
                </div>

                <div className="container mx-auto sm:px-4 max-w-full md:p-12 mt-1">
                    <div className="flex flex-wrap  m-auto text-center">
                        <div className="lg:w-1/3 pr-4 pl-4 pricing-item black">
                            <div className="pricing-divider ">
                                <h3 className="text-gray-100">Free</h3>
                                <h4 className="my-0 text-2xl text-gray-100 font-normal mb-3">
                                    <span className="h3">€</span> 0{' '}
                                </h4>
                                <svg
                                    className="pricing-divider-img"
                                    enableBackground="new 0 0 300 100"
                                    height="100px"
                                    id="Layer_1"
                                    preserveAspectRatio="none"
                                    viewBox="0 0 300 100"
                                    width="300px"
                                    x="0px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    y="0px"
                                >
                                    <path
                                        className="deco-layer deco-layer--1"
                                        d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                                        fill="#FFFFFF"
                                        opacity="0.6"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--2"
                                        d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                                        fill="#FFFFFF"
                                        opacity="0.6"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--3"
                                        d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
H42.401L43.415,98.342z"
                                        fill="#FFFFFF"
                                        opacity="0.7"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--4"
                                        d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                                        fill="#FFFFFF"
                                    ></path>
                                </svg>
                            </div>
                            <div className="flex-auto p-6 bg-white mt-0 shadow">
                                <ul className="list-unstyled mb-5 relative">
                                    <li>
                                        <b>{t('unlimited')}</b> {t('participants')}
                                    </li>
                                    <li>
                                        <b>2</b> {t('parallel topics')}
                                    </li>
                                    <li>
                                        <b>2 {t('months')}</b> {t('storage period')}
                                    </li>
                                    <li>
                                        <b>{t('All features for simple topics')}</b>
                                    </li>
                                    <li>
                                        <b>{t('Financed by advertising')}</b>
                                    </li>
                                    <li>
                                        <b>{t('Community')}</b>{' '}
                                        {t('support through help center')}
                                    </li>
                                </ul>
                                {authContext.state.isLoggedIn && (
                                    <a
                                        href="{{ path('fos_user_registration_register') }}"
                                        className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-blue-600 text-white hover:bg-blue-600 "
                                    >
                                        {t('Sign up for free')}
                                    </a>
                                )}
                            </div>
                        </div>

                        <div className="lg:w-1/3 pr-4 pl-4 pricing-item green">
                            <div className="pricing-divider ">
                                <h3 className="text-gray-100">Basic</h3>
                                <h4 className="my-0 text-2xl text-gray-100 font-normal mb-3">
                                    <span className="h3">€</span> 5{' '}
                                    <span className="h5">/ {t('month')}*</span>
                                </h4>
                                <svg
                                    className="pricing-divider-img"
                                    enableBackground="new 0 0 300 100"
                                    height="100px"
                                    id="Layer_1"
                                    preserveAspectRatio="none"
                                    viewBox="0 0 300 100"
                                    width="300px"
                                    x="0px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    y="0px"
                                >
                                    <path
                                        className="deco-layer deco-layer--1"
                                        d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                                        fill="#FFFFFF"
                                        opacity="0.6"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--2"
                                        d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                                        fill="#FFFFFF"
                                        opacity="0.6"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--3"
                                        d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
H42.401L43.415,98.342z"
                                        fill="#FFFFFF"
                                        opacity="0.7"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--4"
                                        d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                                        fill="#FFFFFF"
                                    ></path>
                                </svg>
                            </div>

                            <div className="flex-auto p-6 bg-white mt-0 shadow">
                                <ul className="list-unstyled mb-5 relative">
                                    <li>
                                        <b>{t('unlimited')}</b> {t('participants')}
                                    </li>
                                    <li>
                                        <b>5</b> {t('parallel topics')}
                                    </li>
                                    <li>
                                        <b>6 {t('months')}</b> {t('storage period')}
                                    </li>
                                    <li>
                                        <b>+ {t('Basic features')}</b>
                                    </li>
                                    <li>
                                        <b>{t('No advertising')}</b>
                                    </li>
                                    <li>
                                        <b>+ {t('Low priority email')}</b>{' '}
                                        {t('support for moderators')}
                                    </li>
                                </ul>
                                {authContext.state.isLoggedIn ? (
                                    <a
                                        href="mailto:sales@acceptify.at"
                                        className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-blue-600 text-white hover:bg-blue-600"
                                    >
                                        {t('Contact sales')}
                                    </a>
                                ) : (
                                    <a
                                        href="{{ path('fos_user_registration_register') }}"
                                        className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-blue-600 text-white hover:bg-blue-600"
                                    >
                                        {t('Sign up & contact sales')}
                                    </a>
                                )}
                            </div>
                        </div>

                        <div className="lg:w-1/3 pr-4 pl-4 pricing-item blue">
                            <div className="pricing-divider ">
                                <h3 className="text-gray-100">Premium</h3>
                                <h4 className="my-0 text-2xl text-gray-100 font-normal mb-3">
                                    <span className="h3">€</span> 20{' '}
                                    <span className="h5">/ {t('month')}*</span>
                                </h4>
                                <svg
                                    className="pricing-divider-img"
                                    enableBackground="new 0 0 300 100"
                                    height="100px"
                                    id="Layer_1"
                                    preserveAspectRatio="none"
                                    viewBox="0 0 300 100"
                                    width="300px"
                                    x="0px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    y="0px"
                                >
                                    <path
                                        className="deco-layer deco-layer--1"
                                        d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                                        fill="#FFFFFF"
                                        opacity="0.6"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--2"
                                        d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                                        fill="#FFFFFF"
                                        opacity="0.6"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--3"
                                        d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
H42.401L43.415,98.342z"
                                        fill="#FFFFFF"
                                        opacity="0.7"
                                    ></path>
                                    <path
                                        className="deco-layer deco-layer--4"
                                        d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                                        fill="#FFFFFF"
                                    ></path>
                                </svg>
                            </div>

                            <div className="flex-auto p-6 bg-white mt-0 shadow">
                                <ul className="list-unstyled mb-5 relative">
                                    <li>
                                        <b>{t('unlimited')}</b> {t('participants')}
                                    </li>
                                    <li>
                                        <b>{t('unlimited')}</b>{' '}
                                        {t('parallel topics')}
                                    </li>
                                    <li>
                                        <b>{t('unlimited')}</b> {t('storage period')}
                                    </li>
                                    <li>
                                        <b>+ {t('Premium features')}</b>
                                    </li>
                                    <li>
                                        <b>{t('No advertising')}</b>
                                    </li>
                                    <li>
                                        <b>+ {t('High priority email')}</b>{' '}
                                        {t('support for moderators')}
                                    </li>
                                </ul>
                                {authContext.state.isLoggedIn ? (
                                    <a
                                        href="mailto:sales@acceptify.at"
                                        className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-blue-600 text-white hover:bg-blue-600"
                                    >
                                        {t('Contact sales')}
                                    </a>
                                ) : (
                                    <a
                                        href="{{ path('fos_user_registration_register') }}"
                                        className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-blue-600 text-white hover:bg-blue-600"
                                    >
                                        {t('Sign up & contact sales')}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mr-16 ml-16 pricing-item blue">
                    <div className="flex-auto p-6 bg-white mt-0 shadow">
                        <ul className="list-unstyled mb-5 relative">
                            <li>
                                <b className="color:#024970;">
                                    {t('professional-decision-making-support')}
                                </b>{' '}
                            </li>
                            <li>
                                <b className="color:#024970;">
                                    {t('moderation-of-decision-making')}
                                </b>
                            </li>
                            <li>
                                <b className="color:#024970;">
                                    {t('devolop-particiipation-concepts')}
                                </b>
                            </li>
                            <li>{t('decision-making-support-description')}</li>
                        </ul>

                        <a
                            href="mailto:sales@acceptify.at"
                            className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-blue-600 text-white hover:bg-blue-600 margin-left:20px;margin-top:-50px; margin-bottom:-20px"
                        >
                            {t('Contact sales')}
                        </a>
                    </div>
                </div>
            </div>

            <div
                id="pricing-bottom"
                className="mx-5 mb-5 mr-16 ml-16 flex-grow-1 text-center"
            >
                *{t('pricing_bottom')}
            </div>

            <div className="divider"></div>
            <h3 className="text-center my-3">{t('Frequently asked questions')}</h3>

            <Accordion type="single" collapsible className="m-20">
                <AccordionItem value="item-1">
                    <AccordionTrigger>
                        {t('faq.question.parallel.topics')}
                    </AccordionTrigger>
                    <AccordionContent>
                        {t('faq.answer.parallel.topics')}
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-2">
                    <AccordionTrigger>
                        {t('faq.question.basic.features')}
                    </AccordionTrigger>
                    <AccordionContent>
                        {t('faq.answer.basic.features')}
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-3">
                    <AccordionTrigger>
                        {t('faq.question.premium.features')}?
                    </AccordionTrigger>
                    <AccordionContent>
                        {t('faq.answer.premium.features')}
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-4">
                    <AccordionTrigger>
                        {t('faq.question.cancel.plan')}
                    </AccordionTrigger>
                    <AccordionContent>
                        {t('faq.answer.cancel.plan')}
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-5">
                    <AccordionTrigger>{t('faq.question.support')}</AccordionTrigger>
                    <AccordionContent>{t('faq.answer.support')}</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default Pricing;
