import React, { FC, useEffect, useState } from 'react';
import { Input } from '../../../components/ui/input';
import { Switch } from '../../../components/ui/switch';
import { useTranslation } from 'react-i18next';
import {
    REDUCER_ACTION_TYPE,
    useFastConsensingContextValue,
} from '../context/FastConsensingContext';

interface FastConsensingFormProps {}

const FastConsensingForm: FC<FastConsensingFormProps> = ({}): JSX.Element => {
    const { t, i18n } = useTranslation(['settings', 'subject']);
    const fastConesingContext = useFastConsensingContextValue();

    const [title, setTitle] = useState(fastConesingContext.state.title);
    const [numberOfSuggestions, setNumberOfSuggestions] = useState<string>(
        fastConesingContext.state.numberOfSuggestions.toString()
    );
    const [evaluationIsActive, setEvaluationIsActive] = useState(
        fastConesingContext.state.evaluationActive
    );
    const [allowUserEditing, setAllowUserEditing] = useState(
        fastConesingContext.state.allowUserEditing
    );
    const [allowSuggestions, setAllowSuggestions] = useState(
        fastConesingContext.state.allowSuggestionsFromParticipants
    );
    const [emailRequire, setEmailRequired] = useState(
        fastConesingContext.state.emailRequired
    );
    let dateobj = new Date('October 15, 1996 05:35:32');

    // Contents of date portion of above date
    // object is converted into a string using
    // toDateString() method.
    let B = dateobj.toISOString().split('T')[0];

    // Printing the converted string.
    console.log(B);

    const [deleteAt, setDeleteAt] = useState<string>(
        fastConesingContext.state.deleteAt.toISOString().split('T')[0]
    );

    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_TITLE,
            payload: title,
        });
    }, [title]);
    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_NUMBER_OF_SUGGESTIONS,
            payload: +numberOfSuggestions,
        });
    }, [numberOfSuggestions]);
    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_EVALUATION_ACTIVE,
            payload: evaluationIsActive,
        });
    }, [evaluationIsActive]);
    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_ALLOW_USER_EDITING,
            payload: allowUserEditing,
        });
    }, [allowUserEditing]);
    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_ALLOW_SUGGESTIONS_FROM_PARTICIPANTS,
            payload: allowSuggestions,
        });
    }, [allowSuggestions]);
    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_EMAIL_REQUIRED,
            payload: emailRequire,
        });
    }, [emailRequire]);
    useEffect(() => {
        fastConesingContext.dispatch({
            type: REDUCER_ACTION_TYPE.SET_DELETE_AT,
            payload: new Date(deleteAt),
        });
    }, [deleteAt]);

    return (
        <div>
            <Input
                placeholder="Titel"
                className="m-1"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            ></Input>
            <table>
                <tr>
                    <td>Anzahl Vorschläge</td>
                    <td>
                        <Input
                            width={80}
                            className="m-1 w-16"
                            type="number"
                            min={0}
                            step={1}
                            max={20}
                            value={numberOfSuggestions}
                            onChange={(e) => setNumberOfSuggestions(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>Bewertung ist aktiv</td>
                    <td>
                        <Switch
                            checked={evaluationIsActive}
                            onCheckedChange={(e) => setEvaluationIsActive(e)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>User können editieren</td>
                    <td>
                        <Switch
                            checked={allowUserEditing}
                            onCheckedChange={(e) => setAllowUserEditing(e)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('subject.allowSuggestionsFrom_participants', {
                            ns: 'subject',
                        })}
                    </td>
                    <td>
                        <Switch
                            checked={allowSuggestions}
                            onCheckedChange={(e) => setAllowSuggestions(e)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>{t('subject.invitation.mail.title', { ns: 'subject' })} </td>
                    <td>
                        <Switch
                            checked={emailRequire}
                            onCheckedChange={(e) => setEmailRequired(e)}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="flex ml-auto mr-0">
                        <div className="flex-grow">Löschen am</div>
                        <Input
                            className="w-36"
                            type="date"
                            value={deleteAt}
                            onChange={(e) =>
                                setDeleteAt(
                                    new Date(e.target.value)
                                        .toISOString()
                                        .split('T')[0]
                                )
                            }
                        />
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default FastConsensingForm;
